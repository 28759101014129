import React from 'react'
import styled from 'styled-components'

import { Column, Row, Text, Icon, IconButton, Image } from '../../components'

import { theme } from '../../theme'

import defaultField from '../../assets/images/defaultField.png'

const FieldCard = ({
  status,
  name = '-',
  cultivation,
  area = '-',
  index,
  showMenu,
  setCurrentField,
  handleFieldEdit,
  handleFieldRemove,
  handleFieldCenter,
  currentPage,
  disableButton,
  dateRange,
  fieldsCount,
  traps,
  disableOnClick
}) => {
  return (
    <CardContainer>
      {status ? (
        status !== 'empty' ? (
          <Icon
            icon={
              status === 'active' ? 'normalPin' : status === 'disabled' ? 'disabledPin' : status
            }
            width={52}
            height={52}
            marginLeft={24}
            marginRight={20}
          />
        ) : (
          <Row
            width='52px'
            height='52px'
            backgroundColor={theme.colors.gray.n400}
            borderRadius='50%'
            margin='0px 20px 0px 24px'
          />
        )
      ) : cultivation && !traps ? (
        <Icon icon={cultivation} width={52} height={52} marginLeft={24} marginRight={20} />
      ) : (
        <Image src={defaultField} height={28} width={51} marginLeft={24} marginRight={20} />
      )}

      <Column
        flex={1}
        onClick={() => {
          !disableOnClick && handleFieldCenter(currentPage * 5 + index)
        }}
        style={{ cursor: 'pointer' }}
      >
        <Row>
          {cultivation && !traps && (
            <Column marginTop='8px'>
              <Text variant='caption' color='gray.n700'>
                Cultura
              </Text>
              <Text variant='paragraph' fontWeight={600} color='gray.n800'>
                {cultivation}
              </Text>
            </Column>
          )}
          <Column marginTop='8px' marginLeft={cultivation && !traps ? 45 : 0}>
            {cultivation && !traps && (
              <Text variant='caption' color='gray.n700'>
                Áreas Selecionadas
              </Text>
            )}
            <Text fontSize={16} fontWeight={600} lineHeight='20px' color='gray.n900'>
              {cultivation && !traps ? `${fieldsCount} áreas` : name}
            </Text>
          </Column>
        </Row>
        <Row>
          <Column margin='8px 0px'>
            <Text variant='caption' color='gray.n700'>
              {cultivation && !traps ? 'Período' : 'Área'}
            </Text>
            <Text variant='paragraph' fontWeight={600} color='gray.n800'>
              {cultivation && !traps ? dateRange : `${Number(area).toFixed(4)} ha`}
            </Text>
          </Column>
          {traps && cultivation && (
            <Column margin='8px 0px 8px 10%'>
              <Text variant='caption' color='gray.n700'>
                {cultivation[0] && 'Cultura'}
              </Text>
              <Text variant='paragraph' fontWeight={600} color='gray.n800'>
                {cultivation.join()}
              </Text>
            </Column>
          )}
        </Row>
      </Column>

      {!disableButton && (
        <IconButton
          icon='more'
          color='#424242'
          marginRight={10}
          onClick={() => {
            if (showMenu) {
              setCurrentField(null)
              return
            }
            setCurrentField(currentPage * 5 + index)
          }}
        />
      )}

      {showMenu && (
        <MenuButtons>
          <Overlay onClick={() => setCurrentField(null)} />
          <OptionButton>
            <Text
              variant='paragraph'
              color='gray.n700'
              onClick={() => {
                handleFieldEdit(currentPage * 5 + index)
              }}
            >
              Editar
            </Text>
          </OptionButton>
          {!traps && (
            <OptionButton>
              <Text
                variant='paragraph'
                color='gray.n700'
                onClick={() => {
                  handleFieldRemove(currentPage * 5 + index)
                }}
              >
                Excluir
              </Text>
            </OptionButton>
          )}
        </MenuButtons>
      )}
    </CardContainer>
  )
}

const CardContainer = styled.div`
  margin: 0px;
  max-height: 106px;
  max-width: 100%;
  background-color: white;
  border: 1px solid ${theme.colors.gray.n300};
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`

const MenuButtons = styled.div`
  position: absolute;
  background-color: white;
  width: 81px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  right: -70px;
  top: 25px;
  z-index: 9999;
`

const OptionButton = styled.div`
  background-color: white;
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${theme.colors.gray.n300};
  }
`

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background: transparent;
  width: 100vw;
  height: 100vh;
  z-index: -1;
`

export default FieldCard
