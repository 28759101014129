import React, { createContext, useState, useCallback, useContext } from 'react'
import { loginRequest, getUserData } from '../services'
import { Mixpanel } from '../utils/Mixpanel'

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const [logged, setLogged] = useState(false)
  const [authLoading, setAuthLoading] = useState(true)
  const [changedLocalStorage, setChangedLocalStorage] = useState(false)

  const login = async (email, password) => {
    try {
      const { data } = await loginRequest(email, password)
      setUser(data.user)
      setToken(data.token)
      
      Mixpanel.identify(data.user.email)
      Mixpanel.people.set_once({
        "$first_name": data.user.name,
        "$email": data.user.email
      
      })
      Mixpanel.track('Usuário logou')
      setLogged(true)
      localStorage.setItem('token', data.token)
      localStorage.setItem('id', data.user.id)
      setAuthLoading(false)
      return data
    } catch (error) {
      console.log(error)
      setAuthLoading(false)
      return false
    }
  }
  const logout = () => {
    setLogged(false)
    setUser(null)
    setToken(null)
    localStorage.clear()
    setChangedLocalStorage(true)
    Mixpanel.reset()
  }

  const verifyToken = useCallback(async (userId, tokenLocal) => {
    try {
      const { data } = await getUserData(userId)
      setToken(tokenLocal)
      setUser(data)
      setLogged(true)
      setAuthLoading(false)
      return data
    } catch (error) {
      logout()
      setAuthLoading(false)
    }
  }, [])

  const verifyLogin = useCallback(() => {
    if (localStorage.getItem('token') && localStorage.getItem('id')) {
      verifyToken(localStorage.getItem('id'), localStorage.getItem('token'))
    } else {
      logout()
      setAuthLoading(false)
    }
  }, [verifyToken])

  return (
    <AuthContext.Provider
      value={{
        login,
        logged,
        user,
        token,
        verifyLogin,
        changedLocalStorage,
        setChangedLocalStorage,
        authLoading,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
