import React from 'react'
import { useHistory } from 'react-router-dom'

import { FieldsContainer } from '../../components'

const FieldsList = ({ fields, loading }) => {
  const history = useHistory()

  const handleFieldClick = index => {
    history.push(`painel/talhao/${fields[index].id}`)
  }

  return (
    <FieldsContainer
      disableButton
      fields={fields}
      traps
      cultivation={fields.cultivation}
      handleFieldCenter={handleFieldClick}
      loading={loading}
    />
  )
}

export default FieldsList
