import React, { useEffect } from 'react'
import { Radio, FormControlLabel, RadioGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { theme } from '../../theme'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      background: 'rgba(46, 151, 95, 0.1)',
      zIndex: 0
    },
    '&:active': {
      background: 'rgba(46, 151, 95, 0.2)'
    }
  },
  icon: {
    borderRadius: '50%',
    zIndex: 1,
    width: 16,
    height: 16,
    backgroundColor: theme.colors.secondary.three,
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: theme.colors.gray.n400
    }
  },
  checkedIcon: {
    backgroundColor: theme.colors.secondary.three,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""'
    }
  },
  label: {
    '& .MuiTypography-body1': {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontSize: '16px',
      color: props => (props.labelColor ? props.labelColor : theme.colors.gray.n700)
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontSize: '16px',
      color: theme.colors.gray.n500
    }
  }
})

const RadioButtonComponent = ({
  name,
  options = [
    {
      disabled: false,
      labelPlacement: 'end'
    }
  ],
  onChange,
  register,
  row,
  value,
  ...props
}) => {
  const classes = useStyles(props)

  useEffect(() => {
    register(name)
  }, [register, name])

  return (
    <RadioGroup name={name} onChange={e => onChange(name, e.target.value)} row={row} value={value}>
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          className={classes.label}
          value={option.value}
          control={
            <Radio
              className={classes.root}
              disabled={option.disabled}
              disableRipple
              color='default'
              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
              icon={<span className={classes.icon} />}
              {...props}
            />
          }
          label={option.label || option.value}
          labelPlacement={option.labelPlacement}
        />
      ))}
    </RadioGroup>
  )
}

export default RadioButtonComponent
