import React from 'react'
import { ThemeProvider } from 'styled-components'

import Routes from './routes'
import { AuthContextProvider } from './context/AuthContext'
import { PropertiesContextProvider } from './context/PropertiesContext'
import { theme } from './theme'
import GlobalStyle from './globalStyles.js'
import WithAxios from './WithAxios.js'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const App = () => {
  return (
    <AuthContextProvider>
      <ToastContainer style={{ fontSize: '14px' }} />
      <GlobalStyle />
      <PropertiesContextProvider>
        <WithAxios>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </WithAxios>
      </PropertiesContextProvider>
    </AuthContextProvider>
  )
}

export default App
