import * as React from 'react'
import { RoomOutlined } from '@material-ui/icons'

function SvgRoom (props) {
  return (
    <RoomOutlined style={{ ...props }} />
  )
}

export default SvgRoom
