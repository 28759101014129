import * as React from 'react'
import { ZoomInOutlined } from '@material-ui/icons'

function SvgZoomIn (props) {
  return (
    <ZoomInOutlined style={{ ...props }} />
  )
}

export default SvgZoomIn
