import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Autocomplete, Polygon } from '@react-google-maps/api'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'

import { correctLatLng, harvestSchema } from '../../utils'

import {
  Column,
  Row,
  Text,
  Steps,
  IconButton,
  FieldsContainer,
  Button,
  MultiSelect,
  Select,
  DatePicker,
  Input,
  MapCore,
  ActionButton,
  InputSearch,
  Modal,
  Loader,
  CultivationMarker
} from '../../components'

import { ReactComponent as Logo } from '../../assets/images/logoHorizontal.svg'
import { Error } from '../../icons'

import { theme } from '../../theme'

import { useProperty } from '../../context/PropertiesContext'
import {
  getPlantings,
  registerNewHarvest,
  getHarvestByPropertyId,
  updateHarvest,
  deleteHarvest,
  updatePropertyStep,
  getFields
} from '../../services'
import { useAuth } from '../../context/AuthContext'
import { Mixpanel } from '../../utils/Mixpanel'

const AddHarvest = () => {
  const history = useHistory()
  const { logout } = useAuth()

  const { property, getStepId, setProperty } = useProperty()

  property.property_step_id < getStepId('Cadastro de safras') && history.push('/')

  property.property_step_id === getStepId('Completo') && history.push('/painel')

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    register,
    watch,
    reset,
    formState: { errors }
  } = useForm({ resolver: yupResolver(harvestSchema) })

  const values = watch(['plantingType', 'startDate'])

  const [fieldsData, setFieldsData] = useState([])
  const [registering, setRegistering] = useState(false)
  const [confirmation, setConfirmation] = useState(false)
  const [editing, setEditing] = useState(false)
  const [formData, setFormData] = useState(null)
  const [fieldsNames, setFieldsNames] = useState('')
  const [multiSelectOptions, setMultiSelectOptions] = useState([])
  const [plantingOptions, setPlantingOptions] = useState([])
  const [registeredHarvests, setRegisteredHarvests] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedFields, setSelectedFields] = useState([])
  const [formDataHistory, setFormDataHistory] = useState([])
  const [harvestId, setHarvestId] = useState('')

  const [logoutModal, setLogoutModal] = useState(false)

  const [modalRemove, setModalRemove] = useState(false)
  const [skipModal, setSkipModal] = useState(false)
  const [currentEditingFieldIndex, setCurrentEditingFieldIndex] = useState(null)
  const [autocomplete, setAutocomplete] = useState(null)
  const [zoom, setZoom] = useState(16)
  const [mapLocation, setMapLocation] = useState({
    lng: fieldsData[0]?.center?.lng,
    lat: fieldsData[0]?.center?.lat
  })

  const getHarvests = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data } = await getHarvestByPropertyId(property.id)
      setRegisteredHarvests(
        data.map(current => ({
          id: current.id,
          cultivationId: current.plantings[0].id,
          cultivation: current.plantings[0].name,
          plantingType: current.plantings[0].planting_type,
          startDate: current.start_date,
          endDate: current.end_date,
          dateRange: `${moment(current.start_date).add(1, 'days').format('DD/MM/YY')} - ${moment(
            current.end_date
          )
            .add(1, 'days')
            .format('DD/MM/YY')}`,
          fieldsCount: current.fields.length,
          fields: current.fields
        }))
      )
      if (formDataHistory.length === 0)
        setFormDataHistory(
          data.map(current => ({
            fields: current.fields.map(current => ({
              label: current.name,
              value: current.id
            })),
            planting: {
              id: current.plantings[0].id,
              name: current.plantings[0].name
            },
            plantingType: current.plantings[0].planting_type,
            startDate: moment(current.start_date).add(1, 'days'),
            endDate: moment(current.end_date).add(1, 'days')
          }))
        )
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property.id])

  const filterHarvest = field => {
    const auxHarvest = registeredHarvests.filter(harvest =>
      harvest.fields.some(current => current.id === field.id)
    )
    const harvest = auxHarvest.filter(current =>
      moment().isBetween(moment(current.startDate).subtract(1, 'days'), current.endDate)
    )
    if (harvest.length > 0) {
      return harvest[0]
    }
    return auxHarvest.find(current => moment().isBefore(moment(current.startDate).add(1, 'days')))
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const { data } = await getFields(property.id)
        setFieldsData(correctLatLng(data))
        await getHarvests()
        await getAllPlantings()
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [getHarvests, property.id])

  useEffect(() => {
    setMultiSelectOptions(
      fieldsData.map(current => ({
        label: current.name,
        value: current.id
      }))
    )
  }, [fieldsData])

  const onLoad = autocomplete => {
    setAutocomplete(autocomplete)
  }

  const onPlaceChanged = () => {
    setMapLocation(autocomplete.getPlace()?.geometry?.location)
    setZoom(16)
  }

  const defaultOptions = {
    fillColor: '#2E7D32',
    fillOpacity: 0.3,
    strokeColor: '#2E7D32',
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }

  const selectedFieldsOptions = {
    fillColor: '#1565C0',
    fillOpacity: 0.3,
    strokeColor: '#1565C0',
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }

  const getAllPlantings = async () => {
    try {
      const { data } = await getPlantings()
      setPlantingOptions(
        data.map(current => ({
          id: {
            id: current.id,
            name: current.name
          },
          value: current.name
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }

  const registerHarvest = async values => {
    const formatedFields = values.fields.map(field => ({ id: field.value }))
    try {
      editing
        ? await updateHarvest(
            harvestId,
            formatedFields,
            values.startDate,
            values.endDate,
            values.planting.id,
            values.plantingType
          )
        : await registerNewHarvest(
            formatedFields,
            values.startDate,
            values.endDate,
            values.planting.id,
            values.plantingType
          )
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async () => {
    await registerHarvest(formData)
    await getHarvests()
    if (editing) {
      const dataAux = formDataHistory
      dataAux[currentEditingFieldIndex] = formData
      setFormDataHistory(dataAux)
    } else setFormDataHistory(formDataHistory.concat(formData))
    reset({ plantingType: null })
    setConfirmation(false)
    setRegistering(false)
    setSelectedFields([])
    setFormData(null)
    setEditing(false)
    setCurrentEditingFieldIndex(null)
  }

  const onConfirmHarvest = values => {
    setFieldsNames(values.fields.map(current => current.label))
    setFormData(values)
    setConfirmation(true)
  }

  const handleHarvestEdit = async harvestIndex => {
    const harvest = registeredHarvests[harvestIndex]
    setFormData(formDataHistory[harvestIndex])
    reset({ plantingType: harvest.plantingType })
    setHarvestId(harvest.id)

    setEditing(true)
    setRegistering(true)
  }

  const handleHarvestDelete = async harvestIndex => {
    try {
      await deleteHarvest(registeredHarvests[harvestIndex].id)
      setFormDataHistory(formDataHistory.splice(harvestIndex, 1))
      reset({ plantingType: null })
      await getHarvests()
      setCurrentEditingFieldIndex(null)
      setModalRemove(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFieldCenter = index => {
    setCurrentEditingFieldIndex(index)
    setMapLocation({
      lng: fieldsData[0]?.center?.lng,
      lat: fieldsData[0]?.center?.lat
    })
  }

  const handleGoBack = index => {
    if (registering) {
      if (confirmation) {
        setConfirmation(false)
        reset({ plantingType: formData.plantingType })
      } else {
        setCurrentEditingFieldIndex(null)
        setSelectedFields([])
        setEditing(false)
        setRegistering(false)
        setFormData(null)
        reset({ plantingType: null })
      }
    } else history.push('/propriedade/cadastro/talhao')
  }

  return fieldsData.length > 0 ? (
    <Row width='100%' height='100vh'>
      <Column width='32vw' alignItems='center' marginTop='24px'>
        <IconButton
          icon='arrowBack2'
          position='absolute'
          top={29.33}
          left={29.33}
          onClick={handleGoBack}
        />
        <Logo width='150px' />
        <Steps
          steps={['Identificação', 'Áreas', 'Safra', 'Armadilha']}
          currentStep={3}
          marginTop={24}
          width='70%'
        />
        {!registering && (
          <Column
            flex={1}
            position='fixed'
            left='0px'
            top='165px'
            height='calc(100% - 165px)'
            width={currentEditingFieldIndex !== null ? 'calc(32vw + 70px)' : '32vw'}
            overflow='auto'
            zIndex='1'
          >
            <Column
              flex={1}
              maxWidth='32vw'
              width='100%'
              alignItems='center'
              justifyContent='space-between'
            >
              <FieldsContainer
                harvest
                width='100%'
                fields={registeredHarvests}
                setCurrentEditingFieldIndex={setCurrentEditingFieldIndex}
                currentEditingFieldIndex={currentEditingFieldIndex}
                handleFieldRemove={params => setModalRemove(true)}
                handleFieldEdit={params => handleHarvestEdit(params)}
                handleFieldCenter={handleFieldCenter}
                openModal={modalRemove}
                maxHeight={registeredHarvests.length === 0 ? '100%' : '450px'}
                loading={isLoading}
                onClickNewCard={() => setRegistering(true)}
              />
              {registeredHarvests.length !== 0 && (
                <Button
                  text='Avançar'
                  width='100%'
                  margin='45px 0px 24px 0px'
                  paddingLeft={24}
                  paddingRight={24}
                  onClick={async () => {
                    const { data } = await updatePropertyStep(
                      property.id,
                      getStepId('Ativação armadilhas')
                    )
                    setProperty(data.property)
                    history.push('/propriedade/cadastro/armadilha')
                  }}
                />
              )}
              {registeredHarvests.length === 0 && !isLoading && (
                <Button
                  inverted
                  text='Cadastrar safra depois'
                  width='100%'
                  margin='16px 0px 24px 0px'
                  paddingLeft={24}
                  paddingRight={24}
                  onClick={() => {
                    setSkipModal(true)
                  }}
                />
              )}
            </Column>
          </Column>
        )}
        {registering && (
          <Column
            flex={1}
            width='100%'
            alignItems='center'
            justifyContent='flex-start'
            padding='24px'
            marginTop='24px'
            overflow='auto'
          >
            {!confirmation && (
              <>
                <Text variant='subtitles' color='black' fontWeight={600} textAlign='center'>
                  Informações da safra
                </Text>
                <Text variant='paragraph' color='gray.n700' margin='16px 24px 24px 24px'>
                  Preencha e confirme as informações da safra plantada nessas áreas.
                </Text>
                <MultiSelect
                  name='fields'
                  control={control}
                  changeValue={setValue}
                  getValues={getValues}
                  options={multiSelectOptions}
                  register={register}
                  value={formData ? formData.fields : []}
                  width='100%'
                  setState={setSelectedFields}
                />
                <Select
                  harvest
                  name='planting'
                  label='Cultura'
                  getValues={getValues}
                  register={register}
                  onChange={setValue}
                  value={formData ? formData.planting : ''}
                  options={plantingOptions}
                  margin='24px'
                  width='100%'
                />
                <Input
                  name='plantingType'
                  label='Variedade'
                  register={register}
                  onChange={setValue}
                  value={values.plantingType}
                  width='100%'
                />
                <Row width='100%' margin='24px'>
                  <DatePicker
                    name='startDate'
                    register={register}
                    changeValue={setValue}
                    value={formData ? formData.startDate : null}
                    control={control}
                    label='Início'
                  />
                  <Row width='25px' />
                  <DatePicker
                    name='endDate'
                    register={register}
                    changeValue={setValue}
                    value={formData ? formData.endDate : null}
                    control={control}
                    label='Fim'
                    minDate={values.startDate}
                  />
                </Row>
                <Column flex={1} justifyContent='flex-end' width='100%'>
                  {errors?.endDate?.type !== 'min' && Object.keys(errors).length !== 0 && (
                    <Row
                      backgroundColor='rgba(198, 40, 40, 0.2)'
                      borderRadius={3}
                      height={27}
                      alignItems='center'
                      onClick={() => console.log(errors)}
                    >
                      <Row marginLeft={18} alignItems='center'>
                        <Error color={theme.colors.feedback.error} width={15} />
                        <Text variant='caption' color='feedback.error' marginLeft='5px'>
                          Todos os campos são obrigatorios
                        </Text>
                      </Row>
                    </Row>
                  )}
                  <Button text='Confirmar' width='100%' onClick={handleSubmit(onConfirmHarvest)} />
                  <Button
                    outlined
                    text='Cancelar'
                    width='100%'
                    marginTop='16px'
                    onClick={() => {
                      setCurrentEditingFieldIndex(null)
                      setSelectedFields([])
                      setEditing(false)
                      setRegistering(false)
                      setFormData(null)
                      reset({ plantingType: null })
                    }}
                  />
                </Column>
              </>
            )}
            {confirmation && (
              <>
                <Text
                  variant='subtitles'
                  color='black'
                  fontWeight={600}
                  textAlign='center'
                  marginBottom='16px'
                >
                  Confirmação da safra
                </Text>
                <Text
                  variant='paragraph'
                  color='gray.n700'
                  fontWeight={400}
                  width='100%'
                  marginBottom='27px'
                >
                  Confirme as informações preenchidas.
                </Text>
                <Text
                  variant='caption'
                  color='gray.n700'
                  fontWeight={400}
                  width='100%'
                  marginBottom='8px'
                >
                  Áreas selecionadas
                </Text>
                <Text variant='paragraph' color='black' fontWeight={600} width='100%'>
                  {fieldsNames.join(', ')}
                </Text>
                <Row width='100%' marginTop='24px'>
                  <Column width='104px'>
                    <Text variant='caption' color='gray.n700' fontWeight={400} marginBottom='8px'>
                      Cultura
                    </Text>
                    <Text variant='paragraph' color='black' fontWeight={600}>
                      {formData.planting.name}
                    </Text>
                  </Column>
                  <Column>
                    <Text variant='caption' color='gray.n700' fontWeight={400} marginBottom='8px'>
                      Variedade
                    </Text>
                    <Text variant='paragraph' color='black' fontWeight={600}>
                      {formData.plantingType}
                    </Text>
                  </Column>
                </Row>
                <Row width='100%' marginTop='24px'>
                  <Column width='104px'>
                    <Text variant='caption' color='gray.n700' fontWeight={400} marginBottom='8px'>
                      Início
                    </Text>
                    <Text variant='paragraph' color='black' fontWeight={600}>
                      {moment(formData.startDate, 'YYYY-MM-DD').format('DD/MM/YY')}
                    </Text>
                  </Column>
                  <Column>
                    <Text variant='caption' color='gray.n700' fontWeight={400} marginBottom='8px'>
                      Fim
                    </Text>
                    <Text variant='paragraph' color='black' fontWeight={600}>
                      {moment(formData.endDate, 'YYYY-MM-DD').format('DD/MM/YY')}
                    </Text>
                  </Column>
                </Row>
                <Column flex={1} width='100%' justifyContent='flex-end'>
                  <Button text='Confirmar' onClick={() => onSubmit()} />
                  <Button
                    outlined
                    text='Cancelar'
                    marginTop='16px'
                    onClick={() => {
                      setConfirmation(false)
                      reset({ plantingType: formData.plantingType })
                    }}
                  />
                </Column>
              </>
            )}
          </Column>
        )}
      </Column>
      <MapCore center={mapLocation} zoom={zoom} flex={1} fields={fieldsData}>
        <Row position='absolute' right={24} top={24}>
          <IconButton
            icon='logout'
            color='white'
            resetCss
            onClick={() => {
              setLogoutModal(true)
            }}
          />
        </Row>

        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <InputSearch />
        </Autocomplete>

        {!registering && (
          <ActionButton
            big
            position='absolute'
            bottom={32}
            left={24}
            onClick={() => setRegistering(true)}
          />
        )}

        {fieldsData.map((field, index) => (
          <Polygon
            key={index}
            paths={field.markers}
            options={
              selectedFields.find(current => current.value === field.id) ||
              (registeredHarvests[currentEditingFieldIndex] &&
                !registering &&
                registeredHarvests[currentEditingFieldIndex].fields.find(
                  current => current.id === field.id
                ))
                ? selectedFieldsOptions
                : defaultOptions
            }
            onClick={() => {
              setCurrentEditingFieldIndex(index)
            }}
          />
        ))}

        {fieldsData.map((field, index) => {
          return (
            filterHarvest(field) && (
              <CultivationMarker
                cultivation={filterHarvest(field).cultivation}
                key={index}
                position={{
                  lng: Number(field.center.lng),
                  lat: Number(field.center.lat)
                }}
                icon={{
                  scaledSize: new window.google.maps.Size(50, 50),
                  anchor: new window.google.maps.Point(25, 25)
                }}
              />
            )
          )
        })}
      </MapCore>
      <Modal
        isOpen={modalRemove}
        title='Deseja mesmo excluir essa área?'
        text='Todas as informações cadastradas serão deletadas'
        bigger
        buttons={{
          firstButton: {
            action: () => handleHarvestDelete(currentEditingFieldIndex),
            text: 'Sim'
          },
          secondButton: {
            action: () => {
              setModalRemove(false)
              setCurrentEditingFieldIndex(null)
            },
            text: 'Não'
          }
        }}
      />
      <Modal
        isOpen={logoutModal}
        title='Você deseja mesmo sair?'
        bigger
        buttons={{
          firstButton: {
            action: () => {
              logout()
              history.push('/')
            },
            text: 'Sim'
          },
          secondButton: {
            action: () => setLogoutModal(false),
            text: 'Não'
          }
        }}
      />
      <Modal
        isOpen={skipModal}
        title='Deseja cadastrar a safra neste momento?'
        bigger
        buttons={{
          firstButton: {
            action: async () => {
              const { data } = await updatePropertyStep(
                property.id,
                getStepId('Ativação armadilhas')
              )
              setProperty(data.property)
              Mixpanel.track('Pulou cadastro de safra')
              history.push('/propriedade/cadastro/armadilha')
            },
            text: 'Não'
          },
          secondButton: {
            action: () => {
              setSkipModal(false)
              Mixpanel.track('Não pulou cadastro de safra')
            },
            text: 'Sim'
          }
        }}
      />
    </Row>
  ) : (
    <Column height='100vh' justifyContent='center' alignItems='center'>
      <Loader />
    </Column>
  )
}

export default AddHarvest
