import { center } from '@turf/turf'

export const getFieldCenter = fieldMarkers => {
  if (fieldMarkers) {
    const formattedPoints = fieldMarkers.map(currentValue => {
      return [parseFloat(currentValue.location_x), parseFloat(currentValue.location_y)]
    })

    const geoJson = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: formattedPoints
      },
      properties: {
        prop0: 'value0',
        prop1: 0.0
      }
    }

    const coordinates = center(geoJson).geometry.coordinates

    return { lng: coordinates[0], lat: coordinates[1] }
  }
}

export const getPolygonCenter = polygon => {
  const formattedPoints = polygon.map(coordinate => {
    return [parseFloat(coordinate.lng), parseFloat(coordinate.lat)]
  })

  const geoJson = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: formattedPoints
    },
    properties: {
      prop0: 'value0',
      prop1: 0.0
    }
  }

  const coordinates = center(geoJson).geometry.coordinates

  return { lng: coordinates[0], lat: coordinates[1] }
}

export const formatToFieldsContainer = fields => {
  return fields.map(field => {
    return {
      title: field.name,
      area: field.area
    }
  })
}

export const formatPolygons = polygons => {
  return polygons.map(currentValue => {
    return {
      ...currentValue,
      markers: currentValue.markers.map(coordinate => {
        return { location_y: coordinate.lng, location_x: coordinate.lat }
      })
    }
  })
}

export const formatCreateMarkers = (name, fields, id) => {
  return {
    properties_id: id,
    fields: [
      {
        name: name,
        markers: fields.markers.map(coordinate => ({
          location_y: String(coordinate.lat),
          location_x: String(coordinate.lng)
        }))
      }
    ]
  }
}
