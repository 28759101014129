import React, { useState, useEffect, useCallback } from 'react'
import { Autocomplete, Polygon, Marker } from '@react-google-maps/api'
import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'

import {
  Column,
  Row,
  Steps,
  FieldsContainer,
  FieldCard,
  Button,
  MapCore,
  IconButton,
  InputSearch,
  Input,
  Text,
  Modal,
  TrapCard,
  Loader
} from '../../components'

import { ReactComponent as Logo } from '../../assets/images/logoHorizontal.svg'

import disabled from '../../assets/images/icons/disabled.svg'
import active from '../../assets/images/icons/active.svg'

import { useProperty } from '../../context/PropertiesContext'
import { correctLatLng, insectControlSchema } from '../../utils'
import {
  getFieldsWithTraps,
  updateTrapStatus,
  getTrapEvents,
  getHarvestByFields,
  updateThreshold,
  createThreshold,
  updatePropertyStep,
  getInsects,
  getUserTraps
} from '../../services'
import { useAuth } from '../../context/AuthContext'
import { Mixpanel } from '../../utils/Mixpanel'

const ActivateTraps = () => {
  const { logout, user } = useAuth()

  const { property, getStepId, setProperty } = useProperty()
  const history = useHistory()

  property.property_step_id < getStepId('Ativação armadilhas') && history.push('/')

  property.property_step_id === getStepId('Completo') && history.push('/painel')

  const { control, setValue, handleSubmit, register, watch, errors } = useForm({
    resolver: yupResolver(insectControlSchema)
  })
  const {
    fields: controlInputs,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'controlLevels'
  })

  const watchControlLevels = watch('controlLevels')

  const controlledInputs = controlInputs.map((field, index) => {
    return {
      ...field,
      ...watchControlLevels[index]
    }
  })

  const [isLoading, setIsLoading] = useState(true)

  const [fields, setFields] = useState([])
  const [currentActivatingFieldIndex, setCurrentActivatingFieldIndex] = useState(null)
  const [isActivating, setIsActivating] = useState(false)
  const [isSettingControlLevel, setIsSettingControlLevel] = useState(false)

  const [trapsBattery, setTrapsBattery] = useState([])
  const [hasBatteryInfo, setHasBatteryInfo] = useState(false)
  const [insects, setInsects] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [logoutModal, setLogoutModal] = useState(false)

  const [autocomplete, setAutocomplete] = useState(null)
  const [zoom, setZoom] = useState()
  const [mapLocation, setMapLocation] = useState()

  const [userTraps, setUserTraps] = useState([])
  const [updateData, setUpdateData] = useState(true)

  const handleFieldEdit = fieldIndex => {
    setCurrentActivatingFieldIndex(fieldIndex)
    setMapLocation({
      lng: fields[fieldIndex]?.center?.lng,
      lat: fields[fieldIndex]?.center?.lat
    })
    verifyTrapsBattery(fields[fieldIndex].traps)
    setIsActivating(true)
    setZoom(20)
  }

  const handleGoBack = () => {
    if (isActivating) {
      setIsActivating(false)
      setIsSettingControlLevel(false)
      setTrapsBattery([])
      setZoom(17)
      setHasBatteryInfo(false)
      setCurrentActivatingFieldIndex(null)
      remove()
      return
    }

    history.push('/propriedade/cadastro/safra')
  }

  const onSubmitThreshold = values => {
    updateThresholds(fields[currentActivatingFieldIndex].allowedThresholds, values)
    activateTraps(fields[currentActivatingFieldIndex].traps)
    fields[currentActivatingFieldIndex].status = 'active'
    handleGoBack()
  }

  const onLoad = autocomplete => {
    setAutocomplete(autocomplete)
  }

  const onPlaceChanged = () => {
    setMapLocation(autocomplete.getPlace()?.geometry?.location)
    setZoom(16)
  }

  const finishTrapActivation = () => {
    setShowModal(!showModal)
  }

  const addInitialInfo = useCallback((fieldsArray, plantingsData, insectsData) => {
    const newArray = correctLatLng(fieldsArray).map(field => {
      return {
        ...field,
        allowedThresholds: filterThresholds(field.thresholds, insectsData),
        status: field.traps[0] ? (verifyTrapsStatus(field.traps) ? 'active' : 'disabled') : 'empty',
        cultivation: filterPlantings(plantingsData, field.id)
      }
    })
    return newArray
  }, [])

  const filterPlantings = (plantingsArray, fieldId) => {
    const newArray = []
    for (const field of plantingsArray) {
      if (field.fields_id === fieldId) {
        newArray.push(field.plantings[0].name)
      }
    }
    return newArray
  }

  const activateTraps = async value => {
    try {
      value.map(async trap => {
        await updateTrapStatus(trap.mac_address, true)
        trap.is_activated = true
      })
    } catch (error) {
      console.log(error)
    }
  }
  const verifyStatus = () => {
    return fields.every(field => {
      return field.status === 'active' || field.status === 'empty'
    })
  }
  const verifyTrapsStatus = traps => {
    return traps.every(trap => {
      return trap.is_activated
    })
  }
  const verifyTrapsBattery = async traps => {
    const newArray = []
    try {
      for (const trap of traps) {
        const { data } = await getTrapEvents(trap.mac_address)
        newArray.push(data[0].battery_level)
      }
      setHasBatteryInfo(true)
      setTrapsBattery(newArray)
    } catch (error) {
      console.log(error)
    }
  }
  const renderControlLevels = (thresholds, insectsData) => {
    insectsData.forEach(insect => {
      if (!thresholds.find(threshold => threshold.insects_id === insect.id)) {
        fields[currentActivatingFieldIndex].allowedThresholds.push({
          created: true,
          insects_id: insect.id,
          fields_id: fields[currentActivatingFieldIndex].id,
          insect: insect
        })
      }
    })

    thresholds.forEach(threshold => {
      append({ insectLevel: threshold.limiter ? threshold.limiter : '' })
    })
  }

  const updateThresholds = (thresholds, values) => {
    try {
      thresholds.forEach(async (threshold, index) => {
        threshold.created
          ? await createThreshold({
              insects_id: threshold.insects_id,
              fields_id: fields[currentActivatingFieldIndex].id,
              limiter: values.controlLevels[index].insectLevel
            })
          : updateThreshold(threshold.id, values.controlLevels[index].insectLevel)
        threshold.limiter = values.controlLevels[index].insectLevel
      })
    } catch (error) {
      console.log(error)
    }
  }

  const filterThresholds = (thresholds, insectsData) => {
    const newArray = []
    for (const insect of insectsData) {
      thresholds.forEach(threshold => {
        if (threshold.insects_id === insect.id) newArray.push(threshold)
      })
    }
    return newArray
  }

  const filterAssociatedTraps = () => {
    let count = 0
    fields.forEach(field => {
      count += field.traps.length
    })
    return count
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true)
      try {
        const { data } = await getFieldsWithTraps(property.id)
        const { data: plantingsData } = await getHarvestByFields(property.id)
        const { data: insectsData } = await getInsects()
        const { data: traps } = await getUserTraps(user.id)

        setUserTraps(traps)
        setInsects(
          insectsData.filter(insect =>
            ['Mosca do mediterrâneo', 'Mariposa oriental'].includes(insect.name)
          )
        )
        setFields(
          addInitialInfo(
            data,
            plantingsData,
            insectsData.filter(insect =>
              ['Mosca do mediterrâneo', 'Mariposa oriental'].includes(insect.name)
            )
          )
        )
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchInitialData()
  }, [property.id, addInitialInfo, updateData, user.id])

  const defaultOptions = {
    fillColor: '#EEEEEE',
    fillOpacity: 0.3,
    strokeColor: '#EEEEEE',
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }

  const activeFieldOptions = {
    fillColor: '#2E7D32',
    fillOpacity: 0.3,
    strokeColor: '#2E7D32',
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }

  return fields.length > 0 ? (
    <Row minHeight='100vh'>
      <Column backgroundColor='white' width='32vw' minHeight='100vh'>
        <Column alignItems='center' marginTop='24px'>
          <IconButton
            icon='arrowBack2'
            position='absolute'
            top={29.33}
            left={29.33}
            onClick={handleGoBack}
          />
          <Logo width='150px' />

          <Steps
            steps={['Identificação', 'Áreas', 'Safra', 'Armadilha']}
            currentStep={4}
            marginTop={24}
            width='70%'
          />
        </Column>

        {!isActivating && (
          <Column
            flex={1}
            position='fixed'
            left='0px'
            top='147px'
            height='calc(100% - 147px)'
            width={currentActivatingFieldIndex !== null ? 'calc(32vw + 70px)' : '32vw'}
            overflow='auto'
            zIndex='1'
          >
            <Column flex={1} alignItems='center' width='32vw' marginTop='18px'>
              <FieldsContainer
                disableButton
                traps={true}
                loading={isLoading}
                width='100%'
                fields={
                  fields && filterAssociatedTraps() === userTraps.length && userTraps.length > 0
                    ? fields
                    : []
                }
                onClickNewCard={() => {
                  setUpdateData(!updateData)
                  Mixpanel.track('Atualizou a lista de armadilhas instaladas')}}
                handleFieldEdit={handleFieldEdit}
                handleFieldCenter={handleFieldEdit}
                setCurrentEditingFieldIndex={setCurrentActivatingFieldIndex}
                currentEditingFieldIndex={currentActivatingFieldIndex}
              />

              <Button
                disabled={
                  !verifyStatus() ||
                  filterAssociatedTraps() !== userTraps.length ||
                  userTraps.length === 0
                }
                text='Avançar'
                onClick={() => {
                  setShowModal(true)
                }}
                width='100%'
                paddingLeft={24}
                paddingRight={24}
                marginBottom={24}
                marginTop={24}
              />
            </Column>
          </Column>
        )}
        {isActivating && (
          <Column flex={1} overflow='hidden'>
            <Text
              variant='subtitles'
              color='gray.n900'
              textAlign='center'
              fontWeight={600}
              marginTop={48}
            >
              {isSettingControlLevel ? 'Nível de controle' : 'Ativação da armadilha'}
            </Text>
            <Text
              variant='paragraph'
              color='gray.n700'
              textAlign='center'
              fontWeight={400}
              marginTop={16}
              marginBottom={16}
            >
              Ative e determine o nível de controle de pragas das armadilhas.
            </Text>

            <Column flex={1} width='100%' marginTop='8px'>
              <FieldCard
                traps
                disableButton
                disableOnClick
                key={currentActivatingFieldIndex}
                name={fields?.[currentActivatingFieldIndex]?.name}
                area={fields?.[currentActivatingFieldIndex]?.area}
                cultivation={fields?.[currentActivatingFieldIndex]?.cultivation}
                status={null}
                index={currentActivatingFieldIndex}
              />

              {!isSettingControlLevel && (
                <Column height='34vh' overflow='auto' overflowX='hidden'>
                  {!hasBatteryInfo ? (
                    <Column
                      justifyContent='center'
                      alignItems='center'
                      marginTop='50px'
                      marginBottom='50px'
                    >
                      <Loader />
                    </Column>
                  ) : (
                    fields[currentActivatingFieldIndex].traps.map((trap, index) => (
                      <TrapCard
                        key={index}
                        code={trap.mac_address}
                        battery={trapsBattery[index]}
                        locationLat={Number(trap.location_y)}
                        locationLng={Number(trap.location_x)}
                        index={index}
                        status={trap.is_activated ? 'active' : ' disabled'}
                      />
                    ))
                  )}
                </Column>
              )}
              {isSettingControlLevel &&
                controlledInputs.map((input, index) => {
                  return (
                    <Column width='100%' paddingLeft={24} paddingRight={24} marginTop={16}>
                      <Text
                        variant='paragraph'
                        color='gray.n900'
                        fontWeight={600}
                        marginBottom={16}
                      >
                        {fields[currentActivatingFieldIndex].allowedThresholds[index].insect.name}
                      </Text>
                      <Input
                        name={`controlLevels[${index}].insectLevel`}
                        label='Nível de controle'
                        register={register}
                        onChange={setValue}
                        control={control}
                        value={input.insectLevel}
                        error={
                          errors.controlLevels && errors.controlLevels[index]
                            ? errors.controlLevels[index]?.insectLevel.message
                            : null
                        }
                        width='100%'
                      />
                    </Column>
                  )
                })}
            </Column>

            <Button
              text={isSettingControlLevel ? 'Confirmar' : 'Ativar'}
              disabled={fields[currentActivatingFieldIndex]?.traps[0] ? false : true}
              onClick={
                isSettingControlLevel
                  ? handleSubmit(onSubmitThreshold)
                  : () => {
                      setIsSettingControlLevel(true)
                      fields[currentActivatingFieldIndex].allowedThresholds
                        ? renderControlLevels(
                            fields[currentActivatingFieldIndex].allowedThresholds,
                            insects
                          )
                        : renderControlLevels([], insects)
                    }
              }
              width='100%'
              paddingLeft={24}
              paddingRight={24}
              marginBottom={24}
              marginTop={24}
            />
          </Column>
        )}
      </Column>

      <MapCore center={mapLocation} zoom={zoom} width='68vw' height='100%' fields={fields}>
        <Row position='absolute' right={24} top={24}>
          <IconButton
            icon='logout'
            color='white'
            resetCss
            onClick={() => {
              setLogoutModal(true)
            }}
          />
        </Row>

        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <InputSearch />
        </Autocomplete>

        {isActivating ? (
          <>
            <Polygon
              key={fields[currentActivatingFieldIndex].index}
              paths={fields[currentActivatingFieldIndex].markers}
              options={
                fields[currentActivatingFieldIndex].status !== 'active'
                  ? defaultOptions
                  : activeFieldOptions
              }
              onClick={() => {
                handleGoBack()
              }}
            />
            {fields[currentActivatingFieldIndex].traps.map((trap, index) => (
              <Marker
                key={index}
                position={{
                  lng: Number(trap?.location_x),
                  lat: Number(trap?.location_y)
                }}
                icon={{
                  url: fields[currentActivatingFieldIndex]?.traps[index].is_activated
                    ? active
                    : disabled,
                  scaledSize: new window.google.maps.Size(50, 50),
                  anchor: new window.google.maps.Point(25, 25)
                }}
                onClick={() => {
                  handleGoBack()
                }}
              />
            ))}
          </>
        ) : (
          fields.map((field, index) => (
            <>
              <Polygon
                key={index}
                paths={field.markers}
                options={field.status !== 'active' ? defaultOptions : activeFieldOptions}
                onClick={() => {
                  if (fields && filterAssociatedTraps() === userTraps.length) {
                    verifyTrapsBattery(field.traps)
                    setMapLocation({
                      lng: field?.center?.lng,
                      lat: field?.center?.lat
                    })
                    setCurrentActivatingFieldIndex(index)
                    setIsActivating(true)
                    setZoom(18)
                  }
                }}
              />
              {window.google && (field.status === 'active' || field.status === 'disabled') && (
                <Marker
                  position={{ lng: field?.center?.lng, lat: field?.center?.lat }}
                  icon={{
                    url: field.status !== 'active' ? disabled : active,
                    scaledSize: new window.google.maps.Size(50, 50),
                    anchor: new window.google.maps.Point(25, 25)
                  }}
                  onClick={() => {
                    if (fields && filterAssociatedTraps() === userTraps.length) {
                      setMapLocation({
                        lng: field?.center?.lng,
                        lat: field?.center?.lat
                      })
                      verifyTrapsBattery(field.traps)
                      setCurrentActivatingFieldIndex(index)
                      setIsActivating(true)
                      setZoom(18)
                    }
                  }}
                />
              )}
            </>
          ))
        )}
      </MapCore>
      <Modal
        isOpen={showModal}
        title='Você concluiu o cadastro!'
        big
        buttons={{
          firstButton: {
            action: async () => {
              const { data } = await updatePropertyStep(property.id, getStepId('Completo'))
              finishTrapActivation()
              setProperty(data.property)
              history.push('/painel')
            },
            text: 'Ir para o dashboard'
          }
        }}
        icon
      />
      <Modal
        isOpen={logoutModal}
        title='Você deseja mesmo sair?'
        bigger
        buttons={{
          firstButton: {
            action: () => {
              logout()
              history.push('/')
            },
            text: 'Sim'
          },
          secondButton: {
            action: () => {
              setLogoutModal(false)
            },
            text: 'Não'
          }
        }}
      />
    </Row>
  ) : (
    <Column height='100vh' justifyContent='center' alignItems='center'>
      <Loader />
    </Column>
  )
}

export default ActivateTraps
