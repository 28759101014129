import React from 'react'

const SVGArrowBack = ({ width = 9, height = 14, color = '#424242' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 9 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.67331 1.5799L7.48664 0.399902L0.893311 6.9999L7.49331 13.5999L8.67331 12.4199L3.25331 6.9999L8.67331 1.5799Z'
      fill={color}
    />
  </svg>
)

export default SVGArrowBack
