import React, { useState, useEffect } from 'react'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers'
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Controller } from 'react-hook-form'
import moment from 'moment'
import MomentUtils from '@date-io/moment'

import { theme } from '../../theme'

const useStyles = makeStyles({
  root: {
    maxWidth: '150px'
  }
})

const defaultMaterialTheme = createMuiTheme({
  typography: {
    body1: {
      fontSize: 14
    },
    fontWeightRegular: 600,
    fontFamily: 'IBM Plex Sans, sans-serif'
  },
  palette: {
    primary: {
      main: theme.colors.secondary.three
    }
  }
})

const DateTimePickerComponent = ({ defaultValue, label, name, control, changeValue, ...props }) => {
  const [selectedDate, handleDateChange] = useState(moment(defaultValue))
  const classes = useStyles()

  useEffect(() => {
    changeValue(name, moment(defaultValue))
  })

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Controller
          name={name}
          control={control}
          render={props => (
            <KeyboardDateTimePicker
              className={classes.root}
              variant='inline'
              label={label}
              value={selectedDate}
              defaultValue={moment(defaultValue)}
              onChange={value => {
                handleDateChange(value)
                changeValue(name, value)
              }}
              format='HH:mm DD/MM'
              ampm={false}
              {...props}
            />
          )}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default DateTimePickerComponent
