import React, { createContext, useContext, useEffect, useState, useCallback } from 'react'
import {
  updateField,
  updateFieldMarkers,
  getProperties,
  newProperty,
  updateProperty,
  getSteps,
  getFieldsWithTraps,
  getHarvestByFields,
  getHarvestByPropertyId
} from '../services'
import { correctLatLng } from '../utils'
import { AuthContext } from './AuthContext'

import moment from 'moment'

export const PropertiesContext = createContext()

export const PropertiesContextProvider = ({ children }) => {
  const { user, logged, authLoading } = useContext(AuthContext)
  const [properties, setProperties] = useState()
  const [property, setProperty] = useState()
  const [propertyInfo, setPropertyInfo] = useState(null)
  const [fieldsData, setFieldsData] = useState([])
  const [fieldNumber, setFieldNumber] = useState(0)
  const [currentStep, setCurrentStep] = useState(1)
  const [harvestsData, setHarvestsData] = useState([])
  const [steps, setSteps] = useState()
  const [shouldUpdateHarvests, setShouldUpdateHarvests] = useState(false)
  const [propertiesLoading, setPropertiesLoading] = useState(true)

  const getPropertiesData = useCallback(async () => {
    try {
      const { data } = await getProperties(user.id)
      const { data: stepsData } = await getSteps()
      setSteps(stepsData)
      data.length > 0 && setProperty(data[data.length - 1])
      setProperties(data)
      setPropertiesLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [user])

  const getDashboardFields = async () => {
    const { data: fieldsWithTraps } = await getFieldsWithTraps(property.id)
    const { data: plantingsData } = await getHarvestByFields(property.id)
    setFieldsData(
      correctLatLng(
        fieldsWithTraps.map(current => ({
          ...current,
          status:
            current.traps?.length > 0
              ? current.traps.every(trap => trap.is_activated)
                ? 'active'
                : 'disabled'
              : 'empty',
          cultivation: plantingsData
            .filter(planting => planting.fields_id === current.id)
            .map(planting => planting.plantings[0].name)
        }))
      )
    )
  }

  const getDashboardHarvests = async () => {
    const { data: harvests } = await getHarvestByPropertyId(property.id)
    setHarvestsData(
      harvests.map(current => ({
        id: current.id,
        cultivationId: current.plantings[0].id,
        cultivation: current.plantings[0].name,
        plantingType: current.plantings[0].planting_type,

        planting: {
          id: current.plantings[0].id,
          name: current.plantings[0].name
        },
        startDate: moment(current.start_date).add(1, 'days'),
        endDate: moment(current.end_date).add(1, 'days'),
        dateRange: `${moment(current.start_date).add(1, 'days').format('DD/MM/YY')} - ${moment(
          current.end_date
        )
          .add(1, 'days')
          .format('DD/MM/YY')}`,
        fieldsCount: current.fields.length,
        fields: current.fields
      }))
    )
  }

  const addProperty = async (name, responsible) => {
    try {
      const { data } = await newProperty(user.id, name, responsible)
      setProperty(data)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const updatePropertyName = async (name, responsible, propertyId) => {
    try {
      await updateProperty(name, responsible, propertyId)
      updateInfo()
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const updateInfo = () => getPropertiesData()

  const updateFieldInfo = async (callback, name, fieldId) => {
    try {
      await updateField(name, fieldId)
      callback()
    } catch (error) {
      console.log(error)
    }
  }

  const updateFieldPoints = async (markers, fieldId) => {
    try {
      await updateFieldMarkers(markers, fieldId)
      return true
    } catch (error) {
      console.log(error)
    }
  }

  const clearProperties = () => {
    setProperties()
    setProperty(null)
    setPropertyInfo(null)
    setFieldsData([])
    setFieldNumber(0)
    setCurrentStep(1)
    setHarvestsData([])
  }
  const getStepId = stepName => {
    return steps.find(step => step.name === stepName).id
  }
  useEffect(() => {
    if (logged) {
      getPropertiesData()
    } else if (!authLoading) setPropertiesLoading(false)
  }, [logged, getPropertiesData, authLoading])

  useEffect(() => {
    !logged && clearProperties()
  }, [logged])

  return (
    <PropertiesContext.Provider
      value={{
        properties,
        propertyInfo,
        setPropertyInfo,
        property,
        setProperty,
        updateInfo,
        addProperty,
        updatePropertyName,
        fieldsData,
        setFieldsData,
        updateFieldInfo,
        updateFieldPoints,
        fieldNumber,
        setFieldNumber,
        setCurrentStep,
        currentStep,
        setHarvestsData,
        harvestsData,
        steps,
        getStepId,
        clearProperties,
        shouldUpdateHarvests,
        setShouldUpdateHarvests,
        propertiesLoading,
        getDashboardFields,
        getDashboardHarvests
      }}
    >
      {children}
    </PropertiesContext.Provider>
  )
}

export const useProperty = () => useContext(PropertiesContext)
