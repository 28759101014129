import React, { useState } from 'react'

import { Row, Column, Image, Steps, IconButton, Modal } from '../../components'

import { useProperty } from '../../context/PropertiesContext'
import { useAuth } from '../../context/AuthContext'

import PropertyIdentifier from './PropertyIdentifier'
import DemarcationAreas from './DemarcationAreas'

import logo from '../../assets/images/logo-partamon-1.svg'
import { useHistory } from 'react-router'

const AddProperty = () => {
  const { currentStep, setCurrentStep, property, getStepId, clearProperties } = useProperty()
  const { logout } = useAuth()

  const history = useHistory()

  const [logoutModal, setLogoutModal] = useState(false)

  property && property.property_step_id === getStepId('Completo') && history.push('/painel')

  return (
    <Column minHeight='100vh' backgroundColor='gray.n50' alignItems='center'>
      {currentStep === 2 && (
        <IconButton
          onClick={() => setCurrentStep(currentStep - 1)}
          icon='arrowBack2'
          position='absolute'
          top={24}
          left={24}
          zIndex={9999}
        />
      )}

      <Column flex={1} marginTop={24} width={['90%', 394]} alignItems='center' position='relative'>
        <Image src={logo} height={[92, 133]} width={[150, 216]} />

        <Steps
          steps={['Identificação', 'Áreas', 'Safra', 'Armadilha']}
          currentStep={currentStep}
          marginTop={16}
          width={['85%', 338]}
        />

        {currentStep === 1 && <PropertyIdentifier setCurrentStep={setCurrentStep} />}
        {currentStep === 2 && <DemarcationAreas setCurrentStep={setCurrentStep} />}
      </Column>

      <Row position='absolute' right={24} top={24}>
        <IconButton
          icon='logoutAlt'
          resetCss
          onClick={() => {
            setLogoutModal(true)
          }}
        />
      </Row>

      <Modal
        isOpen={logoutModal}
        title='Você deseja mesmo sair?'
        bigger
        buttons={{
          firstButton: {
            action: () => {
              logout()
              clearProperties()
              history.push('/')
            },
            text: 'Sim'
          },
          secondButton: {
            action: () => setLogoutModal(false),
            text: 'Não'
          }
        }}
      />
    </Column>
  )
}

export default AddProperty
