import React from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { theme } from '../../theme'

const useStyles = makeStyles({
  root: {
    color: theme.colors.secondary.three,
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.colors.secondary.three
    }
  }
})

const CheckboxComponent = ({
  indeterminate = false,
  disabled = false,
  defaultChecked = false,
  label,
  labelPlacement = 'end',
  ...props
}) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      control={
        <Checkbox
          className={classes.root}
          indeterminate={indeterminate}
          disabled={disabled}
          defaultChecked={defaultChecked}
          {...props}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}

export default CheckboxComponent
