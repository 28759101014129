import { fetchClient } from '../providers'

export const getFields = propertyId => fetchClient.get(`/v1/properties/${propertyId}/fields`)

export const updateField = (name, fieldId) =>
  fetchClient.put(`/v1/fields/${fieldId}`, { name: name })

export const updateFieldMarkers = (markers, fieldId) =>
  fetchClient.put(`/v1/fields/${fieldId}/markers`, { markers: markers })

export const markersCreate = data => {
  fetchClient.post('/v1/fields/markers', data)
}

export const deleteField = id => {
  fetchClient.delete(`v1/fields/${id}`)
}

export const simulateFieldArea = markers =>
  fetchClient.post('/v1/fields/simulate-area', { markers: markers })
