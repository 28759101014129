import React from 'react'

import { Column } from '../../components'

const CardComponent = props => <Column {...props} />

CardComponent.defaultProps = {
  backgroundColor: 'white',
  boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)'
}

export default CardComponent
