import { fetchClient } from '../providers'

export const registerNewHarvest = (
  fieldsId,
  startDate,
  endDate,
  plantingsId,
  plantingType,
  harvestName
) =>
  fetchClient.post('/v1/harvests', {
    start_date: startDate,
    end_date: endDate,
    plantings_id: plantingsId,
    planting_type: plantingType,
    fields_id: fieldsId,
    name: harvestName
  })

export const getPlantings = () => fetchClient.get('/v1/plantings')

export const deleteHarvest = id => fetchClient.delete(`/v1/harvests/${id}`)

export const updateHarvest = (
  id,
  fieldsId,
  startDate,
  endDate,
  plantingsId,
  plantingType,
  harvestName
) =>
  fetchClient.put(`/v1/harvests/${id}`, {
    start_date: startDate,
    end_date: endDate,
    plantings_id: plantingsId,
    planting_type: plantingType,
    fields_id: fieldsId,
    name: harvestName
  })

export const getHarvestById = id => fetchClient.get(`/v1/harvests/${id}`)

export const getHarvestByPropertyId = id => fetchClient.get(`/v1/properties/${id}/harvests`)

export const getHarvestByFields = id => fetchClient.get(`/v1/properties/${id}/fields/harvests`)
