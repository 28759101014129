import React, { useState, useEffect } from 'react'

import {
  Row,
  Column,
  Text,
  IconButton,
  FieldCard,
  Loader,
  Button,
  Link,
  Icon
} from '../../components'

import { formatArrayOfArrays } from '../../utils'

import { theme } from '../../theme'

const FieldsContainer = ({
  fields,
  handleFieldEdit,
  handleFieldRemove,
  handleFieldCenter,
  setCurrentEditingFieldIndex,
  currentEditingFieldIndex,
  openModal,
  loading,
  onClickNewCard,
  harvest = false,
  traps,
  disableButton,
  disableOnClick,
  ...props
}) => {
  const [paginatedFields, setPaginatedFields] = useState([[]])
  const [currentPage, setCurrentPage] = useState(0)
  useEffect(() => {
    setPaginatedFields(formatArrayOfArrays(5, fields))
    if (fields.length % 5 === 0) {
      setCurrentPage(c => (c > 0 && fields.length / 5 <= c ? c - 1 : c))
    }
  }, [fields, fields.length])

  const nextPage = () => {
    if (currentPage < paginatedFields.length - 1) {
      setCurrentPage(currentPage + 1)
    }
  }

  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const pageInfo = () => {
    const lastPage = currentPage === paginatedFields.length - 1

    if (!lastPage) {
      return `${currentPage * 5 + 1}-${currentPage * 5 + 5}`
    } else {
      if (currentPage === 0) {
        return `1-${fields.length}`
      }

      return `${currentPage * 5 + 1}-${fields.length}`
    }
  }

  return (
    <Column flex={1} {...props}>
      <Row
        backgroundColor='gray.n300'
        height={30}
        alignItems='center'
        justifyContent='space-between'
      >
        <Row>
          <Text variant='caption' color='gray.n800' marginLeft={24}>
            Status
          </Text>

          <Text variant='caption' color='gray.n800' marginLeft={40}>
            Áreas
          </Text>
        </Row>

        <Row alignItems='center'>
          <Text variant='caption' color='gray.n800' marginRight={20}>
            {fields.length === 0 ? '0 de 0' : `${pageInfo()} de ${fields.length}`}
          </Text>

          <IconButton
            icon='arrowBack'
            resetCss
            marginRight={24}
            disabled={currentPage === 0}
            color={currentPage === 0 ? theme.colors.gray.n500 : theme.colors.gray.n800}
            onClick={() => previousPage()}
          />
          <IconButton
            icon='arrowRight'
            resetCss
            marginRight={28}
            disabled={currentPage === paginatedFields.length - 1 || fields.length === 0}
            color={
              currentPage === paginatedFields.length - 1 || fields.length === 0
                ? theme.colors.gray.n500
                : theme.colors.gray.n800
            }
            onClick={() => nextPage()}
          />
        </Row>
      </Row>

      {loading ? (
        <Row flex={1} justifyContent='center' alignItems='center'>
          <Loader />
        </Row>
      ) : fields.length !== 0 ? (
        paginatedFields[currentPage]?.map((current, index) => (
          <FieldCard
            traps={traps}
            key={index}
            name={current.name}
            area={current.area}
            cultivation={current.cultivation}
            status={current.status}
            showMenu={!openModal ? currentPage * 5 + index === currentEditingFieldIndex : false}
            index={index}
            currentPage={currentPage}
            setCurrentField={setCurrentEditingFieldIndex}
            handleFieldEdit={handleFieldEdit}
            handleFieldRemove={handleFieldRemove}
            handleFieldCenter={handleFieldCenter}
            dateRange={current.dateRange}
            fieldsCount={current.fieldsCount}
            disableButton={disableButton}
            disableOnClick={disableOnClick}
          />
        ))
      ) : traps ? (
        <Column flex={1} alignItems='center'>
          <Column width='70%' alignItems='center'>
            <Icon icon='info' width={52} height={52} marginTop={50}></Icon>
            <Text
              variant='paragraph'
              fontWeight={600}
              color='feedback.informative'
              marginTop={'12px'}
              textAlign='center'
            >
              Ainda não recebemos informações de todas as suas armadilhas instaladas.
            </Text>

            <Text
              variant='caption'
              fontWeight={400}
              color='gray.n600'
              textAlign='center'
              marginTop={16}
            >
              Pode ser que demore um tempo até elas atualizarem.
            </Text>
            <Text
              variant='caption'
              fontWeight={400}
              color='gray.n600'
              textAlign='center'
              marginTop={16}
            >
              Caso tenha dúvidas ou algum problema entre em contato conosco!
            </Text>

            <Link marginTop={56} onClick={onClickNewCard}>
              Atualizar a página
            </Link>
          </Column>
        </Column>
      ) : (
        <Column flex={1} maxHeight={harvest ? '100%' : '194px'} alignItems='center'>
          <Column flex={1} justifyContent={harvest ? 'flex-start' : 'center'} alignItems='center'>
            <Text
              variant='paragraph'
              fontWeight={600}
              color='gray.n600'
              marginBottom='8px'
              marginTop={harvest && '50px'}
            >
              {harvest ? 'Nenhuma safra por aqui ainda...' : 'Nenhuma área por aqui ainda...'}
            </Text>
            <Text variant='caption' fontWeight={400} color='gray.n600'>
              {harvest
                ? 'Adicione as safras necessárias abaixo'
                : 'Adicione as áreas necessárias abaixo'}
            </Text>
          </Column>
          <Button
            text={harvest ? 'Adicionar uma safra' : 'Adicionar novas áreas'}
            width='100%'
            paddingLeft={24}
            paddingRight={24}
            onClick={onClickNewCard}
          />
        </Column>
      )}
    </Column>
  )
}

export default FieldsContainer
