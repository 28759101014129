import React, { useState, useEffect, useContext } from 'react'
import { Autocomplete, Polygon, DrawingManager, InfoWindow, Marker } from '@react-google-maps/api'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'

import {
  Column,
  Row,
  Steps,
  FieldsContainer,
  Button,
  MapCore,
  IconButton,
  ActionButton,
  InputSearch,
  Input,
  Text,
  Modal,
  Toolbar,
  RadioButton,
  InputCoordinate,
  Loader
} from '../../components'

import { ReactComponent as Logo } from '../../assets/images/logoHorizontal.svg'
import { Edit, Delete } from '../../icons'

import { useProperty } from '../../context/PropertiesContext'
import {
  demarcationSchema,
  getPolygonCenter,
  correctLatLng,
  formatCreateMarkers
} from '../../utils'
import {
  getFields,
  updateField,
  markersCreate,
  deleteField,
  updatePropertyStep,
  simulateFieldArea
} from '../../services'
import { AuthContext } from '../../context/AuthContext'

const searchOptions = [
  { label: 'Localidade', value: 'place', disabled: false, labelPlacement: 'end' },
  { label: 'Longitude e latitude', value: 'coordinate', disabled: false, labelPlacement: 'end' }
]

const Demarcation = () => {
  const { logout } = useContext(AuthContext)

  const { property, fieldNumber, setFieldNumber, getStepId, setProperty } = useProperty()
  const history = useHistory()

  property.property_step_id < getStepId('Demarcação no mapa') && history.push('/')

  property.property_step_id === getStepId('Completo') && history.push('/painel')

  const [isLoading, setIsLoading] = useState(true)
  const [isAreaLoading, setIsAreaLoading] = useState(false)

  const [fields, setFields] = useState([])
  const [currentEditingFieldIndex, setCurrentEditingFieldIndex] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [isViewInfo, setIsViewInfo] = useState(false)

  const [drawingInterface, setDrawingInterface] = useState(false)
  const [drawingMode, setDrawingMode] = useState(null)
  const [polygonCompleted, setPolygonCompleted] = useState(false)
  const [showInfoWindow, setShowInfoWindow] = useState(null)

  const [modalRemove, setModalRemove] = useState(false)
  const [modalCancel, setModalCancel] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [logoutModal, setLogoutModal] = useState(false)

  const [autocomplete, setAutocomplete] = useState(null)
  const [markerPosition, setMarkerPosition] = useState(null)
  const [zoom, setZoom] = useState(fields.length === 0 ? 5 : 16)
  const [isManualZoom, setIsManualZoom] = useState(false)
  const [mapLocation, setMapLocation] = useState(
    fields.length === 0
      ? { lng: -51.9253, lat: -14.235 }
      : {
          lng: fields[0]?.center?.lng,
          lat: fields[0]?.center?.lat
        }
  )
  function handleZoomChanged(newZoom) {
    isManualZoom ? setIsManualZoom(false) : setZoom(newZoom)
  }

  const { register, setValue, errors, watch, reset, handleSubmit } = useForm({
    defaultValues: { field_name: '', search_type: 'place', lat: '', lng: '' },
    resolver: yupResolver(demarcationSchema)
  })

  const values = watch(['field_name', 'search_type', 'lat', 'lng'])

  const handleFieldEdit = fieldIndex => {
    setIsEditing(true)
    setIsViewInfo(false)
    const currentField = fields[fieldIndex]
    reset({
      field_name: currentField?.name || `Talhão ${fieldNumber + 1}`,
      search_type: values.search_type
    })
    currentField?.center && setMapLocation(currentField?.center)
  }

  const handleFieldCenter = fieldIndex => {
    const currentField = fields[fieldIndex]
    setCurrentEditingFieldIndex(fieldIndex)
    currentField?.center && setMapLocation(currentField?.center)
  }

  const handleFieldDelete = () => {
    setModalRemove(true)
  }

  const handleGoBack = () => {
    if (isEditing || isViewInfo) {
      setIsEditing(false)
      setIsViewInfo(false)
      setCurrentEditingFieldIndex(null)
      return
    }
    history.push('/propriedade/cadastro/')
  }

  const handleCancel = () => {
    if (polygonCompleted) {
      setModalCancel(true)
      return
    }
    setIsEditing(false)
    setCurrentEditingFieldIndex(null)
    setDrawingMode(null)
    setDrawingInterface(false)
  }

  const cancelDuringEditing = () => {
    const fieldsArray = fields
    fieldsArray.pop()

    setFields(fieldsArray)
    setIsEditing(false)
    setPolygonCompleted(false)
    setCurrentEditingFieldIndex(null)
    setDrawingMode(null)
    setModalCancel(false)
    setDrawingInterface(false)
  }

  const validateEdit = () => {
    if (!drawingMode) {
      return false
    } else if (polygonCompleted) {
      return false
    } else {
      return true
    }
  }

  const handlePolygonCompleted = async polygon => {
    setPolygonCompleted(true)
    setDrawingMode(null)

    const markers = polygon
      .getPath()
      .getArray()
      .map(coordinate => {
        return {
          lat: coordinate.lat(),
          lng: coordinate.lng()
        }
      })

    markers.push(markers[0])

    const area = (await calculateFieldArea(markers)).toFixed(4)
    const center = getPolygonCenter(markers)
    setMapLocation(center)
    const newField = {
      name: `Talhão ${fieldNumber + 1}`,
      markers,
      area: area,
      center: center
    }

    setFields([...fields, newField])

    polygon.visible = false
  }

  const calculateFieldArea = async markers => {
    try {
      setIsAreaLoading(true)
      const formatedMarkers = markers.map(current => ({
        location_x: current.lng.toString(),
        location_y: current.lat.toString()
      }))
      const { data } = await simulateFieldArea(formatedMarkers)
      return data
    } catch (error) {
      console.log(error)
    } finally {
      setIsAreaLoading(false)
      setIsLoading(true)
      setIsLoading(false)
    }
  }

  const removeField = async () => {
    try {
      const id = fields[currentEditingFieldIndex].id
      await deleteField(id)

      const auxArray = fields

      if (currentEditingFieldIndex > -1) {
        auxArray.splice(currentEditingFieldIndex, 1)
      }

      setFields(auxArray)
      if (auxArray.length===0){
        const { data } = await updatePropertyStep(property.id, getStepId('Demarcação no mapa'))
        setProperty(data.property)
      }
      setCurrentEditingFieldIndex(null)
      setPolygonCompleted(false)
      setIsEditing(false)
      setIsViewInfo(false)
      setModalRemove(false)
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async values => {
    try {
      setIsLoading(true)

      if (polygonCompleted) {
        const formattedData = formatCreateMarkers(
          values.field_name,
          fields[currentEditingFieldIndex],
          property.id
        )

        await markersCreate(formattedData)
        setFieldNumber(fieldNumber + 1)
      } else {
        await updateField(values.field_name, fields[currentEditingFieldIndex].id)
      }

      setIsEditing(false)
      setIsViewInfo(false)
      setDrawingMode(null)
      setPolygonCompleted(false)
      setDrawingInterface(false)
      setCurrentEditingFieldIndex(null)

      setTimeout(() => {
        const fetchData = async () => {
          const { data } = await getFields(property.id)
          setFields(correctLatLng(data))
        }
        fetchData()
      }, 700)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onLoad = autocomplete => {
    setAutocomplete(autocomplete)
  }

  const onPlaceChanged = () => {
    setMapLocation(autocomplete.getPlace()?.geometry?.location)
    setZoom(16)
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const { data } = await getFields(property.id)
        setFields(correctLatLng(data))
        setIsLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    fetchInitialData()
  }, [property.id, drawingInterface])

  const defaultOptions = {
    fillColor: '#2E7D32',
    fillOpacity: 0.3,
    strokeColor: '#2E7D32',
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }

  const selectedFieldOptions = {
    fillColor: '#1565C0',
    fillOpacity: 0.3,
    strokeColor: '#1565C0',
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }

  return isLoading ? (
    <Column height='100vh' justifyContent='center' alignItems='center'>
      <Loader />
    </Column>
  ) : (
    <Row height='100vh'>
      <Column backgroundColor='white' width='32vw'>
        <IconButton
          icon='arrowBack2'
          position='absolute'
          top={29.33}
          left={29.33}
          onClick={handleGoBack}
        />
        <Column alignItems='center' marginTop='24px'>
          <Logo width='150px' />

          <Steps
            steps={['Identificação', 'Áreas', 'Safra', 'Armadilha']}
            currentStep={2}
            marginTop={24}
            width='70%'
          />
        </Column>

        {!isViewInfo && !isEditing && !drawingMode && (
          <Column
            flex={1}
            position='fixed'
            left='0px'
            top='165px'
            height='calc(100% - 165px)'
            width={currentEditingFieldIndex !== null ? 'calc(32vw + 70px)' : '32vw'}
            overflow='auto'
            zIndex='1'
          >
            <Column flex={1} alignItems='center' width='32vw' marginTop='18px'>
              <FieldsContainer
                isLoading={isLoading}
                width='100%'
                fields={fields}
                handleFieldEdit={handleFieldEdit}
                handleFieldRemove={handleFieldDelete}
                handleFieldCenter={handleFieldCenter}
                setCurrentEditingFieldIndex={setCurrentEditingFieldIndex}
                currentEditingFieldIndex={currentEditingFieldIndex}
                onClickNewCard={() => setDrawingInterface(true)}
                openModal={modalRemove}
              />

              {fields.length !== 0 && (
                <Button
                  text='Avançar'
                  onClick={async () => {
                    const { data } = await updatePropertyStep(
                      property.id,
                      getStepId('Cadastro de safras')
                    )
                    setProperty(data.property)
                    history.push('/propriedade/cadastro/safra')
                  }}
                  width='100%'
                  paddingLeft={24}
                  paddingRight={24}
                  marginBottom={24}
                  marginTop={24}
                />
              )}
            </Column>
          </Column>
        )}
        {isViewInfo && (
          <Column flex={1}>
            <Text
              variant='subtitles'
              color='gray.n900'
              textAlign='center'
              fontWeight={600}
              marginTop={48}
            >
              Informações da área
            </Text>

            <Column flex={1} width='100%' paddingLeft={24} paddingRight={24} marginTop='8px'>
              <Text variant='caption' marginTop={32} color='gray.n700'>
                Nome:
              </Text>

              <Text variant='paragraph' color='gray.n900' fontWeight={600} marginTop='8px'>
                {fields?.[currentEditingFieldIndex]?.name}
              </Text>

              <Text variant='caption' marginTop={32} color='gray.n700'>
                Área:
              </Text>
              <Text variant='paragraph' color='gray.n900' fontWeight={600} marginTop='8px'>
                {Number(fields?.[currentEditingFieldIndex]?.area).toFixed(4)} ha
              </Text>
            </Column>

            <Column paddingLeft={24} paddingRight={24}>
              <Button
                text='Editar'
                onClick={() => {
                  handleFieldEdit(currentEditingFieldIndex)
                }}
                icon={<Edit />}
                marginTop={24}
              />

              <Button
                text='Excluir'
                remove={true}
                onClick={() => {
                  handleFieldDelete()
                }}
                icon={<Delete />}
                marginTop={20}
                marginBottom={24}
              />
            </Column>
          </Column>
        )}
        {(isEditing || drawingMode) && (
          <Column flex={1}>
            <Text
              variant='subtitles'
              color='gray.n900'
              textAlign='center'
              fontWeight={600}
              marginTop={48}
            >
              Informações da área
            </Text>

            <Column flex={1} paddingLeft={24} paddingRight={24} marginTop={16}>
              <Text variant='paragraph' color='gray.n700'>
                Preencha e confirme as informações desse talhão.
              </Text>

              <Input
                name='field_name'
                label='Nome'
                register={register}
                onChange={setValue}
                mt={20}
                width='100%'
                value={values.field_name}
                error={errors.field_name?.message}
              />

              <Text variant='caption' marginTop={15} color='gray.n700'>
                Área:
              </Text>
              {isAreaLoading ? (
                <Loader />
              ) : (
                <Text variant='paragraph' color='gray.n900' fontWeight={600} marginTop='8px'>
                  {fields[currentEditingFieldIndex]
                    ? Number(fields?.[currentEditingFieldIndex]?.area).toFixed(4)
                    : '--'}{' '}
                  ha
                </Text>
              )}
            </Column>

            <Column paddingLeft={24} paddingRight={24}>
              <Button
                text='Confirmar'
                onClick={handleSubmit(onSubmit)}
                marginTop={24}
                disabled={validateEdit() || isAreaLoading}
              />

              <Button
                text='Cancelar'
                outlined
                onClick={handleCancel}
                marginTop={20}
                marginBottom={24}
                disabled={isAreaLoading}
              />
            </Column>
          </Column>
        )}
      </Column>

      <MapCore
        center={mapLocation}
        zoom={zoom}
        onZoomChanged={handleZoomChanged}
        width='68vw'
        height='100%'
        fields={fields}
      >
        <Row position='absolute' right={24} top={24}>
          <IconButton
            icon='logout'
            color='white'
            resetCss
            onClick={() => {
              setLogoutModal(true)
            }}
          />
        </Row>

        <Column position='absolute' left={24} top={24}>
          {values.search_type === 'place' ? (
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <InputSearch />
            </Autocomplete>
          ) : (
            <Row>
              <InputCoordinate
                placeholder='Longitude'
                suffix='Y'
                name='lng'
                setValue={setValue}
                value={values.lng}
                register={register}
                range={90}
              />
              <InputCoordinate
                placeholder='Latitude'
                suffix='X'
                name='lat'
                maxLenght={2}
                setValue={setValue}
                value={values.lat}
                marginLeft='8px'
                register={register}
                range={180}
              />
              <Button
                text='Ir'
                marginLeft='8px'
                disabled={values.lng === '' || values.lat === ''}
                onClick={() => {
                  setMapLocation({ lng: Number(values.lng), lat: Number(values.lat) })
                  setMarkerPosition({ lng: Number(values.lng), lat: Number(values.lat) })
                }}
              />
            </Row>
          )}

          {markerPosition && (
            <Marker position={markerPosition} onClick={() => setMarkerPosition(null)} />
          )}

          <RadioButton
            position='absolute'
            name='search_type'
            options={searchOptions}
            register={register}
            onChange={setValue}
            labelColor='white'
            value={values.search_type}
            row
          />
        </Column>

        {!drawingInterface && (
          <ActionButton
            big
            position='absolute'
            bottom={32}
            left={24}
            onClick={() => {
              setDrawingInterface(true)
            }}
          />
        )}

        {fields.map((field, index) => (
          <Polygon
            key={index}
            paths={field.markers}
            onMouseOver={() => setShowInfoWindow(index)}
            onMouseOut={() => setShowInfoWindow(null)}
            options={currentEditingFieldIndex === index ? selectedFieldOptions : defaultOptions}
            onClick={() => {
              setCurrentEditingFieldIndex(index)
              setIsEditing(false)
              setIsViewInfo(true)
              setMapLocation(field.center)
            }}
          />
        ))}

        {showInfoWindow !== null && (
          <InfoWindow position={fields[showInfoWindow].center}>
            <Text variant='paragraph' textAlign='center' color='#212121' fontWeight={600}>
              {fields[showInfoWindow].name}
            </Text>
          </InfoWindow>
        )}

        <DrawingManager
          options={{
            drawingMode: drawingMode,
            drawingControl: true,
            polygonOptions: {
              ...selectedFieldOptions
            }
          }}
          drawingModes={['polygon']}
          onPolygonComplete={polygon => handlePolygonCompleted(polygon)}
        />

        {drawingInterface && !drawingMode && (
          <Column position='absolute' bottom={32} right={24}>
            <Toolbar
              timelineClick={() => {
                setCurrentEditingFieldIndex(fields.length)
                setDrawingMode('polygon')
                handleFieldEdit(fields.length)
              }}
              openClick={() => {
                setDrawingMode(null)
              }}
              zoomInClick={() => {
                setIsManualZoom(true)
                setZoom(zoom + 0.2)
              }}
              zoomOutClick={() => {
                setIsManualZoom(true)
                setZoom(zoom - 0.2)
              }}
              deleteClick={() =>
                currentEditingFieldIndex !== null ? setModalRemove(true) : setModalDelete(true)
              }
            />
          </Column>
        )}
      </MapCore>
      <Modal
        isOpen={modalRemove}
        title='Deseja mesmo excluir essa área?'
        text='Todas as informações cadastradas serão deletadas'
        bigger
        buttons={{
          firstButton: {
            action: () => removeField(),
            text: 'Sim'
          },
          secondButton: {
            action: () => {
              if (!isViewInfo) {
                setCurrentEditingFieldIndex(null)
              }
              setModalRemove(false)
            },
            text: 'Não'
          }
        }}
      />

      <Modal
        isOpen={modalCancel}
        title='Deseja mesmo sair da edição?'
        text='Todas as informações cadastradas serão deletadas'
        bigger
        buttons={{
          firstButton: {
            action: () => {
              cancelDuringEditing()
            },
            text: 'Sim'
          },
          secondButton: {
            action: () => {
              setModalCancel(false)
            },
            text: 'Não'
          }
        }}
      />

      <Modal
        isOpen={modalDelete}
        title='Erro'
        text='Selecione um talhão para removê-lo'
        bigger
        buttons={{
          firstButton: {
            action: () => {
              setModalDelete(false)
            },
            text: 'Fechar'
          }
        }}
      />

      <Modal
        isOpen={logoutModal}
        title='Você deseja mesmo sair?'
        bigger
        buttons={{
          firstButton: {
            action: () => {
              logout()
              history.push('/')
            },
            text: 'Sim'
          },
          secondButton: {
            action: () => setLogoutModal(false),
            text: 'Não'
          }
        }}
      />
    </Row>
  )
}

export default Demarcation
