import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'
import MultiSelect from 'react-multi-select-component'

import { theme } from '../../theme'
import { Checkbox, Text } from '../../components'

const CustomMulti = styled(MultiSelect)`
  width: ${props => props.width || '395px'};
  
  --rmsc-hover: ${theme.colors.gray.n300};
  --rmsc-selected: ${theme.colors.gray.n200};
  --rmsc-border: ${props => (props.value[0] ? theme.colors.gray.n600 : theme.colors.gray.n200)};
  --rmsc-gray: ${props =>
    props.disabled
      ? theme.colors.gray.n500
      : props.value[0]
      ? theme.colors.gray.n800
      : theme.colors.gray.n600};

  & .go2642161244.dropdown-heading {
    cursor: pointer;
  }

  & .dropdown-heading-value {
    font-size: 16px;
    line-height: 21px;
  }

  & .go1075022653 input {
    font-size: 16px;
    line-height: 21px;
  }

  & .go2646822163 {
    background-color: ${props => props.value[0] ? theme.colors.gray.n50 : theme.colors.gray.n200};
    &:hover {
      border-color: ${theme.colors.feedback.confirmation}
    }
  }

  & .go2646822163:focus-within {
    background-color: ${theme.colors.white};
    border-color: ${props => props.disabled ? 'transparent' : theme.colors.secondary.three};
    box-shadow: none;
  }

  & ::-webkit-scrollbar-track {
    background-color: ${theme.colors.white};
  }

  & ::-webkit-scrollbar {
    width: 6px;
    background-color: ${theme.colors.white};
  }

  & ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.gray.n400};
  }

  & .go3425554998.dropdown-content {
    z-index: 2;
    & .panel-content {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
      overflow-x: hidden;
    }
  }
`
const MultiSelectComponent = ({
  name,
  control,
  register,
  changeValue,
  getValues,
  width,
  disabled = false,
  options,
  setState,
  value
}) => {
  const [selected, setSelected] = useState(value)

  useEffect(()=>{
    value.length > 0 && setSelected(value)
  },[value])

  useEffect(() => {
    register(name)
    changeValue(name, selected)
    setState(selected)
  }, [register, name, changeValue, selected, setState])

  return (
    <Controller
      name={name}
      control={control}
      render={props => (
        <CustomMulti
          disabled={disabled}
          width={width}
          options={options}
          value={selected}
          onChange={value => {
            setState && setState(value)
            setSelected(value)
            changeValue(name, value)
          }}
          overrideStrings={{
            selectSomeItems: 'Selecione os talhões',
            allItemsAreSelected: 'Todos talhões selecionados',
            selectAll: 'Selecionar todos',
            search: 'Buscar'
          }}

          ItemRenderer={({ checked, option, onClick, disabled }) => (
            <Checkbox
              onChange={onClick}
              disabled={disabled}
              label={
                <Text variant='paragraph' color={theme.colors.gray.n800} fontWeight={400}>
                  {option.label}
                </Text>
              }
              tabIndex={-1}
              checked={checked}
              labelStyle={{
                color: theme.colors.gray.n800,
                fontWeight: 400,
                size: '16px'
              }}
            />
          )}
        />
      )}
    />
  )
}

export default MultiSelectComponent
