import * as React from 'react'
import { VisibilityOutlined } from '@material-ui/icons'

function SvgVisibility (props) {
  return (
    <VisibilityOutlined style={{ ...props }} />
  )
}

export default SvgVisibility
