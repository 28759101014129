import * as React from 'react'
import { DeleteOutline } from '@material-ui/icons'

function SvgDelete (props) {
  return (
    <DeleteOutline style={{ ...props }} />
  )
}

export default SvgDelete
