import * as React from 'react'
import { CloudOutlined } from '@material-ui/icons'

function SvgCloud (props) {
  return (
    <CloudOutlined style={{ ...props }} />
  )
}

export default SvgCloud
