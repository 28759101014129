import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  // Map styles
  & .gmnoprint {
    display: none
  }

  & .gm-control-active {
    display: none
  }
`

export default GlobalStyle
