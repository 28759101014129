/**
 * Função para corrigir os dados de latitude e longitude que vem da API para o formato: {lat, lng},
 * utilizado no google maps para gerar os pontos
 *
 * Importante: Pontos devem estar como números e não como strings! Por isso a necessidade do parseFloat
 *
 */
import { getFieldCenter } from './'

export const correctLatLng = fields => {
  return fields.map(field => {
    return {
      ...field,
      center: getFieldCenter(field.markers),
      name: field.name,
      id: field.id,
      markers: field.markers.map(marker => {
        return {
          lng: parseFloat(marker.location_x),
          lat: parseFloat(marker.location_y)
        }
      })
    }
  })
}

export const formatPointsApi = field => {
  return field.map(mark => {
    return {
      location_x: mark.lng,
      location_y: mark.lat,
      location_z: null
    }
  })
}
