import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Polygon, Marker } from '@react-google-maps/api'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ReferenceLine,
  ResponsiveContainer
} from 'recharts'

import moment from 'moment'
import 'moment/locale/pt-br'

import {
  Row,
  Column,
  MapCore,
  IconButton,
  TrapCard,
  Text,
  Button,
  Image,
  Loader,
  DatePicker,
  Modal,
  Tab,
  Input
} from '../../components'
import { theme } from '../../theme'
import { useProperty } from '../../context/PropertiesContext'
import { useAuth } from '../../context/AuthContext'
import { getChartData, updateThreshold, getInsects, createTrapFloor } from '../../services'

import { ReactComponent as Logo } from '../../assets/images/logoHorizontal.svg'
import { Edit } from '../../icons'
import defaultField from '../../assets/images/defaultField.png'
import disabled from '../../assets/images/icons/disabled.svg'
import active from '../../assets/images/icons/active.svg'
import alerta from '../../assets/images/icons/Alerta.svg'
import critico from '../../assets/images/icons/Critico.svg'
import { insectLimiterSchema } from '../../utils'
import { Mixpanel } from '../../utils/Mixpanel'

moment.locale('pt-br')
const Micro = () => {
  const search = useLocation().search
  const insectId = new URLSearchParams(search).get('insectId')

  const history = useHistory()
  const { id: fieldId } = useParams()

  const [insectList, setInsectList] = useState([])
  const [chartRange, setChartRange] = useState(7)
  const [chartInsect, setChartInsect] = useState()
  const [insectWarningLevel, setInsectWarningLevel] = useState([])

  const { control, setValue, register, watch, handleSubmit } = useForm({
    resolver: yupResolver(insectLimiterSchema),
    defaultValues: { endDate: moment(), startDate: moment().subtract(chartRange, 'days') }
  })

  const { fieldsData, harvestsData, clearProperties, getDashboardFields, getDashboardHarvests } =
    useProperty()
  const { logout } = useAuth()

  const [currentFieldData, setCurrentFieldData] = useState()
  const [currentHarvestData, setCurrentHarvestdData] = useState()
  const [trapsBattery, setTrapBattery] = useState()

  const fetchInitialData = useCallback(async () => {
    try {
      await getDashboardFields()

      await getDashboardHarvests()
    } catch (error) {
      console.log(error)
    } finally {
    }
  }, [getDashboardFields, getDashboardHarvests])

  useEffect(() => {
    fieldsData.length === 0 && fetchInitialData()
    if (fieldsData.length > 0) {
      setCurrentFieldData(fieldsData.find(field => field.id === fieldId))

      if (currentFieldData) {
        setCurrentHarvestdData(
          harvestsData.find(harvest =>
            harvest.fields.find(field => field.id === currentFieldData?.id)
          )
        )
        setTrapBattery(currentFieldData?.traps?.map(trap => trap.traps_events[0].battery_level))

        setIsLoading(false)
      }
    }
  }, [fieldsData, fetchInitialData, currentFieldData, fieldId, harvestsData])

  useEffect(()=>{
    Mixpanel.track('Acessou a tela do micro')
  },[])

  const [logoutModal, setLogoutModal] = useState(false)

  const [isFloorDateLoading, setIsFloorDateLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isMapLoading, setIsMapLoading] = useState(true)
  const [isChartLoading, setIsChartLoading] = useState(true)
  const [isDatePickerChange, setIsDatePickerChange] = useState(false)
  const [isTabChange, setIsTabChange] = useState(false)
  const [isEditingLimiter, setIsEditingLimiter] = useState(false)

  const startDate = watch('startDate')
  const endDate = watch('endDate')
  const limiter = watch('limiter')

  const [chartData, setChartData] = useState(null)

  const defaultOptions = {
    fillColor: theme.colors.feedback.confirmation,
    fillOpacity: 0.3,
    strokeColor: theme.colors.feedback.confirmation,
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }
  const CautionOptions = {
    fillColor: theme.colors.feedback.alert,
    fillOpacity: 0.3,
    strokeColor: theme.colors.feedback.alert,
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }

  const CriticalOptions = {
    fillColor: theme.colors.feedback.error,
    fillOpacity: 0.3,
    strokeColor: theme.colors.feedback.error,
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }

  const [mapColor, setMapColor] = useState({
    field: defaultOptions,
    icon: active,
    trapListIcon: 'normalPin'
  })

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Column backgroundColor='white' padding='10px'>
          <Text>{`${label}`}</Text>
          <Text
            color={theme.colors.feedback.confirmation}
          >{`Média de pragas: ${payload[0].value}`}</Text>
          <Text
            color={theme.colors.feedback.confirmation}
          >{`Total de pragas: ${payload[0].payload.total_insects}`}</Text>
        </Column>
      )
    }

    return null
  }

  const fetchChartData = useCallback(async () => {
    setIsChartLoading(true)
    try {
      const { data } = await getChartData(
        fieldId,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        chartInsect
      )

      data.graphics = data.graphics?.map(current => ({
        ...current,
        average_insects: parseFloat(current.average_insects)
      }))

      setChartData(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsChartLoading(false)
    }
  }, [chartInsect, endDate, fieldId, startDate])

  const calculateWarningLevels = useCallback(async () => {
    const aux = []
    try {
      setIsChartLoading(true)
      for (const insect of insectList) {
        const { data } = await getChartData(
          fieldId,
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD'),
          insect.id
        )
        if (data?.threshold?.length > 0) {
          if (data.graphics.some(current => current.average_insects >= data.threshold[0].limiter))
            aux.push(1)
          else if (
            data.graphics.some(
              current => current.average_insects >= data.threshold[0].limiter * 0.8
            )
          )
            aux.push(2)
          else aux.push(3)
        } else aux.push(3)
      }
      setInsectWarningLevel(aux)
    } catch (error) {
      console.log(error)
    } finally {
      setIsChartLoading(false)
    }
  }, [endDate, fieldId, insectList, startDate])

  const handleMapColor = () => {
    const insectIndex = insectList.findIndex(
      current => current.id === chartData?.threshold?.[0]?.insects_id
    )
    const warningLevel = insectWarningLevel[insectIndex]

    if (warningLevel === 1)
      setMapColor({ field: CriticalOptions, icon: critico, trapListIcon: 'criticPin' })
    else if (warningLevel === 2)
      setMapColor({ field: CautionOptions, icon: alerta, trapListIcon: 'alertPin' })
    else setMapColor({ field: defaultOptions, icon: active, trapListIcon: 'normalPin' })
    setIsMapLoading(false)
  }

  useEffect(() => {
    if (insectList.length > 0) fetchChartData()
    // eslint-disable-next-line
  }, [startDate, endDate, chartInsect, fieldId, insectList])

  useEffect(() => {
    calculateWarningLevels()
    // eslint-disable-next-line
  }, [chartData])

  useEffect(() => {
    if (chartData) {
      handleMapColor()
    }
    // eslint-disable-next-line
  }, [chartData, insectWarningLevel])

  const submitFloor = async (trapId, date) => {
    try {
      setIsFloorDateLoading(true)
      await createTrapFloor(trapId, date)
      await getDashboardFields()
    } catch (error) {
      console.log(error)
    } finally {
      setIsFloorDateLoading(false)
    }
  }

  const dateTabOnclick = value => {
    setChartRange(value)
    setIsTabChange(true)
    calculateWarningLevels()
  }

  useEffect(() => {
    if (moment.isMoment(startDate) && moment.isMoment(endDate))
      if (startDate.isValid() && endDate.isValid()) {
        if (isDatePickerChange) {
          setChartRange(endDate.diff(startDate, 'days'))
          setIsDatePickerChange(false)
        } else {
          if (isTabChange && endDate.diff(startDate, 'days') !== chartRange) {
            setValue('endDate', moment())
            setValue('startDate', moment().subtract(chartRange, 'days'))
            setIsTabChange(false)
          }
        }
      } else {
        setIsDatePickerChange(true)
      }
    // eslint-disable-next-line
  }, [endDate, startDate, chartRange])

  useEffect(() => {
    const fetchInsectData = async () => {
      try {
        const { data } = await getInsects()
        setChartInsect(
          insectId
            ? parseInt(insectId)
            : data.filter(insect =>
                ['Mosca do mediterrâneo', 'Mariposa oriental'].includes(insect.name)
              )[0].id
        )
        setInsectList(
          data.filter(
            insect =>
              // eslint-disable-next-line
              insect.id == insectId ||
              ['Mosca do mediterrâneo', 'Mariposa oriental'].includes(insect.name)
          )
        )
      } catch (error) {
        console.log(error)
      }
    }

    fetchInsectData()
  }, [insectId])

  const onLimiterSubmit = async formData => {
    setIsChartLoading(true)
    try {
      await updateThreshold(chartData.threshold[0].id, formData.limiter)
      await fetchChartData()
      calculateWarningLevels()
    } catch (error) {
      console.log(error)
    } finally {
      setIsChartLoading(false)
      setIsEditingLimiter(false)
    }
  }

  return isLoading ? (
    <Column height='100vh' justifyContent='center' alignItems='center'>
      <Loader />
    </Column>
  ) : (
    <Row width='100%' height='100vh'>
      <Column width='32vw' height='100%' alignItems='center' padding='24px 0px 24px 0px'>
        <IconButton
          icon='arrowBack2'
          position='absolute'
          top={29.33}
          left={29.33}
          onClick={() => history.goBack()}
        />
        <Logo width='160px' />
        <Row
          width='100%'
          height='72px'
          marginTop='20px'
          alignItems='center'
          border={`solid 1px ${theme.colors.gray.n300}`}
        >
          <Image src={defaultField} height={28} width={51} marginLeft={24} marginRight={20} />
          <Text variant='paragraph' fontWeight={600}>
            {currentFieldData.name}
          </Text>
        </Row>
        <Column width='100%'>
          {currentFieldData.traps.length > 0 ? (
            currentFieldData.traps.map((trap, index) => (
              <TrapCard
                key={index}
                index={index}
                code={trap.mac_address}
                battery={trapsBattery[index]}
                locationLat={trap.location_y}
                locationLng={trap.location_x}
                microIcon={trap.is_activated ? mapColor.trapListIcon : 'disabledPin'}
                isMicro
                lastTrapEvent={moment(trap.traps_events[0].created_at).format('HH:mm DD/MM')}
                trapId={trap.id}
                submitFloor={submitFloor}
                floorData={trap.traps_floors[0]?.date_change}
                isFloorDateLoading={isFloorDateLoading}
              />
            ))
          ) : (
            <Row
              width='100%'
              height='106px'
              justifyContent='center'
              alignItems='center'
              backgroundColor={theme.colors.gray.n200}
              border={`1px solid ${theme.colors.gray.n300}`}
            >
              <Text variant='paragraph' fontWeight={600}>
                Não há armadilhas neste talhão atualmente!
              </Text>
            </Row>
          )}
        </Column>
        <Column flex={1} width='100%' padding='16px 24px 0px 24px'>
          <Row width='100%' marginBottom='24px'>
            <Column height='43px' justifyContent='space-between' minWidth='105px'>
              <Text variant='caption'>Área</Text>
              <Text variant='paragraph' fontWeight={600}>
                {`${currentFieldData.area.substring(0, currentFieldData.area.indexOf('.') + 5)} ha`}
              </Text>
            </Column>
            <Column height='43px' justifyContent='space-between' minWidth='105px'>
              <Text variant='caption'>Cultura</Text>
              <Text variant='paragraph' fontWeight={600}>
                {currentHarvestData ? currentHarvestData.cultivation : '-'}
              </Text>
            </Column>
            <Column height='43px' justifyContent='space-between' minWidth='105px'>
              <Text variant='caption'>Variedade</Text>
              <Text variant='paragraph' fontWeight={600}>
                {currentHarvestData ? currentHarvestData.plantingType : '-'}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column height='43px' justifyContent='space-between' minWidth='105px'>
              <Text variant='caption'>Inicio</Text>
              <Text variant='paragraph' fontWeight={600}>
                {currentHarvestData
                  ? moment(currentHarvestData.startDate).add(1, 'days').format('DD/MM/YY')
                  : '-'}
              </Text>
            </Column>
            <Column height='43px' justifyContent='space-between' minWidth='105px'>
              <Text variant='caption'>Fim</Text>
              <Text variant='paragraph' fontWeight={600}>
                {currentHarvestData
                  ? moment(currentHarvestData.endDate).add(1, 'days').format('DD/MM/YY')
                  : '-'}
              </Text>
            </Column>
          </Row>
        </Column>
        <Column width='90%' height='96px' justifyContent='space-between' display='none'>
          <Button text='Editar' />
          <Button outlined text='Excluir' />
        </Column>
      </Column>
      <Column flex={1} height='100%'>
        {isMapLoading ? (
          <Row
            flex={1}
            justifyContent='center'
            alignItems='center'
            backgroundColor={theme.colors.gray.n100}
          >
            <Loader />
          </Row>
        ) : (
          <MapCore fields={[currentFieldData]} zoom={5} flex={1}>
            <Polygon paths={currentFieldData.markers} options={mapColor.field} />

            {window.google &&
              currentFieldData.traps &&
              currentFieldData.traps.map((trap, index) => (
                <Marker
                  key={index}
                  position={{
                    lng: Number(trap?.location_x),
                    lat: Number(trap?.location_y)
                  }}
                  icon={{
                    url: currentFieldData.traps[index].is_activated ? mapColor.icon : disabled,
                    scaledSize: new window.google.maps.Size(50, 50),
                    anchor: new window.google.maps.Point(25, 25)
                  }}
                />
              ))}
          </MapCore>
        )}
        <Column width='100%' height='50vh'>
          <Row
            alignItems='center'
            paddingLeft='24px'
            width='100%'
            height='60px'
            backgroundColor={theme.colors.gray.n100}
          >
            {insectWarningLevel.length > 0 &&
              insectList.map((current, index) => (
                <Tab
                  value={current.id}
                  activationState={chartInsect}
                  setActivationState={setChartInsect}
                  text={current.name}
                  iconLeft={insectWarningLevel[index] === 3 ? 'done' : 'error'}
                  activeColor={
                    insectWarningLevel[index] === 1
                      ? theme.colors.feedback.error
                      : insectWarningLevel[index] === 2
                      ? theme.colors.feedback.alert
                      : theme.colors.feedback.confirmation
                  }
                  marginRight='28px'
                />
              ))}
          </Row>
          <Row
            alignItems='center'
            width='100%'
            height='45px'
            backgroundColor={theme.colors.gray.n100}
          >
            <Row
              flex={1}
              alignItems='center'
              justifyContent='space-between'
              padding='0px 118px 0px 26px'
            >
              <Tab
                value={1}
                activationState={chartRange}
                setActivationState={dateTabOnclick}
                text='Um dia'
              />
              <Tab
                value={7}
                activationState={chartRange}
                setActivationState={dateTabOnclick}
                text='7 dias'
              />
              <Tab
                value={15}
                activationState={chartRange}
                setActivationState={dateTabOnclick}
                text='15 dias'
              />
              <Tab
                value={30}
                activationState={chartRange}
                setActivationState={dateTabOnclick}
                text='1 mês'
              />
            </Row>
            <Row width='33vw' paddingRight='24px'>
              <Row onClick={() => setIsDatePickerChange(true)}>
                <DatePicker
                  name='startDate'
                  register={register}
                  changeValue={setValue}
                  value={startDate}
                  maxDate={moment().subtract(1, 'days')}
                  control={control}
                  label='Início'
                />
              </Row>
              <Row minWidth='24px' />
              <Row onClick={() => setIsDatePickerChange(true)}>
                <DatePicker
                  name='endDate'
                  register={register}
                  changeValue={setValue}
                  value={endDate}
                  maxDate={moment()}
                  minDate={moment(startDate).add(1, 'days')}
                  control={control}
                  label='Fim'
                />
              </Row>
            </Row>
          </Row>

          {isChartLoading ? (
            <Row
              flex={1}
              justifyContent='center'
              alignItems='center'
              backgroundColor={theme.colors.gray.n100}
            >
              <Loader />
            </Row>
          ) : chartData?.graphics.length > 0 && chartData?.threshold.length > 0 ? (
            <Row flex={1} alignItems='center' backgroundColor={theme.colors.gray.n100}>
              <Column alignItems='flex-start' height='100%' padding='24px'>
                <Text variant='caption' marginBottom='5px'>
                  Nível de Controle
                </Text>
                <Row alignItems='flex-end' marginBottom='20px'>
                  {isEditingLimiter ? (
                    <Input
                      name='limiter'
                      register={register}
                      onChange={setValue}
                      width='60px'
                      justifyContent='flex-end'
                      marginRight='10px'
                      autoFocus
                      value={limiter}
                    />
                  ) : (
                    <Text
                      variant='subtitles'
                      fontWeight={600}
                      margin='8px 10px 0px 0px'
                      color={theme.colors.gray.n700}
                      height='40px'
                    >
                      {chartData.threshold[0].limiter}
                    </Text>
                  )}
                  {isEditingLimiter ? (
                    <Row marginRight='-70px' alignItems='center'>
                      <IconButton
                        width='20px'
                        icon='checkCircle'
                        color={theme.colors.feedback.confirmation}
                        onClick={handleSubmit(onLimiterSubmit)}
                      />
                      <IconButton
                        width='20px'
                        icon='cancel'
                        color={theme.colors.feedback.error}
                        onClick={() => setIsEditingLimiter(false)}
                      />
                    </Row>
                  ) : (
                    <Row
                      alignItems='center'
                      marginBottom='12px'
                      style={{ cursor: 'pointer' }}
                      onClick={() => setIsEditingLimiter(true)}
                    >
                      <Edit color={theme.colors.gray.n700} />
                    </Row>
                  )}
                </Row>
              </Column>
              <Text
                variant='body'
                minWidth='190px'
                margin='0px -120px 0px -40px'
                style={{
                  transform: 'rotate(-90deg)'
                }}
              >
                Média de pragas identificadas
              </Text>
              <ResponsiveContainer width='82%' height='90%'>
                <BarChart data={chartData.graphics}>
                  <CartesianGrid vertical={false} />
                  <XAxis dataKey='reading_date' />
                  <YAxis domain={[0, dataMax => Math.round(dataMax * 0.2 + dataMax)]} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar name='Pragas' dataKey='average_insects' fill={theme.colors.secondary.five} />
                  <ReferenceLine
                    y={chartData.threshold[0].limiter}
                    stroke={theme.colors.feedback.error}
                    label={<Label position='insideBottomLeft'>Nível de controle</Label>}
                    ifOverflow='extendDomain'
                  />
                </BarChart>
              </ResponsiveContainer>
            </Row>
          ) : (
            <Row
              flex={1}
              justifyContent='center'
              alignItems='center'
              backgroundColor={theme.colors.gray.n100}
            >
              <Text variant='paragraph'>
                Por favor selecione outras datas ou outra praga a ser consultada.
              </Text>
            </Row>
          )}
        </Column>
      </Column>

      <Row position='absolute' right={24} top={24}>
        <IconButton
          icon='logout'
          color='white'
          resetCss
          onClick={() => {
            setLogoutModal(true)
          }}
        />
      </Row>

      <Modal
        isOpen={logoutModal}
        title='Você deseja mesmo sair?'
        bigger
        buttons={{
          firstButton: {
            action: () => {
              logout()
              clearProperties()
              history.push('/')
            },
            text: 'Sim'
          },
          secondButton: {
            action: () => setLogoutModal(false),
            text: 'Não'
          }
        }}
      />
    </Row>
  )
}

export default Micro
