import React from 'react'
import styled from 'styled-components'
import { ButtonGroup, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Timeline, Open, ZoomIn, ZoomOut, Delete } from '../../icons'
import { theme } from '../../theme'
import { Text, Column } from '../../components'

const VerticalLine = styled.div({
  backgroundColor: theme.colors.white,
  width: '1px',
  height: '32px',
  minWidth: '0px',
  alignSelf: 'center'
})

const useStyles = makeStyles({
  root: {
    color: theme.colors.white,
    backgroundColor: theme.colors.secondary.five,
    width: '369px',
    height: '56px',
    display: 'flex',
    justifyContent: 'space-around',
    '& .MuiButtonGroup-grouped': {
      minWidth: '0px'
    }
  }
})

const Toolbar = ({ timelineClick, openClick, zoomInClick, zoomOutClick, deleteClick }) => {
  const classes = useStyles()
  return (
    <ButtonGroup className={classes.root}>
      <IconButton onClick={timelineClick} style={{ borderRadius: '0px' }}>
        <Column flex={1} alignItems='center'>
          <Timeline color={theme.colors.white} />
          <Text fontSize={10} lineHeight='11.72px' color='white'>
            Desenhar
          </Text>
        </Column>
      </IconButton>
      <VerticalLine />
      <IconButton onClick={openClick}>
        <Column flex={1} alignItems='center'>
          <Open color={theme.colors.white} />
          <Text fontSize={10} lineHeight='11.72px' color='white'>
            Mover
          </Text>
        </Column>
      </IconButton>
      <VerticalLine />
      <IconButton onClick={zoomInClick}>
        <Column flex={1} alignItems='center'>
          <ZoomIn color={theme.colors.white} />
          <Text fontSize={10} lineHeight='11.72px' color='white'>
            Mais zoom
          </Text>
        </Column>
      </IconButton>
      <VerticalLine />
      <IconButton onClick={zoomOutClick}>
        <Column flex={1} alignItems='center'>
          <ZoomOut color={theme.colors.white} />
          <Text fontSize={10} lineHeight='11.72px' color='white'>
            Menos zoom
          </Text>
        </Column>
      </IconButton>
      <VerticalLine />
      <IconButton onClick={deleteClick} style={{ borderRadius: '0px' }}>
        <Column flex={1} alignItems='center'>
          <Delete color={theme.colors.white} />
          <Text fontSize={10} lineHeight='11.72px' color='white'>
            Excluir
          </Text>
        </Column>
      </IconButton>
    </ButtonGroup>
  )
}

export default Toolbar
