import * as React from 'react'
import { FilterListOutlined } from '@material-ui/icons'

function SvgFilterList (props) {
  return (
    <FilterListOutlined style={{ ...props }} />
  )
}

export default SvgFilterList
