import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Autocomplete, Polygon } from '@react-google-maps/api'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'

import { harvestSchema } from '../../utils'

import {
  Column,
  Row,
  Text,
  IconButton,
  Button,
  MultiSelect,
  Select,
  DatePicker,
  Input,
  MapCore,
  InputSearch,
  Modal,
  Loader
} from '../../components'

import { ReactComponent as Logo } from '../../assets/images/logoHorizontal.svg'
import { Error } from '../../icons'

import { theme } from '../../theme'

import { useProperty } from '../../context/PropertiesContext'
import { getPlantings, registerNewHarvest, updateHarvest } from '../../services'
import { useAuth } from '../../context/AuthContext'

const DashboardHarvest = () => {
  const search = useLocation().search
  const harvestId = new URLSearchParams(search).get('harvestId')

  const history = useHistory()
  const { logout } = useAuth()

  const {
    property,
    fieldsData,
    harvestsData,
    setShouldUpdateHarvests,
    getDashboardFields,
    getDashboardHarvests,
    getStepId
  } = useProperty()

  property.property_step_id < getStepId('Completo') && history.push('/')

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    register,
    watch,
    reset,
    formState: { errors }
  } = useForm({ resolver: yupResolver(harvestSchema) })

  const values = watch(['plantingType', 'startDate'])
  const [confirmation, setConfirmation] = useState(false)
  const editing = harvestId
  const [formData, setFormData] = useState(null)
  const [fieldsNames, setFieldsNames] = useState('')
  const [multiSelectOptions, setMultiSelectOptions] = useState([])
  const [plantingOptions, setPlantingOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedFields, setSelectedFields] = useState([])

  const [logoutModal, setLogoutModal] = useState(false)

  const [autocomplete, setAutocomplete] = useState(null)
  const [zoom, setZoom] = useState(16)
  const [mapLocation, setMapLocation] = useState({
    lng: fieldsData[0]?.center?.lng,
    lat: fieldsData[0]?.center?.lat
  })

  const fetchInitialData = useCallback(async () => {
    try {
      await getDashboardFields()
      harvestId && await getDashboardHarvests()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (harvestId) {
          const harvest = harvestsData.find(element => element.id === harvestId)
          setFormData({
            fields: harvest.fields.map(current => ({
              label: current.name,
              value: current.id
            })),
            planting: {
              id: harvest.cultivationId,
              name: harvest.cultivation
            },
            plantingType: harvest.plantingType,
            startDate: harvest.startDate,
            endDate: harvest.endDate
          })
        }
        await getAllPlantings()
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    if (fieldsData.length > 0) fetchData()
    else fetchInitialData()
  }, [property.id, harvestId, harvestsData, fetchInitialData, fieldsData.length])

  useEffect(() => {
    setMultiSelectOptions(
      fieldsData.map(current => ({
        label: current.name,
        value: current.id
      }))
    )
  }, [fieldsData])

  const onLoad = autocomplete => {
    setAutocomplete(autocomplete)
  }

  const onPlaceChanged = () => {
    setMapLocation(autocomplete.getPlace()?.geometry?.location)
    setZoom(16)
  }

  const defaultOptions = {
    fillColor: '#2E7D32',
    fillOpacity: 0.3,
    strokeColor: '#2E7D32',
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }

  const selectedFieldsOptions = {
    fillColor: '#1565C0',
    fillOpacity: 0.3,
    strokeColor: '#1565C0',
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }

  const getAllPlantings = async () => {
    try {
      const { data } = await getPlantings()
      setPlantingOptions(
        data.map(current => ({
          id: {
            id: current.id,
            name: current.name
          },
          value: current.name
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }

  const registerHarvest = async values => {
    const formatedFields = values.fields.map(field => ({ id: field.value }))
    try {
      editing
        ? await updateHarvest(
            harvestId,
            formatedFields,
            values.startDate,
            values.endDate,
            values.planting.id,
            values.plantingType
          )
        : await registerNewHarvest(
            formatedFields,
            values.startDate,
            values.endDate,
            values.planting.id,
            values.plantingType
          )
    } catch (error) {
      console.log(error)
    }
  }
  const onSubmit = async () => {
    await registerHarvest(formData)
    setShouldUpdateHarvests(true)
    history.goBack()
  }

  const onConfirmHarvest = values => {
    setFieldsNames(values.fields.map(current => current.label))
    setFormData(values)
    setConfirmation(true)
  }

  const handleGoBack = index => {
    if (confirmation) {
      setConfirmation(false)
    } else history.goBack()
  }

  return fieldsData.length > 0 ? (
    <Row width='100%' height='100vh'>
      <Column width='32vw' alignItems='center' marginTop='24px'>
        <IconButton
          icon='arrowBack2'
          position='absolute'
          top={29.33}
          left={29.33}
          onClick={handleGoBack}
        />
        <Logo width='150px' />
        {!isLoading && (
          <Column
            flex={1}
            width='100%'
            alignItems='center'
            justifyContent='flex-start'
            padding='24px'
            marginTop='24px'
            overflowY='auto'
            overflowX='hidden'
          >
            {!confirmation && (
              <>
                <Text variant='subtitles' color='black' fontWeight={600} textAlign='center'>
                  Informações da safra
                </Text>
                <Text variant='paragraph' color='gray.n700' margin='16px 24px 24px 24px'>
                  Preencha e confirme as informações da safra plantada nessas áreas.
                </Text>
                <MultiSelect
                  name='fields'
                  control={control}
                  changeValue={setValue}
                  getValues={getValues}
                  options={multiSelectOptions}
                  register={register}
                  value={formData ? formData.fields : []}
                  width='100%'
                  setState={setSelectedFields}
                />
                <Select
                  harvest
                  name='planting'
                  label='Cultura'
                  getValues={getValues}
                  register={register}
                  onChange={setValue}
                  value={formData ? formData.planting : ''}
                  options={plantingOptions}
                  margin='24px'
                  width='100%'
                />
                <Input
                  name='plantingType'
                  label='Variedade'
                  register={register}
                  onChange={setValue}
                  value={
                    values.plantingType
                      ? values.plantingType
                      : formData?.plantingType
                      ? formData.plantingType
                      : ''
                  }
                  width='100%'
                />
                <Row width='100%' margin='24px'>
                  <DatePicker
                    name='startDate'
                    register={register}
                    changeValue={setValue}
                    value={formData ? formData.startDate : null}
                    control={control}
                    label='Início'
                  />
                  <Row width='25px' />
                  <DatePicker
                    name='endDate'
                    register={register}
                    changeValue={setValue}
                    value={formData ? formData.endDate : null}
                    control={control}
                    label='Fim'
                    minDate={values.startDate}
                  />
                </Row>
                <Column flex={1} justifyContent='flex-end' width='100%'>
                  {errors?.endDate?.type !== 'min' && Object.keys(errors).length !== 0 && (
                    <Row
                      backgroundColor='rgba(198, 40, 40, 0.2)'
                      borderRadius={3}
                      height={27}
                      alignItems='center'
                      onClick={() => console.log(errors)}
                    >
                      <Row marginLeft={18} alignItems='center'>
                        <Error color={theme.colors.feedback.error} width={15} />
                        <Text variant='caption' color='feedback.error' marginLeft='5px'>
                          Todos os campos são obrigatorios
                        </Text>
                      </Row>
                    </Row>
                  )}
                  <Button text='Confirmar' width='100%' onClick={handleSubmit(onConfirmHarvest)} />
                  <Button
                    outlined
                    text='Cancelar'
                    width='100%'
                    marginTop='16px'
                    onClick={() => {
                      history.goBack()
                    }}
                  />
                </Column>
              </>
            )}
            {confirmation && (
              <>
                <Text
                  variant='subtitles'
                  color='black'
                  fontWeight={600}
                  textAlign='center'
                  marginBottom='16px'
                >
                  Confirmação da safra
                </Text>
                <Text
                  variant='paragraph'
                  color='gray.n700'
                  fontWeight={400}
                  width='100%'
                  marginBottom='27px'
                >
                  Confirme as informações preenchidas.
                </Text>
                <Text
                  variant='caption'
                  color='gray.n700'
                  fontWeight={400}
                  width='100%'
                  marginBottom='8px'
                >
                  Áreas selecionadas
                </Text>
                <Text variant='paragraph' color='black' fontWeight={600} width='100%'>
                  {fieldsNames.join(', ')}
                </Text>
                <Row width='100%' marginTop='24px'>
                  <Column width='104px'>
                    <Text variant='caption' color='gray.n700' fontWeight={400} marginBottom='8px'>
                      Cultura
                    </Text>
                    <Text variant='paragraph' color='black' fontWeight={600}>
                      {formData.planting.name}
                    </Text>
                  </Column>
                  <Column>
                    <Text variant='caption' color='gray.n700' fontWeight={400} marginBottom='8px'>
                      Variedade
                    </Text>
                    <Text variant='paragraph' color='black' fontWeight={600}>
                      {formData.plantingType}
                    </Text>
                  </Column>
                </Row>
                <Row width='100%' marginTop='24px'>
                  <Column width='104px'>
                    <Text variant='caption' color='gray.n700' fontWeight={400} marginBottom='8px'>
                      Início
                    </Text>
                    <Text variant='paragraph' color='black' fontWeight={600}>
                      {moment(formData.startDate, 'YYYY-MM-DD').format('DD/MM/YY')}
                    </Text>
                  </Column>
                  <Column>
                    <Text variant='caption' color='gray.n700' fontWeight={400} marginBottom='8px'>
                      Fim
                    </Text>
                    <Text variant='paragraph' color='black' fontWeight={600}>
                      {moment(formData.endDate, 'YYYY-MM-DD').format('DD/MM/YY')}
                    </Text>
                  </Column>
                </Row>
                <Column flex={1} width='100%' justifyContent='flex-end'>
                  <Button text='Confirmar' onClick={() => onSubmit()} />
                  <Button
                    outlined
                    text='Cancelar'
                    marginTop='16px'
                    onClick={() => {
                      setConfirmation(false)
                      reset({ plantingType: formData.plantingType })
                    }}
                  />
                </Column>
              </>
            )}
          </Column>
        )}
      </Column>
      <MapCore center={mapLocation} zoom={zoom} flex={1} fields={fieldsData}>
        <Row position='absolute' right={24} top={24}>
          <IconButton
            icon='logout'
            color='white'
            resetCss
            onClick={() => {
              setLogoutModal(true)
            }}
          />
        </Row>

        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <InputSearch />
        </Autocomplete>

        {fieldsData.map((field, index) => (
          <Polygon
            key={index}
            paths={field.markers}
            options={
              selectedFields.find(current => current.value === field.id)
                ? selectedFieldsOptions
                : defaultOptions
            }
          />
        ))}
      </MapCore>
      <Modal
        isOpen={logoutModal}
        title='Você deseja mesmo sair?'
        bigger
        buttons={{
          firstButton: {
            action: () => {
              logout()
              history.push('/')
            },
            text: 'Sim'
          },
          secondButton: {
            action: () => setLogoutModal(false),
            text: 'Não'
          }
        }}
      />
    </Row>
  ) : (
    <Column height='100vh' justifyContent='center' alignItems='center'>
      <Loader />
    </Column>
  )
}

export default DashboardHarvest
