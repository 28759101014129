import React from 'react'
import styled from 'styled-components'
import { space, layout, color, border, background } from 'styled-system'

const getProps = ({ backgroundImage, ...props }) => {
  if (!backgroundImage) {
    return props
  }

  return {
    as: 'div',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    ...props
  }
}

const ImageComponent = props => <Image {...getProps(props)} />

const Image = styled.img(background, space, layout, color, border)

export default ImageComponent
