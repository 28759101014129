import React from 'react'
import { ButtonGroup, IconButton, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Column, Row, Text } from '../../components'
import { Notification, List, Tractor, Cloud, Map } from '../../icons'
import { theme } from '../../theme'

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.gray.n50,
    width: '100%',
    height: '58px',
    display: 'flex',
    justifyContent: 'space-around',
    borderRadius: '0px',
    '& .MuiButtonGroup-grouped': {
      minWidth: '0px'
    },
    boxShadow: '0px -6px 20px rgba(0, 0, 0, 0.15)'
  },
  register: {
    backgroundColor: theme.colors.gray.n50,
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    '& .MuiButtonGroup-grouped': {
      minWidth: '0px'
    },
    boxShadow: '0px -6px 20px rgba(0, 0, 0, 0.15)'
  },
  button: {
    backgroundColor: theme.colors.secondary.five,
    width: '33.33%',
    color: theme.colors.white,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.colors.secondary.three
    },
    '&:disabled': {
      backgroundColor: theme.colors.gray.n500,
      color: theme.colors.white
    },
    '&.span': {
      marginLeft: '11px'
    }
  }
})

const BottomNavigator = ({
  selected = 0,
  firstButtom,
  disableFirstButtom = false,
  secondButtom,
  disableSecondButtom = false,
  thirdButtom,
  disableThirdButtom = false,
  fourthButtom,
  disableFourthButtom = false,
  hideFourthButtom = false,
  fifthButtom,
  disableFifthButtom = false,
  firstRegisteringButtom,
  disableFirstRegisteringButtom = false,
  secondRegisteringButtom,
  disableSecondRegisteringButtom = false,
  nextButton,
  disableNextButton,
  isMobile = false,
  isRegistering = false
}) => {
  const classes = useStyles()

  return (
    <>
      {!isMobile ? (
        <ButtonGroup className={classes.root}>
          <IconButton
            disabled={disableFirstButtom}
            onClick={firstButtom}
            style={{
              borderRadius: '15px',
              backgroundColor: selected === 0 ? theme.colors.gray.n200 : 'initial'
            }}
          >
            <Column
              flex={1}
              alignItems='center'
              height='53px'
              justifyContent='space-between'
              paddingTop='5px'
            >
              <Notification
                color={disableFirstButtom ? theme.colors.gray.n300 : theme.colors.gray.n700}
              />
              <Text variant='body'>Resumo</Text>
            </Column>
          </IconButton>
          <IconButton
            disabled={disableSecondButtom}
            onClick={secondButtom}
            style={{
              borderRadius: '15px',
              backgroundColor: selected === 1 ? theme.colors.gray.n200 : 'initial'
            }}
          >
            <Column
              flex={1}
              alignItems='center'
              height='53px'
              justifyContent='space-between'
              paddingTop='10px'
            >
              <List color={disableSecondButtom ? theme.colors.gray.n300 : theme.colors.gray.n700} />
              <Text variant='body'>Áreas</Text>
            </Column>
          </IconButton>
          <IconButton
            disabled={disableThirdButtom}
            onClick={thirdButtom}
            style={{
              borderRadius: '15px',
              backgroundColor: selected === 2 ? theme.colors.gray.n200 : 'initial'
            }}
          >
            <Column
              flex={1}
              alignItems='center'
              height='53px'
              justifyContent='space-between'
              paddingTop='5px'
            >
              <Tractor
                color={disableThirdButtom ? theme.colors.gray.n300 : theme.colors.gray.n700}
              />
              <Text variant='body'>Safras</Text>
            </Column>
          </IconButton>
          {!hideFourthButtom && (
            <IconButton
              disabled={disableFourthButtom}
              onClick={fourthButtom}
              style={{
                borderRadius: '15px',
                backgroundColor: selected === 3 ? theme.colors.gray.n200 : 'initial'
              }}
            >
              <Column
                flex={1}
                alignItems='center'
                height='53px'
                justifyContent='space-between'
                paddingTop='8px'
              >
                <Cloud
                  color={disableFourthButtom ? theme.colors.gray.n300 : theme.colors.gray.n700}
                  width='32px'
                />
                <Text variant='body'>Tempo</Text>
              </Column>
            </IconButton>
          )}
        </ButtonGroup>
      ) : isRegistering ? (
        <Row width='100vw' height='58px'>
          <ButtonGroup className={classes.register}>
            <IconButton
              disabled={disableFirstRegisteringButtom}
              onClick={firstRegisteringButtom}
              style={{
                borderRadius: '15px',
                padding: '0px 0px 0px 15px',
                backgroundColor: selected === 0 ? theme.colors.gray.n200 : 'initial'
              }}
            >
              <Column flex={1} alignItems='center'>
                <List
                  color={
                    disableFirstRegisteringButtom ? theme.colors.gray.n300 : theme.colors.gray.n700
                  }
                />
                <Text variant='body'>Lista</Text>
              </Column>
            </IconButton>
            <IconButton
              disabled={disableSecondRegisteringButtom}
              onClick={secondRegisteringButtom}
              style={{
                borderRadius: '15px',
                padding: '0px 15px 0px 0px',
                backgroundColor: selected === 0 ? theme.colors.gray.n200 : 'initial'
              }}
            >
              <Column flex={1} alignItems='center'>
                <Map
                  color={
                    disableSecondRegisteringButtom ? theme.colors.gray.n300 : theme.colors.gray.n700
                  }
                />
                <Text variant='body'>Mapa</Text>
              </Column>
            </IconButton>
          </ButtonGroup>
          <Button className={classes.button} disabled={disableNextButton} onClick={nextButton}>
            <Text variant='paragraph'>Avançar</Text>
          </Button>
        </Row>
      ) : (
        <ButtonGroup className={classes.root}>
          <IconButton onClick={firstButtom} style={{ borderRadius: '15px', padding: '0px' }}>
            <Column
              flex={1}
              width='52px'
              height='42px'
              alignItems='center'
              justifyContent='space-between'
            >
              <Notification color={theme.colors.gray.n700} />
              <Text variant='body'>Resumo</Text>
            </Column>
          </IconButton>
          <IconButton
            onClick={secondButtom}
            style={{
              borderRadius: '15px',
              padding: '0px',
              backgroundColor: selected === 0 ? theme.colors.gray.n200 : 'initial'
            }}
          >
            <Column
              flex={1}
              width='52px'
              height='42px'
              alignItems='center'
              justifyContent='space-between'
              paddingTop='3px'
            >
              <List color={theme.colors.gray.n700} />
              <Text variant='body'>Áreas</Text>
            </Column>
          </IconButton>
          <IconButton
            onClick={thirdButtom}
            style={{
              borderRadius: '15px',
              padding: '0px',
              backgroundColor: selected === 0 ? theme.colors.gray.n200 : 'initial'
            }}
          >
            <Column
              flex={1}
              width='52px'
              height='42px'
              alignItems='center'
              justifyContent='space-between'
            >
              <Map color={theme.colors.gray.n700} />
              <Text variant='body'>Mapa</Text>
            </Column>
          </IconButton>
          <IconButton
            onClick={fourthButtom}
            style={{
              borderRadius: '15px',
              padding: '0px',
              backgroundColor: selected === 0 ? theme.colors.gray.n200 : 'initial'
            }}
          >
            <Column
              flex={1}
              width='52px'
              height='42px'
              alignItems='center'
              justifyContent='space-between'
            >
              <Tractor color={theme.colors.gray.n700} />
              <Text variant='body'>Safras</Text>
            </Column>
          </IconButton>
          <IconButton
            onClick={fifthButtom}
            style={{
              borderRadius: '15px',
              padding: '0px',
              backgroundColor: selected === 0 ? theme.colors.gray.n200 : 'initial'
            }}
          >
            <Column
              flex={1}
              width='52px'
              height='42px'
              alignItems='center'
              justifyContent='space-between'
            >
              <Cloud color={theme.colors.gray.n700} />
              <Text variant='body'>Tempo</Text>
            </Column>
          </IconButton>
        </ButtonGroup>
      )}
    </>
  )
}

export default BottomNavigator
