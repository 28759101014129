import React, { useMemo } from 'react'
import { Marker } from '@react-google-maps/api'

import Apple from '../../assets/images/icons/MacieiraAlt.svg'
import Avocado from '../../assets/images/icons/AbacateAlt.svg'
import Citrus from '../../assets/images/icons/CitrusAlt.svg'
import Coffee from '../../assets/images/icons/CaféAlt.svg'
import Cotton from '../../assets/images/icons/AlgodãoAlt.svg'
import Grape from '../../assets/images/icons/VideiraAlt.svg'
import Mango from '../../assets/images/icons/MangueiraAlt.svg'
import Olive from '../../assets/images/icons/OliveiraAlt.svg'
import Papaya from '../../assets/images/icons/MamoeiroAlt.svg'
import Peach from '../../assets/images/icons/PessegueiraAlt.svg'
import Pear from '../../assets/images/icons/PereiraAlt.svg'
import Plum from '../../assets/images/icons/AmeixaAlt.svg'
import Rice from '../../assets/images/icons/ArrozAlt.svg'
import Soy from '../../assets/images/icons/SojaAlt.svg'
import Strawberry from '../../assets/images/icons/MorangueiroAlt.svg'
import SugarCane from '../../assets/images/icons/CanaDeAcucarAlt.svg'
import Tabacco from '../../assets/images/icons/TabacoAlt.svg'
import Tomato from '../../assets/images/icons/TomateiroAlt.svg'
import Wheat from '../../assets/images/icons/TrigoAlt.svg'
import HarvestDefault from '../../assets/images/icons/DefaultAlt.svg'

const ABACATEIRO = 'Abacateiro'
const AMEIXEIRA = 'Ameixeira'
const ALGODOEIRO = 'Algodoeiro'
const ARROZ = 'Arroz'
const CAFEEIRO = 'Cafeeiro'
const CANADEACUCAR = 'Cana-de-açúcar'
const CITRUS = 'Citros'
const MACIEIRA = 'Macieira'
const MAMOEIRO = 'Mamoeiro'
const MANGUEIRA = 'Mangueira'
const MORANGUEIRO = 'Morangueiro'
const OLIVEIRA = 'Oliveira'
const PEREIRA = 'Pereira'
const PESSEGUEIRO = 'Pessegueiro'
const SOJA = 'Soja'
const TABACO = 'Tabaco'
const TOMATEIRO = 'Tomateiro'
const TRIGO = 'Trigo'
const VIDEIRA = 'Videira'

const CultivationMarker = ({ icon: { scaledSize, anchor }, cultivation, ...props }) => {
  const Icon = useMemo(() => {
    switch (cultivation) {
      case ABACATEIRO:
        return Avocado
      case AMEIXEIRA:
        return Plum
      case ALGODOEIRO:
        return Cotton
      case ARROZ:
        return Rice
      case CAFEEIRO:
        return Coffee
      case CANADEACUCAR:
        return SugarCane
      case CITRUS:
        return Citrus
      case MACIEIRA:
        return Apple
      case MAMOEIRO:
        return Papaya
      case MANGUEIRA:
        return Mango
      case MORANGUEIRO:
        return Strawberry
      case OLIVEIRA:
        return Olive
      case PEREIRA:
        return Pear
      case PESSEGUEIRO:
        return Peach
      case SOJA:
        return Soy
      case TABACO:
        return Tabacco
      case TOMATEIRO:
        return Tomato
      case TRIGO:
        return Wheat
      case VIDEIRA:
        return Grape
      default:
        return HarvestDefault
    }
  }, [cultivation])

  return (
    <Marker
      icon={{
        url: Icon,
        scaledSize: scaledSize,
        anchor: anchor
      }}
      {...props}
    />
  )
}

export default CultivationMarker
