import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Text, Column, Input, Button, Loader, Row } from '../../components'

import { theme } from '../../theme'

import { PropertyIdentifierSchema } from '../../utils'
import { newProperty, updateCompanyName } from '../../services'
import { useAuth } from '../../context/AuthContext'

import { useProperty } from '../../context/PropertiesContext'

const PropertyIdentifier = ({ setCurrentStep }) => {
  const { user } = useAuth()
  const { setProperty, propertyInfo, setPropertyInfo, property, updatePropertyName, updateInfo } =
    useProperty()
  const { setValue, register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(PropertyIdentifierSchema),
    defaultValues: {
      company_name: propertyInfo
        ? propertyInfo.company_name
        : user.company_name
        ? user.company_name
        : '',
      property_name: propertyInfo
        ? propertyInfo.property_name
        : property
        ? property.property_name
        : ''
    }
  })

  const values = watch(['company_name', 'property_name'])

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async values => {
    try {
      setIsLoading(true)
      if (propertyInfo) {
        updatePropertyName(values.property_name, property.id)
        updateCompanyName(user.id, values.company_name)
        setPropertyInfo(values)
      } else {
        const { data } = await newProperty(user.id, values.property_name, values.company_name)
        setProperty(data)
        setPropertyInfo(values)
        updateInfo()
      }
      setCurrentStep(2)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (property) {
      setPropertyInfo(
        user.company_name ? { ...property, company_name: user.company_name } : property
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Column width='100%'>
      <Text
        variant={['paragraph', 'subtitles']}
        color='gray.n900'
        fontWeight={600}
        textAlign='center'
        marginTop={46}
      >
        Identificação da propriedade
      </Text>
      <Text variant={['body', 'paragraph']} color='gray.n700' marginTop={16}>
        Como primeiro passo, informe o seu nome, sua empresa e propriedade abaixo.
      </Text>
      <Input
        name='company_name'
        label='Nome da empresa'
        register={register}
        onChange={setValue}
        mt={32}
        value={values.company_name}
        error={errors.company_name?.message}
      />
      <Input
        name='property_name'
        label='Nome da propriedade*'
        register={register}
        onChange={setValue}
        mt={32}
        value={values.property_name}
        error={errors.property_name?.message}
      />
      <Text variant='caption' color='gray.n700' marginTop='8px'>
        Todas as opções marcadas com (*) são obrigatórias
      </Text>
      {isLoading ? (
        <Row width='100%' justifyContent='center' mt={32} border={`solid 1px ${theme.colors.gray.n500}`} borderRadius='4px'>
          <Loader />
        </Row>
      ) : (
        <Button
          text='Avançar'
          position={['absolute', 'relative']}
          bottom={16}
          width='100%'
          marginTop={64}
          onClick={handleSubmit(onSubmit)}
        />
      )}
    </Column>
  )
}

export default PropertyIdentifier
