import React, { useState } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

import { Column, Row, Text } from '../../components'
import { Visibility, VisibilityOff, Error } from '../../icons'

import { theme } from '../../theme'

const StyledInput = styled(TextField)`
  background-color: ${({ value }) => (value ? theme.colors.gray.n50 : theme.colors.gray.n200)};
  border-radius: 4px;

  & .Mui-focused {
    color: ${theme.colors.feedback.informative};
  }

  & .MuiInputLabel-outlined {
    transform: translate(14px, 13px) scale(1);
  }

  & .MuiInputBase-input {
    color: ${theme.colors.gray.n600};

    &:focus {
      background-color: ${theme.colors.gray.n50};
    }
  }

  & .MuiOutlinedInput-input {
    padding: 10.5px 14px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ value }) => (value ? theme.colors.gray.n600 : theme.colors.gray.n200)};
    border-width: 1px;
  }
`

const useStyles = makeStyles({
  root: {
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.colors.feedback.confirmation
      }
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd': {
      backgroundColor: theme.colors.gray.n50,
      paddingRight: '30px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.colors.feedback.confirmation
      }
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused.MuiInputBase-formControl': {
      paddingRight: '0px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.colors.feedback.confirmation,
        borderWidth: '1px'
      }
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd': {
      paddingRight: '30px'
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.Mui-focused.Mui-focused': {
      color: theme.colors.feedback.confirmation
    }
  }
})

const InputComponent = ({
  name,
  label,
  disabled = false,
  icon = false,
  register,
  onChange,
  error,
  description,
  value,
  variant = 'outlined',
  autoFocus = 'false',
  ...props
}) => {
  const classes = useStyles()
  const [secureText, setSecureText] = useState(!!icon)

  return (
    <Column {...props}>
      <StyledInput
        autoFocus ={autoFocus}
        className={classes.root}
        type={secureText ? 'password' : 'text'}
        name={name}
        value={value}
        variant={variant}
        label={label}
        disabled={disabled}
        inputRef={register}
        onChange={text => onChange(name, text.target.value)}
        InputLabelProps={{
          style: {
            height: 20
          }
        }}
        InputProps={{
          style: {
            height: 40
          },
          endAdornment: icon && (
            <IconButton
              onClick={() => setSecureText(!secureText)}
              style={{
                position: 'absolute',
                right: 13,
                padding: 0,
                backgroundColor: 'transparent'
              }}
            >
              <>
                {!secureText ? (
                  <VisibilityOff
                    right={20}
                    backgroundColor='transparent'
                    color={theme.colors.gray.n600}
                  />
                ) : (
                  <Visibility
                    right={20}
                    backgroundColor='transparent'
                    color={theme.colors.gray.n600}
                  />
                )}
              </>
            </IconButton>
          )
        }}
      />
      {error && (
        <Row
          backgroundColor='rgba(198, 40, 40, 0.2)'
          borderRadius={3}
          height={27}
          alignItems='center'
        >
          <Row marginLeft={18} alignItems='center'>
            <Error color={theme.colors.feedback.error} width={15} />
            <Text variant='caption' color='feedback.error' marginLeft='5px'>
              {error}
            </Text>
          </Row>
        </Row>
      )}
      {description && (
        <Text variant='caption' marginTop='4px' marginLeft={2}>
          {description}
        </Text>
      )}
    </Column>
  )
}

export default InputComponent
