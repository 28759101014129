export default {
  colors: {
    primary: {
      one: '#538325',
      two: '#66972E',
      three: '#7AAB38'
    },
    secondary: {
      one: '#1E653F',
      two: '#288653',
      three: '#2E975F',
      four: '#36AA6C',
      five: '#3DB977'
    },
    feedback: {
      error: '#C62828',
      confirmation: '#2E7D32',
      alert: '#F9A825',
      informative: '#1565C0'
    },
    white: '#FFFFFF',
    black: '#000000',
    gray: {
      n50: '#FAFAFA',
      n100: '#F5F5F5',
      n200: '#EEEEEE',
      n250: '#E5E5E5',
      n300: '#E0E0E0',
      n400: '#BDBDBD',
      n500: '#9E9E9E',
      n600: '#757575',
      n700: '#616161',
      n800: '#424242',
      n900: '#212121'
    }
  }
}
