import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Column, Text, Button, Icon, InputFile, Modal } from '../../components'

import { Publish } from '../../icons'

import { sendKml, getFields, updatePropertyStep, reSendKml } from '../../services'
import { useProperty } from '../../context/PropertiesContext'
import { correctLatLng } from '../../utils'
import { Mixpanel } from '../../utils/Mixpanel'

const DemarcationAreas = () => {
  const fileRef = useRef()
  const formData = new FormData()
  const history = useHistory()
  const {
    property,
    setFieldsData,
    fieldsData,
    setFieldNumber,
    fieldNumber,
    getStepId,
    setProperty
  } = useProperty()

  const [openModal, setOpenModal] = useState(false)
  const [reuploadModal, setReuploadModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const fileHandler = async (event, isReupload = false) => {
    formData.append('file', event.target.files[0])
    try {
      setOpenModal(true)
      setIsLoading(true)
      isReupload ? await reSendKml(property.id, formData) : await sendKml(property.id, formData)
      const { data } = await getFields(property.id)
      setFieldsData(correctLatLng(data))
      setFieldNumber(data.length)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const checkProperty = async () => {
      try {
        const { data } = await getFields(property.id)
        setFieldsData(correctLatLng(data))
      } catch (error) {
        console.log(error)
      }
    }

    checkProperty()
  }, [property.id, setFieldsData])

  return (
    <Column width='100%'>
      <Text
        variant={['paragraph', 'subtitles']}
        color='gray.n900'
        fontWeight={600}
        textAlign='center'
        marginTop={46}
      >
        Demarcação das áreas
      </Text>

      <Text variant={['body', 'paragraph']} color='gray.n700' marginTop={16}>
        Escolha a maneira que você deseja demarcar as áreas da sua propriedade.
      </Text>

      <InputFile
        fileRef={fileRef}
        accept='.kml, .kmz'
        onChange={event => fileHandler(event, fieldsData.length > 0)}
      />
      <Button
        text='Automático'
        icon={<Publish color='white' />}
        marginTop={32}
        onClick={async () => {
          Mixpanel.track('Cadastrou áreas automaticamente')
          if (fieldsData.length > 0) {
            setReuploadModal(true)
          } else fileRef.current.click()
        }}
      />
      <Text variant='caption' color='gray.n700' marginTop='8px'>
        Importe arquivos de KML e/ou KMZ.
      </Text>

      <Button
        text='Manual'
        marginTop={32}
        icon={<Icon icon='edit' />}
        onClick={async () => {
          Mixpanel.track('Cadastrou áreas manualmente')
          const { data } = await updatePropertyStep(property.id, getStepId('Demarcação no mapa'))
          setProperty(data.property)
          history.push('/propriedade/cadastro/talhao')
        }}
      />
      <Text variant='caption' color='gray.n700' marginTop='8px'>
        Demarque manualmente as áreas de sua propriedade no mapa.
      </Text>

      <Modal
        isOpen={openModal}
        title={`${fieldNumber} áreas foram encontradas.`}
        text='Deseja continuar?'
        loading={isLoading}
        buttons={{
          firstButton: {
            text: 'Sim',
            action: async () => {
              setOpenModal(false)
              const { data } = await updatePropertyStep(
                property.id,
                getStepId('Demarcação no mapa')
              )
              setProperty(data.property)
              history.push('/propriedade/cadastro/talhao')
            }
          },
          secondButton: {
            text: 'Não',
            action: () => {
              setOpenModal(false)
            }
          }
        }}
        icon
      />

      <Modal
        isOpen={reuploadModal}
        title='Identificamos que já existem marcações cadastradas para essa propriedade.'
        text='Deseja mesmo assim subir um arquivo novo?'
        loading={isLoading}
        buttons={{
          firstButton: {
            text: 'Sim',
            action: () => {
              setReuploadModal(false)
              fileRef.current.click()
            }
          },
          secondButton: {
            text: 'Não',
            action: () => {
              history.push('/propriedade/cadastro/talhao')
            }
          }
        }}
        bigger
      />
    </Column>
  )
}

export default DemarcationAreas
