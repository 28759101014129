import { fetchClient } from '../providers'

export const sendKml = (propertyId, formData) =>
  fetchClient.put(`/v1/properties/${propertyId}`, formData, {
    headers: { 'content-type': 'multipart/form-data' }
  })

export const reSendKml = (propertyId, file) =>
  fetchClient.put(`/v1/properties/${propertyId}/kml`, file, {
    headers: { 'content-type': 'multipart/form-data' }
  })
