import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { theme } from '../../theme'

import { Column, Button, Text, Input, Card, Image, Icon, Row, Loader } from '../../components'

import backgroundImage from '../../assets/images/bg.png'
import logo from '../../assets/images/logo.png'

import { resetPasswordSchema } from '../../utils'
import { resetPassword } from '../../services'

import { toast } from 'react-toastify'

const ResetPassword = () => {
  const { register, setValue, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      password: '',
      passwordConfirmation: ''
    },
    resolver: yupResolver(resetPasswordSchema)
  })

  const values = watch(['password', 'passwordConfirmation'])

  const history = useHistory()
  const { token } = useParams()

  const [wasSent, setWasSent] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { logged } = useAuth()

  useEffect(() => {
    if (logged) history.push('/')
  }, [history, logged])

  const handleClick = () => {
    history.push('/')
  }

  const onSubmit = async values => {
    try {
      setIsSubmitting(true)
      const data = await resetPassword({ token: token, password: values.password })
      setWasSent(data)
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Column minHeight='100vh' backgroundColor='gray.n250' alignItems='center'>
      <Image backgroundImage={backgroundImage} width='100vw' height='33vh' />
      <Card
        width={[326, 418]}
        borderRadius={6}
        marginTop='-20vh'
        p={[32, 64]}
        alignItems='center'
        as='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Image src={logo} height={[92, 133]} width={[150, 216]} />

        {!wasSent ? (
          <Column width='100%'>
            <Text variant='subtitles' color='gray.n900' fontWeight={600} mt={32}>
              Redefina sua senha
            </Text>

            <Input
              name='password'
              label='Nova senha'
              register={register}
              onChange={setValue}
              icon
              mt={32}
              value={values.password}
              error={errors.password?.message}
            />
            <Input
              name='passwordConfirmation'
              label='Repita a senha'
              register={register}
              onChange={setValue}
              icon
              mt={32}
              value={values.passwordConfirmation}
              error={errors.passwordConfirmation?.message}
            />
            {isSubmitting ? (
              <Row
                width='100%'
                justifyContent='center'
                mt={32}
                border={`solid 1px ${theme.colors.gray.n500}`}
                borderRadius='4px'
              >
                <Loader />
              </Row>
            ) : (
              <Button text={'Redefinir'} width='100%' mt={32} />
            )}
          </Column>
        ) : (
          <Column width='100%'>
            <Row alignItems='center' justifyContent='center'>
              <Icon
                icon='checkCircleOutline'
                color={theme.colors.secondary.five}
                width={33}
                height={33}
                mt={32}
                mr={20}
              />
              <Text variant='paragraph' color='gray.n700' fontWeight={400} mt={32}>
                Sua senha foi redefinida com sucesso.
              </Text>
            </Row>
            <Button text={'Voltar'} width='100%' mt={32} onClick={handleClick} />
          </Column>
        )}
      </Card>
    </Column>
  )
}

export default ResetPassword
