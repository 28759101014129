import * as React from 'react'
import { SettingsOutlined } from '@material-ui/icons'

function SvgSettings (props) {
  return (
    <SettingsOutlined style={{ ...props }} />
  )
}

export default SvgSettings
