import React from 'react'
import { GoogleMap, useLoadScript } from '@react-google-maps/api'

const libraries = ['drawing', 'places']


const MapCore = ({
  children,
  center,
  width,
  height,
  flex,
  zoom = 16,
  fields,
  extraOnLoadFunction,
  onZoomChanged
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries
  })

  const containerStyle = {
    flex: flex,
    width: width,
    heith: height
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }


  function handleZoomChanged(){
    onZoomChanged && onZoomChanged(this.getZoom())
  }

  const fitMarkersBounds = map => {

    if (fields && fields.length !== 0) {
      const bounds = new window.google.maps.LatLngBounds()
      fields.map(field =>
        field.markers.map(marker =>
          bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng))
        )
      )
      map.fitBounds(bounds)
    }
  }

  const onLoad = map => {
    fitMarkersBounds(map)
    if (extraOnLoadFunction) extraOnLoadFunction()
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={zoom}
      center={center}
      onLoad={onLoad}
      mapTypeId='satellite'
      onZoomChanged={handleZoomChanged}
    >
      {children}
    </GoogleMap>
  ) : (
    <div>Carregando...</div>
  )
}

export default MapCore
