import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
     mixpanel.track(name, props);
  },
  reset:()=>{
    mixpanel.reset();
  },
  people: {
    set_once: (props) => {
      mixpanel.people.set_once(props);
    },
  },
};


export const Mixpanel = actions;