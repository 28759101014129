import * as React from 'react'
import { ZoomOutOutlined } from '@material-ui/icons'

function SvgZoomOut (props) {
  return (
    <ZoomOutOutlined style={{ ...props }} />
  )
}

export default SvgZoomOut
