import React from 'react'

const SVGArrowRight = ({ width = 9, height = 14, color = '#424242' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 9 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.32666 12.4199L1.50666 13.5999L8.10666 6.9999L1.50666 0.399902L0.32666 1.5799L5.74666 6.9999L0.32666 12.4199Z'
      fill={color}
    />
  </svg>
)

export default SVGArrowRight
