import React from 'react'

const SVGAccount = ({ width = 48, height = 48, color = '#3DB977' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='24' cy='24' r='24' fill={color} />
    <path
      d='M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM14.14 36.56C15 34.76 20.24 33 24 33C27.76 33 33.02 34.76 33.86 36.56C31.14 38.72 27.72 40 24 40C20.28 40 16.86 38.72 14.14 36.56ZM36.72 33.66C33.86 30.18 26.92 29 24 29C21.08 29 14.14 30.18 11.28 33.66C9.24 30.98 8 27.64 8 24C8 15.18 15.18 8 24 8C32.82 8 40 15.18 40 24C40 27.64 38.76 30.98 36.72 33.66ZM24 12C20.12 12 17 15.12 17 19C17 22.88 20.12 26 24 26C27.88 26 31 22.88 31 19C31 15.12 27.88 12 24 12ZM24 22C22.34 22 21 20.66 21 19C21 17.34 22.34 16 24 16C25.66 16 27 17.34 27 19C27 20.66 25.66 22 24 22Z'
      fill='white'
    />
  </svg>
)

export default SVGAccount
