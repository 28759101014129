import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Column, Button, Text, Input, Link, Card, Image, Loader, Row } from '../../components'

import backgroundImage from '../../assets/images/bg.png'
import logo from '../../assets/images/logo-partamon-1.svg'

import { loginSchema } from '../../utils'
import { useProperty } from '../../context/PropertiesContext'

import { theme } from '../../theme'

import { toast } from 'react-toastify'

const Login = () => {
  const { register, setValue, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      password: '',
      email: ''
    },
    resolver: yupResolver(loginSchema)
  })

  const [loading, setLoading] = useState(localStorage.getItem('id'))
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const values = watch(['password', 'email'])

  const history = useHistory()

  const { login, logged, changedLocalStorage, setChangedLocalStorage } = useAuth()

  const { properties, setProperty, property, getStepId, steps, propertiesLoading } = useProperty()

  useEffect(() => {
    if (properties && steps && logged) {
      setIsLoggingIn(true)
      if (property) {
        if (properties.length > 1) history.push('/painel')
        else {
          const stepId = property.property_step_id
          stepId === getStepId('Completo') && history.push('/painel')
          stepId === getStepId('Cadastro de nome') && history.push('/propriedade/cadastro/')
          stepId === getStepId('Demarcação no mapa') && history.push('/propriedade/cadastro/talhao')
          stepId === getStepId('Cadastro de safras') && history.push('/propriedade/cadastro/safra')
          stepId === getStepId('Ativação armadilhas') &&
            history.push('/propriedade/cadastro/armadilha')
        }
      } else history.push('/propriedade/cadastro')
    }
  }, [history, logged, propertiesLoading, setProperty, getStepId, steps, property, properties])

  useEffect(() => {
    if (changedLocalStorage) {
      setLoading(false)
      setChangedLocalStorage(false)
    }
  }, [changedLocalStorage, setChangedLocalStorage])

  const onSubmit = async values => {
    try {
      setIsLoggingIn(true)
      const error = await login(values.email, values.password)
      !error &&
        toast.error('Seu e-mail ou senha estão incorretos.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
    } finally {
      setIsLoggingIn(false)
    }
  }

  return loading ? (
    <Column height='100vh' justifyContent='center' alignItems='center'>
      <Loader />
    </Column>
  ) : (
    <Column minHeight='100vh' backgroundColor='gray.n250' alignItems='center'>
      <Image backgroundImage={backgroundImage} width='100vw' height='33vh' />
      <Card
        width={[326, 418]}
        borderRadius={6}
        marginTop='-20vh'
        p={[32, 64]}
        alignItems='center'
        as='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Image src={logo} height={[92, 133]} width={[150, 216]} />

        <Column width='100%'>
          <Text variant='subtitles' color='gray.n900' fontWeight={600} mt={32} mb={32}>
            Login
          </Text>
          <Input
            name='email'
            label='E-mail'
            register={register}
            onChange={setValue}
            value={values.email}
            error={errors.email?.message}
          />
          <Input
            name='password'
            label='Senha'
            register={register}
            onChange={setValue}
            icon
            mt={32}
            value={values.password}
            error={errors.password?.message}
          />
        </Column>

        <Link variant='caption' marginTop={32} to='/esqueci-a-senha'>
          Esqueci minha senha
        </Link>
        {isLoggingIn ? (
          <Row
            width='100%'
            justifyContent='center'
            mt={32}
            border={`solid 1px ${theme.colors.gray.n500}`}
            borderRadius='4px'
          >
            <Loader />
          </Row>
        ) : (
          <Button text='Entrar' width='100%' mt={32} />
        )}
      </Card>
    </Column>
  )
}

export default Login
