import * as React from 'react'
import { PublishOutlined } from '@material-ui/icons'

function SvgPublish (props) {
  return (
    <PublishOutlined style={{ ...props }} />
  )
}

export default SvgPublish
