import * as React from 'react'
import { VisibilityOffOutlined } from '@material-ui/icons'

function SvgVisibilityOff (props) {
  return (
    <VisibilityOffOutlined style={{ ...props }} />
  )
}

export default SvgVisibilityOff
