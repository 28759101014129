import { fetchClient } from '../providers'

export const loginRequest = (email, password) =>
  fetchClient.post('/v1/users/login', { email, password })

export const getUserData = userId => fetchClient.get(`/v1/users/${userId}`)

export const updateCompanyName = (userId, name) =>
  fetchClient.put(`/v1/users/${userId}`, { company_name: name })

export const getUserTraps = userId => fetchClient.get(`/v1/users/${userId}/traps`)

export const forgotPasswordRequest = email => fetchClient.post('/v1/users/forget', { email })

export const resetPassword = data => fetchClient.put('/v1/users/reset', data)
