import * as React from 'react'
import { OpenWithOutlined } from '@material-ui/icons'

function SvgOpen (props) {
  return (
    <OpenWithOutlined style={{ ...props }} />
  )
}

export default SvgOpen
