import React, { Fragment } from 'react'
import styled from 'styled-components'

import { Row, Text, Column } from '../../components'
import { Check } from '../../icons'

import { theme } from '../../theme'

const Steps = ({ steps, currentStep, ...props }) => {
  return (
    <Row alignItems='center' width='100%' {...props}>
      <Column position='relative' alignItems='center'>
        <Circle currentStep index={-1}>
          {currentStep > 1 ? (
            <Check color='white' height={16} width={16} />
          ) : (
            <Text variant='caption' color='white'>
              1
            </Text>
          )}
        </Circle>
        <Text position='absolute' marginTop={28} color='secondary.five'>
          {steps[0]}
        </Text>
      </Column>

      {steps.map((_, index) => (
        <Fragment key={index}>
          {index < steps.length - 1 && (
            <Fragment key={index}>
              <Row
                width={`${100 / (steps.length - 1)}%`}
                height={1}
                backgroundColor={index < currentStep - 1 ? 'secondary.five' : 'gray.n400'}
              />
              <Column position='relative' alignItems='center'>
                <Circle index={index} currentStep={currentStep - 1}>
                  {currentStep - 1 > index + 1 ? (
                    <Check color='white' height={16} width={16} />
                  ) : (
                    <Text
                      variant='caption'
                      color={index <= currentStep - 2 ? 'white' : 'gray.n600'}
                    >
                      {index + 2}
                    </Text>
                  )}
                </Circle>
                <Text
                  position='absolute'
                  variant='body'
                  marginTop={28}
                  color={index <= currentStep - 2 ? 'secondary.five' : 'gray.n600'}
                >
                  {steps[index + 1]}
                </Text>
              </Column>
            </Fragment>
          )}
        </Fragment>
      ))}
    </Row>
  )
}

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 28px;
  width: 28px;
  border-radius: 14px;
  background-color: ${({ index, currentStep }) =>
    index < currentStep ? theme.colors.secondary.five : theme.colors.gray.n400};
`

export default Steps
