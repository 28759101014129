import React from 'react'

const SVGArrowBack2 = ({ width = 22, height = 22, color = '#616161' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21.6666 9.66668H5.43992L12.8933 2.21334L10.9999 0.333344L0.333252 11L10.9999 21.6667L12.8799 19.7867L5.43992 12.3333H21.6666V9.66668Z'
      fill={color}
    />
  </svg>
)

export default SVGArrowBack2
