import * as React from 'react'
import { ReplyOutlined } from '@material-ui/icons'

function SvgReply (props) {
  return (
    <ReplyOutlined style={{ ...props }} />
  )
}

export default SvgReply
