import React from 'react'
import styled from 'styled-components'

import { Text, Row, Button, Column, Loader } from '../../components'

import { Check } from '../../icons'

import { theme } from '../../theme'

const ModalComponent = ({
  isOpen,
  title,
  text,
  icon,
  buttons,
  bigger,
  loading,
  handleClose,
  ...props
}) => {
  return (
    <>
      {isOpen && (
        <Overlay onClick={handleClose}>
          <Modal bigger={bigger} {...props} borderRadius={6}>
            {loading ? (
              <Row alignItems='center'>
                <Loader />
                <Text variant='subtitles' color='gray.n900' fontWeight={600} marginLeft={16}>
                  Carregando...
                </Text>
              </Row>
            ) : (
              <>
                <Row alignItems='center'>
                  {icon && <Check width={56} height={50} />}
                  <Text
                    variant='subtitles'
                    fontWeight={600}
                    color='gray.n900'
                    width={!bigger ? 195 : '100%'}
                    marginLeft={icon ? 24 : 0}
                  >
                    {title}
                  </Text>
                </Row>

                <Text variant='paragraph' color='gray.n600' marginTop={16}>
                  {text}
                </Text>

                <Row marginTop={16}>
                  <Button
                    onClick={buttons.firstButton.action}
                    text={buttons.firstButton.text}
                    width='100%'
                  />
                  {buttons.secondButton && (
                    <Button
                      onClick={buttons.secondButton.action}
                      text={buttons.secondButton.text}
                      width='100%'
                      outlined
                      marginLeft={16}
                    />
                  )}
                </Row>
              </>
            )}
          </Modal>
        </Overlay>
      )}
    </>
  )
}

const Modal = styled(Column)`
  background-color: ${theme.colors.gray.n50};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 50px;
  padding: 32px;
  width: ${({ bigger }) => (bigger ? '510px' : '354px')};
`

const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  z-index: 2;
`

export default ModalComponent
