import React from 'react'
import styled from 'styled-components'

import { Row } from '../../components'

import { theme } from '../../theme'

import { Search } from '../../icons'

const InputSearch = ({ ...props }) => {
  return (
    <Row
      {...props}
      width={300}
      height={40}
      backgroundColor='gray.n200'
      borderRadius={4}
      alignItems='center'
    >
      <Search width={22} height={22} color={theme.colors.gray.n600} marginLeft={19} />
      <StyledInput type='text' placeholder='O que você procura?' />
    </Row>
  )
}

const StyledInput = styled.input`
  flex: 1;
  color: #757575;
  padding: 0 12px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  border-color: #eeeeee;
  background-color: #eeeeee;
  border-style: solid;
`

export default InputSearch
