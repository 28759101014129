import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'

import { Column, Row, Text } from '../../components'
import { Error } from '../../icons'

import { theme } from '../../theme'

const StyledSelect = styled(Select)`
  background-color: ${({ value }) => (value ? theme.colors.gray.n50 : theme.colors.gray.n200)};
  border-radius: 4px;
  height: 40px;

  & .Mui-focused {
    color: ${theme.colors.feedback.confirmation};
  }
  & .MuiInputBase-input {
    color: ${theme.colors.gray.n600};

    &:focus {
      background-color: ${theme.colors.gray.n50};
    }
  }
  & .MuiOutlinedInput-root {
    color: ${theme.colors.feedback.confirmation};
    & fieldset {
      border-color: transparent;
    }
    &:hover fieldset {
      border-color: ${theme.colors.feedback.confirmation};
    }
  }
`

const StyledForm = styled(FormControl)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${({ value }) => (value ? theme.colors.gray.n600 : theme.colors.gray.n200)};
    }
    &:hover fieldset {
      border-color: ${theme.colors.feedback.confirmation};
    }
  }

  & .MuiFormLabel-root.Mui-focused {
    color: ${theme.colors.feedback.confirmation};
  }

  & .MuiOutlinedInput-root.Mui-focused {
    & fieldset {
      border-color: ${theme.colors.feedback.confirmation};
      border-width: 1px;
    }
  }
`

const useStyles = makeStyles({
  root: {
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.colors.feedback.confirmation
    }
  }
})

const InputComponent = ({
  name,
  label,
  disabled = false,
  register,
  onChange,
  error,
  description,
  options,
  getValues,
  value,
  harvest = false,
  ...props
}) => {
  const classes = useStyles()
  const [selectValue, setSelectValue] = useState('')
  useEffect(() => {
    onChange(name, value)
    setSelectValue(
      harvest && value ? options.find(element => element.value === value.name)?.id : value
    )
  }, [name, onChange, value, options, harvest])

  return (
    <Column {...props}>
      <StyledForm variant='outlined' margin='dense' value={selectValue}>
        <InputLabel
          id='demo-simple-select-outlined-label'
          value={selectValue}
          shrink={selectValue !== ''}
        >
          {label}
        </InputLabel>
        <StyledSelect
          disabled={disabled}
          filled={selectValue !== ''}
          className={classes.root}
          name={name}
          labelId='demo-simple-select-outlined-label'
          id='demo-simple-select-outlined'
          value={selectValue}
          onChange={text => {
            onChange(name, text.target.value)
            setSelectValue(text.target.value)
          }}
          label={label}
          inputProps={{
            inputRef: ref => {
              if (!ref) return
              register({
                name: name,
                value: ref.value
              })
            }
          }}
        >
          {options.map((current, index) => (
            <MenuItem key={index} value={current.id}>
              {current.value}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledForm>

      {error && (
        <Row
          backgroundColor='rgba(198, 40, 40, 0.2)'
          borderRadius={3}
          height={27}
          alignItems='center'
        >
          <Row marginLeft={18} alignItems='center'>
            <Error color={theme.colors.feedback.error} width={15} />
            <Text variant='caption' color='feedback.error' marginLeft='5px'>
              {error}
            </Text>
          </Row>
        </Row>
      )}
      {description && (
        <Text variant='caption' marginTop='4px' marginLeft={2}>
          {description}
        </Text>
      )}
    </Column>
  )
}

export default InputComponent
