import React, { useEffect, useState } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/pt-br'

import { theme } from '../../theme'

moment.locale('pt-br')

const StyledDatePicker = styled(KeyboardDatePicker)`
  background-color: ${({ value }) => (value ? theme.colors.gray.n50 : theme.colors.gray.n200)};
  max-height: 40px;
  border-radius: 4px;
  
  & .Mui-focused {
    color: ${theme.colors.feedback.informative};
    background-color: ${theme.colors.gray.n50};
    border-color: ${theme.colors.gray.n200};
  }

  & .MuiInputLabel-outlined {
    transform: translate(14px, 13px) scale(1);
  }
  
  & .MuiInputBase-input {
    color: ${theme.colors.gray.n600};
    
    &:focus {
      background-color: ${theme.colors.gray.n50};
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ value }) => (value ? theme.colors.gray.n600 : theme.colors.gray.n200)};
  }
`

const useStyles = makeStyles({
  root: {
    width: '100%',
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.colors.feedback.confirmation
      }
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.colors.feedback.confirmation,
        borderWidth: '1px'
      }
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd': {
      background: theme.colors.gray.n60,
      '& .MuiInputBase-input': {
        color: theme.colors.gray.n800,
        opacity: 0.8,
        fontFamily: 'IBM Plex Sans, sans-serif',
        fontSize: '16px',
        fontWeight: 'normal'
      },
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: '0px'
      }
    },
    '& .MuiFormLabel-root': {
      color: theme.colors.gray.n600,
      fontFamily: 'IBM Plex Sans, sans-serif',
      fontWeight: 400
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.colors.feedback.confirmation
    }
  }
})

const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.colors.secondary.three
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: theme.colors.secondary.three
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: theme.colors.secondary.three
      },
      dayDisabled: {
        color: theme.colors.secondary.three
      },
      current: {
        color: theme.colors.secondary.three
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: theme.colors.secondary.three
      }
    }
  }
})

const DatePickerComponente = ({
  control,
  name,
  changeValue,
  register,
  invalidDateMessage,
  maxDate,
  minDate,
  maxDateMessage,
  minDateMessage,
  label,
  value
}) => {
  const [selectedDate, handleDateChange] = useState(value)
  const classes = useStyles()

  useEffect(() => {
    register(name)
    changeValue(name, value)
  }, [register, name, changeValue, value])

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Controller
          name={name}
          control={control}
          render={props => (
            <StyledDatePicker
              className={classes.root}
              id='data-picker-dialog'
              inputVariant='outlined'
              variant='inline'
              label={label}
              format='DD/MM/yyyy'
              defaultValue={null}
              value={selectedDate}
              onChange={value => {
                handleDateChange(value)
                changeValue(name, moment(value).format('YYYY-MM-DD'))
              }}
              invalidDateMessage={invalidDateMessage || 'Data inválida'}
              maxDate={maxDate}
              minDate={minDate}
              maxDateMessage={maxDateMessage || 'A data inicial não deve ultrapassar a final'}
              minDateMessage={minDateMessage || 'A data final não deve preceder a data inicial'}
              autoOk
              autoComplete='off'
              margin='dense'
              {...props}
            />
          )}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default DatePickerComponente
