import React, { useMemo } from 'react'

import { Column } from '../../components'

import {
  Cancel,
  CheckCircle,
  Error,
  Done,
  DisabledPin,
  AlertPin,
  NormalPin,
  CriticPin,
  More,
  ArrowBack,
  ArrowRight,
  Filter,
  Edit,
  ArrowBack2,
  Settings,
  Help,
  Info,
  Logout,
  LogoutAlt,
  Account,
  Search,
  CheckCircleOutline,
  Apple,
  Avocado,
  Citrus,
  Coffee,
  Cotton,
  Grape,
  Mango,
  Olive,
  Papaya,
  Peach,
  Pear,
  Plum,
  Rice,
  Soy,
  Strawberry,
  SugarCane,
  Tabacco,
  Tomato,
  Wheat,
  HarvestDefault,
  ArrowDown
} from '../../icons'

const ARROWDOOWN = 'arrowDown'
const CANCEL = 'cancel'
const CHECKCIRCLE = 'checkCircle'
const ERROR = 'error'
const DONE = 'done'
const DISABLEDPIN = 'disabledPin'
const CRITICPIN = 'criticPin'
const ALERTPIN = 'alertPin'
const NORMALPIN = 'normalPin'
const MORE = 'more'
const ARROWBACK = 'arrowBack'
const ARROWRIGHT = 'arrowRight'
const FILTER = 'filter'
const EDIT = 'edit'
const ARROWBACK2 = 'arrowBack2'
const SETTINGS = 'settings'
const HELP = 'help'
const INFO = 'info'
const LOGOUT = 'logout'
const LOGOUTALT = 'logoutAlt'
const ACCOUNT = 'account'
const SEARCH = 'search'
const CHECKCIRCLEOUTLINE = 'checkCircleOutline'
const ABACATEIRO = 'Abacateiro'
const AMEIXEIRA = 'Ameixeira'
const ALGODOEIRO = 'Algodoeiro'
const ARROZ = 'Arroz'
const CAFEEIRO = 'Cafeeiro'
const CANADEACUCAR = 'Cana-de-açúcar'
const CITRUS = 'Citros'
const MACIEIRA = 'Macieira'
const MAMOEIRO = 'Mamoeiro'
const MANGUEIRA = 'Mangueira'
const MORANGUEIRO = 'Morangueiro'
const OLIVEIRA = 'Oliveira'
const PEREIRA = 'Pereira'
const PESSEGUEIRO = 'Pessegueiro'
const SOJA = 'Soja'
const TABACO = 'Tabaco'
const TOMATEIRO = 'Tomateiro'
const TRIGO = 'Trigo'
const VIDEIRA = 'Videira'

const IconComponent = ({ icon, width, height, color, ...props }) => {
  const Icon = useMemo(() => {
    switch (icon) {
      case ARROWDOOWN:
        return ArrowDown
      case CANCEL:
        return Cancel
      case CHECKCIRCLE:
        return CheckCircle
      case ERROR:
        return Error
      case DONE:
        return Done
      case DISABLEDPIN:
        return DisabledPin
      case CRITICPIN:
        return CriticPin
      case ALERTPIN:
        return AlertPin
      case NORMALPIN:
        return NormalPin
      case MORE:
        return More
      case ARROWBACK:
        return ArrowBack
      case ARROWRIGHT:
        return ArrowRight
      case FILTER:
        return Filter
      case EDIT:
        return Edit
      case ARROWBACK2:
        return ArrowBack2
      case SETTINGS:
        return Settings
      case HELP:
        return Help
      case INFO:
        return Info
      case LOGOUT:
        return Logout
      case LOGOUTALT:
        return LogoutAlt
      case ACCOUNT:
        return Account
      case SEARCH:
        return Search
      case CHECKCIRCLEOUTLINE:
        return CheckCircleOutline
      case ABACATEIRO:
        return Avocado
      case AMEIXEIRA:
        return Plum
      case ALGODOEIRO:
        return Cotton
      case ARROZ:
        return Rice
      case CAFEEIRO:
        return Coffee
      case CANADEACUCAR:
        return SugarCane
      case CITRUS:
        return Citrus
      case MACIEIRA:
        return Apple
      case MAMOEIRO:
        return Papaya
      case MANGUEIRA:
        return Mango
      case MORANGUEIRO:
        return Strawberry
      case OLIVEIRA:
        return Olive
      case PEREIRA:
        return Pear
      case PESSEGUEIRO:
        return Peach
      case SOJA:
        return Soy
      case TABACO:
        return Tabacco
      case TOMATEIRO:
        return Tomato
      case TRIGO:
        return Wheat
      case VIDEIRA:
        return Grape
      default:
        return HarvestDefault
    }
  }, [icon])

  return (
    <Column {...props}>
      <Icon width={width} height={height} color={color} />
    </Column>
  )
}

export default IconComponent
