import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Text, Row } from '../../components'

import { Attachment } from '../../icons'

import { theme } from '../../theme'

const getProps = ({ to, textVariant, ...props }) => {
  if (to) {
    return {
      to,
      as: Link,
      ...props
    }
  }

  return {
    as: 'a',
    ...props
  }
}

const LinkComponent = ({ icon, ...props }) => (
  <Row>
    {icon && <Attachment marginRight={5} color={theme.colors.feedback.informative} />}
    <LinkText {...getProps({ ...props })} color={theme.colors.feedback.informative} />
  </Row>
)

const LinkText = styled(Text)`
  text-decoration: ${({ textDecoration }) => textDecoration};
  cursor: pointer;
`

LinkComponent.defaultProps = {
  textDecoration: 'underline'
}

export default LinkComponent
