import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { AuthContext } from './context/AuthContext'

const PrivateRoute = ({ path, ...props }) => {
  const { logged } = useContext(AuthContext)
  if (!logged) {
    return <Redirect exact to='/login' />
  }

  return <Route path={path} {...props} />
}

export default PrivateRoute
