import * as React from 'react'
import { DoneOutlined } from '@material-ui/icons'

function SvgDone (props) {
  return (
    <DoneOutlined style={{ ...props }} />
  )
}

export default SvgDone
