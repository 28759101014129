import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { theme } from '../../theme'

import { Column, Button, Text, Input, Card, Image, Icon, Row, Loader } from '../../components'

import backgroundImage from '../../assets/images/bg.png'
import logo from '../../assets/images/logo.png'

import { forgotPasswordSchema } from '../../utils'
import { forgotPasswordRequest } from '../../services'

import { toast } from 'react-toastify'

const ForgotPassword = () => {
  const { register, setValue, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(forgotPasswordSchema)
  })

  const values = watch(['email'])

  const history = useHistory()

  const [loading, setLoading] = useState(localStorage.getItem('id'))
  const [wasSent, setWasSent] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { logged, changedLocalStorage } = useAuth()

  useEffect(() => {
    if (logged) history.push('/')
  }, [history, logged])

  useEffect(() => {
    setLoading(localStorage.getItem('id'))
  }, [changedLocalStorage])

  const handleClick = () => {
    history.push('/')
  }

  const onSubmit = async values => {
    try {
      setIsSubmitting(true)
      const { data } = await forgotPasswordRequest(values.email)
      setWasSent(data.message)
    } catch (error) {
      error.response.status === 404 &&
        toast.error('E-mail não encontrado.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
    } finally {
      setIsSubmitting(false)
    }
  }

  return loading ? (
    <Column height='100vh' justifyContent='center' alignItems='center'>
      <Loader />
    </Column>
  ) : (
    <Column minHeight='100vh' backgroundColor='gray.n250' alignItems='center'>
      <Image backgroundImage={backgroundImage} width='100vw' height='33vh' />
      <Card
        width={[326, 418]}
        borderRadius={6}
        marginTop='-20vh'
        p={[32, 64]}
        alignItems='center'
        as='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Image src={logo} height={[92, 133]} width={[150, 216]} />

        {!wasSent ? (
          <Column width='100%'>
            <Text variant='subtitles' color='gray.n900' fontWeight={600} mt={32}>
              Redefina sua senha
            </Text>

            <Input
              name='email'
              label='E-mail'
              register={register}
              onChange={setValue}
              mt={32}
              value={values.email}
              error={errors.email?.message}
            />
            {isSubmitting ? (
              <Row
                width='100%'
                justifyContent='center'
                mt={32}
                border={`solid 1px ${theme.colors.gray.n500}`}
                borderRadius='4px'
              >
                <Loader />
              </Row>
            ) : (
              <Column>
                <Button text={'Redefinir'} width='100%' mt={32} />
                <Button outlined text={'Voltar'} width={'100%'} mt={'8px'} onClick={handleClick} />
              </Column>
            )}
          </Column>
        ) : (
          <Column width='100%'>
            <Row alignItems='center' justifyContent='center'>
              <Icon
                icon='checkCircleOutline'
                color={theme.colors.secondary.five}
                width={33}
                height={33}
                mt={32}
                mr={20}
              />
              <Text variant='paragraph' color='gray.n700' fontWeight={400} mt={32}>
                Foi enviado em seu e-mail um link para a redefinição da senha.
              </Text>
            </Row>
            <Button text={'Voltar'} width='100%' mt={32} onClick={handleClick} />
          </Column>
        )}
      </Card>
    </Column>
  )
}

export default ForgotPassword
