import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

import { theme } from '../../theme'

const StyledLoader = styled(CircularProgress)`
  .MuiCircularProgress-circleIndeterminate {
    color: ${theme.colors.secondary.five};
  }
`

const Loader = () => <StyledLoader />

export default Loader
