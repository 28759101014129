import React, { useState, useEffect, useCallback } from 'react'

import { useForm } from 'react-hook-form'
import { Polygon, Marker } from '@react-google-maps/api'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import {
  Row,
  Column,
  MapCore,
  Text,
  Select,
  BottomNavigator,
  Loader,
  IconButton,
  Modal,
  ActionButton,
  Overlay,
  CultivationMarker
} from '../../components'
import { theme } from '../../theme'

import { ReactComponent as Logo } from '../../assets/images/logoHorizontal.svg'
import active from '../../assets/images/icons/active.svg'
import alert from '../../assets/images/icons/Alerta.svg'
import critical from '../../assets/images/icons/Critico.svg'

import FieldsList from './FieldsList'
import HarvestsList from './HarvestsList'
import Summary from './Summary'

import { useProperty } from '../../context/PropertiesContext'
import { useAuth } from '../../context/AuthContext'
import { getDashboardData } from '../../services'
import { correctLatLng } from '../../utils'

const Dashboard = () => {
  const { user, logout } = useAuth()
  const {
    harvestsData,
    property,
    shouldUpdateHarvests,
    setShouldUpdateHarvests,
    getStepId,
    getDashboardHarvests
  } = useProperty()

  const history = useHistory()

  property.property_step_id < getStepId('Completo') && history.push('/')

  const { setValue, getValues, register } = useForm()

  const propertiesList = [
    {
      id: property.id,
      value: property.property_name
    }
  ]
  const [pageNavigation, setPageNavigation] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [logoutModal, setLogoutModal] = useState(false)
  const [showButtons, setShowButtons] = useState(false)
  const [updateData, setUpdateData] = useState(false)
  const [dashboardData, setDashboardData] = useState()
  const [mapLocation, setMapLocation] = useState()
  const [trapIconData, setTrapIconData] = useState()

  const defaultOptions = {
    fillColor: '#2E7D32',
    fillOpacity: 0.3,
    strokeColor: '#2E7D32',
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }
  const CautionOptions = {
    fillColor: theme.colors.feedback.alert,
    fillOpacity: 0.3,
    strokeColor: theme.colors.feedback.alert,
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }
  const CriticalOptions = {
    fillColor: theme.colors.feedback.error,
    fillOpacity: 0.3,
    strokeColor: theme.colors.feedback.error,
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }
  const EmptyOptions = {
    fillColor: theme.colors.gray.n300,
    fillOpacity: 0.3,
    strokeColor: theme.colors.gray.n500,
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 1
  }

  const filterHarvest = field => {
    const auxHarvest = harvestsData.filter(harvest =>
      harvest.fields.some(current => current.id === field.id)
    )
    const harvest = auxHarvest.filter(current =>
      moment().isBetween(moment(current.startDate).subtract(1, 'days'), current.endDate)
    )
    if (harvest.length > 0) {
      return harvest[0]
    }
    return auxHarvest.find(current => moment().isBefore(moment(current.startDate).add(1, 'days')))
  }

  const getDashboardMainData = useCallback(async () => {
    try {
      const { data } = await getDashboardData(property.id)
      setDashboardData({
        ...data,
        property: {
          ...data.property[0],
          fields: correctLatLng(
            data.property[0].fields.map(field => ({
              ...field,
              cultivation: [field.plantings_name],
              ...(field.traps?.length > 0
                ? data.lastReads?.find(reading => reading.fields_id === field.id)
                  ? data.lastReads
                      .filter(reading => reading.fields_id === field.id)
                      .some(reading => reading.percentage >= 1)
                    ? { status: 'criticPin', fieldColor: CriticalOptions, fieldIcon: critical }
                    : data.lastReads
                        .filter(reading => reading.fields_id === field.id)
                        .some(reading => reading.percentage >= 0.8)
                    ? { status: 'alertPin', fieldColor: CautionOptions, fieldIcon: alert }
                    : { status: 'active', fieldColor: defaultOptions, fieldIcon: active }
                  : field.traps.every(trap => trap.is_activated)
                  ? { status: 'active', fieldColor: defaultOptions, fieldIcon: active }
                  : { status: 'disabled', fieldColor: EmptyOptions, fieldIcon: false }
                : { status: 'empty', fieldColor: EmptyOptions, fieldIcon: false })
            }))
          )
        }
      })
    } catch (error) {
      console.log(error)
    }
  }, [CautionOptions, CriticalOptions, defaultOptions, EmptyOptions, property.id])

  const fetchInitialData = useCallback(async () => {
    try {
      await getDashboardMainData()
      if (harvestsData.length === 0 || shouldUpdateHarvests) await getDashboardHarvests()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      setShouldUpdateHarvests(false)
    }
  }, [
    harvestsData.length,
    setShouldUpdateHarvests,
    shouldUpdateHarvests,
    getDashboardHarvests,
    getDashboardMainData
  ])

  const handleTrapIcons = () => {
    setTrapIconData(
      dashboardData.property.fields.map(field => ({
        center: field.center,
        fieldIcon: field.fieldIcon
      }))
    )
  }

  useEffect(() => {
    setIsLoading(true)
    fetchInitialData()
    // eslint-disable-next-line
  }, [updateData])

  return isLoading ? (
    <Row height='100vh' width='100vw' justifyContent='center' alignItems='center'>
      <Loader />
    </Row>
  ) : (
    <Row width='100%' height='100vh'>
      <Column width='32vw' alignItems='center' marginTop='24px'>
        <Logo width='150px' />
        <Text variant='paragraph' marginTop='5px' marginBottom='16px'>
          {`Seja bem-vindo, ${user.name}!`}
        </Text>
        <Select
          name='property'
          label='Propriedade'
          getValues={getValues}
          register={register}
          onChange={setValue}
          value={propertiesList[0].id}
          options={propertiesList}
          width='90%'
          disabled
        />
        <Column flex={1} width='100%' marginTop='16px'>
          {pageNavigation === 0 && (
            <Summary dashboardData={dashboardData} mapControl={setMapLocation} />
          )}
          {pageNavigation === 1 && dashboardData && (
            <FieldsList fields={dashboardData.property.fields} loading={isLoading} />
          )}
          {pageNavigation === 2 && (
            <HarvestsList
              fields={harvestsData}
              loading={isLoading}
              update={updateData}
              setUpdate={setUpdateData}
            />
          )}

          <Row width='32vw' position='fixed' bottom='0px' left='0px'>
            <BottomNavigator
              selected={pageNavigation}
              firstButtom={() => setPageNavigation(0)}
              secondButtom={() => setPageNavigation(1)}
              thirdButtom={() => setPageNavigation(2)}
              hideFourthButtom
            />
          </Row>
        </Column>
      </Column>

      <MapCore
        center={mapLocation}
        fields={dashboardData.property.fields}
        zoom={5}
        flex={1}
        extraOnLoadFunction={handleTrapIcons}
      >
        {showButtons && (
          <Row position='absolute' bottom={112} left={32} alignItems='center'>
            <Overlay onClick={() => setShowButtons(false)} />
            <ActionButton
              child
              onClick={() => {
                history.push(`/painel/${property.id}/safra`)
              }}
            />
            <Text
              marginLeft={10}
              padding={1}
              color={'gray.n700'}
              zIndex={2}
              backgroundColor='white'
              onClick={() => {
                history.push(`/painel/${property.id}/safra`)
              }}
            >
              Adicionar nova safra
            </Text>
          </Row>
        )}
        <ActionButton
          big
          position='absolute'
          bottom={32}
          left={24}
          onClick={() => setShowButtons(!showButtons)}
        />
        {dashboardData?.property.fields.map((field, index) => (
          <Polygon
            key={index}
            paths={field.markers}
            options={field.fieldColor}
            onClick={() => history.push(`/painel/talhao/${field.id}`)}
          />
        ))}

        {window.google &&
          (pageNavigation === 2
            ? dashboardData?.property.fields?.map(
                (field, index) =>
                  filterHarvest(field) && (
                    <CultivationMarker
                      cultivation={filterHarvest(field).cultivation}
                      key={index}
                      position={{
                        lng: Number(field.center.lng),
                        lat: Number(field.center.lat)
                      }}
                      icon={{
                        scaledSize: new window.google.maps.Size(50, 50),
                        anchor: new window.google.maps.Point(25, 25)
                      }}
                    />
                  )
              )
            : trapIconData?.map(
                (field, index) =>
                  field.fieldIcon && (
                    <Marker
                      key={index}
                      position={{
                        lng: Number(field.center.lng),
                        lat: Number(field.center.lat)
                      }}
                      icon={{
                        url: field.fieldIcon,
                        scaledSize: new window.google.maps.Size(50, 50),
                        anchor: new window.google.maps.Point(25, 25)
                      }}
                    />
                  )
              ))}
      </MapCore>

      <Row position='absolute' right={24} top={24}>
        <IconButton
          icon='logout'
          color='white'
          resetCss
          onClick={() => {
            setLogoutModal(true)
          }}
        />
      </Row>

      <Modal
        isOpen={logoutModal}
        title='Você deseja mesmo sair?'
        bigger
        buttons={{
          firstButton: {
            action: () => {
              logout()
              history.push('/')
            },
            text: 'Sim'
          },
          secondButton: {
            action: () => setLogoutModal(false),
            text: 'Não'
          }
        }}
      />
    </Row>
  )
}

export default Dashboard
