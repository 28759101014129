import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'

import { Column } from '../../components'

import { theme } from '../../theme'

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.secondary.five,
    color: theme.colors.white,
    borderRadius: '50%',
    width: '64px',
    height: '64px',
    zIndex: 2,
    '&:hover': {
      backgroundColor: theme.colors.secondary.three
    },
    '&:disabled': {
      backgroundColor: theme.colors.gray.n500,
      color: theme.colors.white
    }
  },
  small: {
    backgroundColor: theme.colors.secondary.five,
    color: theme.colors.white,
    borderRadius: '50%',
    minWidth: '48px',
    width: '48px',
    height: '48px',
    '&:hover': {
      backgroundColor: theme.colors.secondary.three
    },
    '&:disabled': {
      backgroundColor: theme.colors.gray.n500,
      color: theme.colors.white
    }
  },
  child: {
    backgroundColor: theme.colors.gray.n100,
    color: theme.colors.gray.n700,
    borderRadius: '50%',
    minWidth: '48px',
    width: '48px',
    height: '48px',
    zIndex: 2,
    '&:hover': {
      backgroundColor: theme.colors.gray.n400
    },
    '&:disabled': {
      backgroundColor: theme.colors.gray.n500,
      color: theme.colors.white
    }
  }
})

const ActionButonComponent = ({
  big = false,
  disabled = false,
  onClick,
  child = false,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Column {...props}>
      <Button
        disabled={disabled}
        className={child ? classes.child : big ? classes.root : classes.small}
        TouchRippleProps={{ classes: { child: classes.child } }}
        onClick={onClick}
      >
        <Add />
      </Button>
    </Column>
  )
}

export default ActionButonComponent
