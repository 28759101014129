import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import { Column } from '../../components'

import { theme } from '../../theme'

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.secondary.five,
    color: theme.colors.white,
    borderRadius: '4px',
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.colors.secondary.three
    },
    '&:disabled': {
      backgroundColor: theme.colors.gray.n500,
      color: theme.colors.white
    },
    '&.span': {
      marginLeft: '11px'
    }
  },
  outlined: {
    backgroundColor: theme.colors.white,
    color: theme.colors.secondary.five,
    borderRadius: '4px',
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: '16px',
    textTransform: 'none',
    height: '40px',
    fontWeight: '400',
    border: `1px solid ${theme.colors.secondary.five}`,
    '&:hover': {
      color: theme.colors.secondary.three,
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.secondary.three}`
    },
    '&:disabled': {
      color: theme.colors.gray.n500,
      border: `1px solid ${theme.colors.gray.n500}`
    },
    '&:active': {
      border: `1px solid ${theme.colors.secondary.one}`,
      color: theme.colors.secondary.one
    }
  },
  remove: {
    backgroundColor: theme.colors.white,
    color: theme.colors.feedback.error,
    borderRadius: '4px',
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: '16px',
    textTransform: 'none',
    height: '40px',
    fontWeight: '400',
    border: `1px solid ${theme.colors.feedback.error}`,
    '&:hover': {
      color: theme.colors.feedback.error,
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.feedback.error}`
    },
    '&:disabled': {
      color: theme.colors.gray.n500,
      border: `1px solid ${theme.colors.gray.n500}`
    },
    '&:active': {
      border: `1px solid ${theme.colors.feedback.error}`,
      color: theme.colors.feedback.error
    }
  },
  inverted: {
    backgroundColor: theme.colors.white,
    color: theme.colors.secondary.five,
    borderRadius: '4px',
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: '16px',
    textTransform: 'none',
    height: '40px',
    fontWeight: '400',
    '&:hover': {
      color: theme.colors.secondary.three,
      backgroundColor: theme.colors.white
    },
    '&:disabled': {
      color: theme.colors.gray.n500
    },
    '&:active': {
      color: theme.colors.secondary.one
    }
  },
  childRoot: {
    backgroundColor: theme.colors.secondary.one
  },
  childOutlined: {
    backgroundColor: theme.colors.gray.n300
  }
})

const ButtonComponent = ({
  disabled = false,
  outlined = false,
  inverted = false,
  icon,
  text,
  onClick,
  remove = false,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Column {...props}>
      <Button
        disabled={disabled}
        className={
          outlined
            ? classes.outlined
            : remove
            ? classes.remove
            : inverted
            ? classes.inverted
            : classes.root
        }
        TouchRippleProps={{ classes: { child: outlined ? classes.outlined : classes.root } }}
        startIcon={icon}
        onClick={onClick}
        type='submit'
      >
        {text}
      </Button>
    </Column>
  )
}

export default ButtonComponent
