import * as React from 'react'
import { TimelineOutlined } from '@material-ui/icons'

function SvgTimeline (props) {
  return (
    <TimelineOutlined style={{ ...props }} />
  )
}

export default SvgTimeline
