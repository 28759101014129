import React from 'react'
import { useHistory } from 'react-router-dom'

import { Column, Button, Text, Image } from '../../components'

import logo from '../../assets/images/logo-wide.svg'
import error from '../../assets/images/error-404.svg'

const NotFound = () => {
  const history = useHistory()

  const handleClick = () => {
    history.push('/')
  }
  return (
    <Column minHeight='100vh' backgroundColor='white' alignItems='center'>
      <Image src={logo} height={[65, 81]} width={[200, 250]} mt={[16, 24]} mb={90} />
      <Image src={error} height={[161, 215]} width={[216, 290]} />
      <Text variant='subtitles' color='gray.n900' fontWeight={600} mt={[16, 21]} fontSize={16}>
        Página não encontrada!
      </Text>
      <Text
        variant='paragraph'
        color=' gray.n700'
        fontWeight={400}
        mt={['8px', '16px']}
        width={[328, 290]}
        textAlign='center'
        fontSize={14}
        lineHeight={['18px', '21px']}
      >
        Nós não encontramos a página que você estava procurando. Tente ir por outro caminho!
      </Text>

      <Button
        outlined
        text={'Voltar'}
        width={[328, 290]}
        mt={64}
        onClick={handleClick}
        position={['absolute', 'static']}
        top='81vh'
      />
    </Column>
  )
}
export default NotFound
