import React from 'react'
import { IconButton } from '@material-ui/core'

import { Icon, Column } from '../../components'

const IconButtonComponent = ({
  icon,
  width,
  height,
  color,
  onClick,
  resetCss = false,
  disabled,
  ...props
}) => {
  return (
    <Column {...props} justifyContent='center' alignItems='center'>
      <IconButton
        onClick={onClick}
        style={resetCss ? { padding: 0, backgroundColor: 'transparent' } : {}}
        disabled={disabled}
      >
        <Icon icon={icon} width={width} height={height} color={color} />
      </IconButton>
    </Column>
  )
}

export default IconButtonComponent
