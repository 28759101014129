import * as React from 'react'
import { PowerSettingsNewOutlined } from '@material-ui/icons'

function SvgPowerSettings (props) {
  return (
    <PowerSettingsNewOutlined style={{ ...props }} />
  )
}

export default SvgPowerSettings
