import * as React from 'react'
import { FilterHdrOutlined } from '@material-ui/icons'

function SvgFilterHdr (props) {
  return (
    <FilterHdrOutlined style={{ ...props }} />
  )
}

export default SvgFilterHdr
