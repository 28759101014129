import { fetchClient } from '../providers'

export const getProperties = userId => fetchClient.get(`/v1/users/${userId}/properties`)

export const newProperty = (userId, propertyName, companyName) =>
  fetchClient.post('/v1/properties', {
    user_id: userId,
    property_name: propertyName,
    company_name: companyName
  })

export const updateProperty = (name, propertyId) =>
  fetchClient.put(`/v1/properties/${propertyId}`, { property_name: name })

export const updatePropertyStep = (propertyId, step) =>
  fetchClient.put(`/v1/properties/${propertyId}`, { property_step_id: step })

export const getSteps = () => fetchClient.get('/v1/steps/')

export const createPropertyWithDraw = propertyInfo =>
  fetchClient.post('v1/fields/markers', propertyInfo)

export const getDashboardData = (propertyId, start_date, end_date) =>
  fetchClient.get(
    `/v1/properties/${propertyId}/dashboard?${start_date ? `start_date=${start_date}` : ''}&${
      end_date ? `end_date=${end_date}` : ''
    }`
  )
