import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { FieldsContainer, Modal } from '../../components'
import { useProperty } from '../../context/PropertiesContext'
import { deleteHarvest } from '../../services'

const HarvestsList = ({ fields, loading, setUpdate, update }) => {
  const history = useHistory()
  const { property } = useProperty()
  const [modalRemove, setModalRemove] = useState(false)
  const [harvestIndex, setHarvestIndex] = useState(null)

  const [currentActivatingFieldIndex, setCurrentActivatingFieldIndex] = useState(null)

  const { setShouldUpdateHarvests } = useProperty()

  const handleHarvestEdit = fieldIndex => {
    history.push(`/painel/${property.id}/safra?harvestId=${fields[currentActivatingFieldIndex].id}`)
  }

  const handleHarvestDelete = async index => {
    try {
      await deleteHarvest(fields[harvestIndex].id)
    } catch (error) {
      console.log(error)
    } finally {
      setShouldUpdateHarvests(true)
      setUpdate(!update)
      setHarvestIndex('')
      setModalRemove(false)
    }
  }

  return (
    <>
      <FieldsContainer
        disableOnClick
        dashboard
        fields={fields}
        loading={loading}
        handleFieldEdit={handleHarvestEdit}
        handleFieldRemove={() => {
          setHarvestIndex(currentActivatingFieldIndex)
          setCurrentActivatingFieldIndex(null)
          setModalRemove(true)
        }}
        onClickNewCard={() => history.push(`/painel/${property.id}/safra`)}
        setCurrentEditingFieldIndex={setCurrentActivatingFieldIndex}
        currentEditingFieldIndex={currentActivatingFieldIndex}
      />
      <Modal
        isOpen={modalRemove}
        title='Deseja mesmo excluir essa safra?'
        text='Todas as informações cadastradas serão deletadas'
        bigger
        buttons={{
          firstButton: {
            action: handleHarvestDelete,
            text: 'Sim'
          },
          secondButton: {
            action: () => {
              setModalRemove(false)
              setCurrentActivatingFieldIndex(null)
            },
            text: 'Não'
          }
        }}
      />
    </>
  )
}

export default HarvestsList
