import React from 'react'

const SVGDisabledPin = ({ width = 70, height = 70 }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 70 70'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='32' cy='32' r='32' fill='#9E9E9E' />
    <path
      d='M41.4825 27.2594H38.1521C37.6188 26.335 36.884 25.5409 35.9951 24.9365L37.9269 23.0046L36.2558 21.3335L33.684 23.9053C33.1388 23.775 32.5818 23.7039 32.001 23.7039C31.4203 23.7039 30.8632 23.775 30.3299 23.9053L27.7462 21.3335L26.0751 23.0046L27.9951 24.9365C27.118 25.5409 26.3832 26.335 25.8499 27.2594H22.5195V29.6298H24.9966C24.9373 30.0209 24.8899 30.412 24.8899 30.815V32.0002H22.5195V34.3705H24.8899V35.5557C24.8899 35.9587 24.9373 36.3498 24.9966 36.7409H22.5195V39.1113H25.8499C27.0825 41.2328 29.3699 42.6668 32.001 42.6668C34.6321 42.6668 36.9195 41.2328 38.1521 39.1113H41.4825V36.7409H39.0055C39.0647 36.3498 39.1121 35.9587 39.1121 35.5557V34.3705H41.4825V32.0002H39.1121V30.815C39.1121 30.412 39.0647 30.0209 39.0055 29.6298H41.4825V27.2594ZM36.7418 32.0002V35.5557C36.7418 35.8165 36.7062 36.1128 36.6588 36.3853L36.5403 37.1557L36.1018 37.9261C35.2484 39.3957 33.684 40.2965 32.001 40.2965C30.318 40.2965 28.7536 39.3839 27.9003 37.9261L27.4618 37.1676L27.3432 36.3972C27.2958 36.1246 27.2603 35.8283 27.2603 35.5557V30.815C27.2603 30.5424 27.2958 30.2461 27.3432 29.9853L27.4618 29.215L27.9003 28.4446C28.2558 27.8283 28.7536 27.295 29.3343 26.892L30.0099 26.4298L30.8869 26.2165C31.2543 26.1216 31.6336 26.0742 32.001 26.0742C32.3803 26.0742 32.7477 26.1216 33.1269 26.2165L33.9329 26.4061L34.6558 26.9039C35.2484 27.3068 35.7343 27.8283 36.0899 28.4565L36.5403 29.2268L36.6588 29.9972C36.7062 30.2579 36.7418 30.5542 36.7418 30.815V32.0002ZM29.6306 34.3705H34.3714V36.7409H29.6306V34.3705ZM29.6306 29.6298H34.3714V32.0002H29.6306V29.6298Z'
      fill='#212121'
    />
    <circle cx='54' cy='54' r='16' fill='#9E9E9E' />
    <path
      d='M54.6667 48H53.3333V54.6667H54.6667V48ZM57.8867 49.4467L56.94 50.3933C57.9933 51.24 58.6667 52.54 58.6667 54C58.6667 56.58 56.58 58.6667 54 58.6667C51.42 58.6667 49.3333 56.58 49.3333 54C49.3333 52.54 50.0067 51.24 51.0533 50.3867L50.1133 49.4467C48.82 50.5467 48 52.1733 48 54C48 57.3133 50.6867 60 54 60C57.3133 60 60 57.3133 60 54C60 52.1733 59.18 50.5467 57.8867 49.4467Z'
      fill='#FFFFFF'
    />
  </svg>
)

export default SVGDisabledPin
