import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import { hotjar } from './utils/hotjar'

hotjar.initialize(process.env.REACT_APP_HOTJAR_SITEID, 6)
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
