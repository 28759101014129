import { fetchClient } from '../providers'

export const getTraps = () => fetchClient.get('/v1/traps')

export const getFieldsWithTraps = propertyId =>
  fetchClient.get(`/v1/properties/${propertyId}/traps`)

export const updateTrapStatus = address =>
  fetchClient.put('/v1/traps/status', { mac_address: address, is_activated: true })

export const getTrapEvents = address => fetchClient.get(`/v1/traps/${address}`)

export const createThreshold = threshold => fetchClient.post('/v1/thresholds/', threshold)

export const updateThreshold = (thresholdId, thresholdValue) =>
  fetchClient.put(`/v1/thresholds/${thresholdId}`, { limiter: thresholdValue })

export const getInsects = () => fetchClient.get('/v1/insects')

export const createTrapFloor = (trapId, date) =>
  fetchClient.post('/v1/traps-floors', { traps_id: trapId, date_change: date })
