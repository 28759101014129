import { useAuth } from './context/AuthContext'

import provider from './providers/fetchClient'

import { toast } from 'react-toastify'

const WithAxios = ({ children }) => {
  const { logout, logged } = useAuth()
  const customId = 'custom-id-yes'

  provider.interceptors.response.use(
    response => response,
    error => {
      const status = error.response ? error.response.status : null
      if (status === 401 && logged) {
        logout()

        toast.error('Você foi desconectado. Por favor, realize o login novamente.', {
          toastId: customId,
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
      }
      return Promise.reject(error)
    }
  )

  return children
}

export default WithAxios
