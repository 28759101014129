import React from 'react'
import moment from 'moment'

import { Column, Row, Text, Link, Loader } from '../../components'
import { theme } from '../../theme'
import { SummaryCritical, SummaryAlert } from '../../icons'

const Summary = ({ dashboardData, mapControl }) => {
  const handleFieldCenter = fieldCenter => {
    mapControl(fieldCenter)
  }

  return (
    <Column alignItems='center' margin='10px 24px'>
      <Text variant='subtitles' fontWeight={600}>
        Resumo Geral
      </Text>
      <Column
        alignItems='center'
        width='calc(32vw - 24px)'
        position='fixed'
        left='24px'
        top='227px'
        height='calc(100vh - 285px)'
        overflow='auto'
        padding='0px 24px 60px 0px'
      >
        {!dashboardData ? (
          <Row width='100%' height='30vh' alignItems='center' justifyContent='center'>
            <Loader />
          </Row>
        ) : dashboardData.lastReads.some(reading => reading.percentage > 0.8) ? (
          dashboardData.property.fields.map(
            field =>
              dashboardData.lastReads.some(
                reading => reading.fields_id === field.id && reading.percentage > 0.8
              ) && (
                <Column
                  width='100%'
                  padding='24px 0px'
                  borderBottom={`1px solid ${theme.colors.gray.n300}`}
                >
                  <Row width='100%' alignItems='center'>
                    {dashboardData.lastReads.some(
                      reading => reading.fields_id === field.id && reading.percentage >= 1
                    ) ? (
                      <SummaryCritical />
                    ) : (
                      <SummaryAlert />
                    )}
                    <Text
                      variant='paragraph'
                      marginLeft='10px'
                      fontWeight={600}
                      onClick={() => handleFieldCenter(field.center)}
                      style={{ cursor: 'pointer' }}
                    >
                      {field.name}
                    </Text>
                  </Row>
                  <Column>
                    {dashboardData.lastReads
                      .filter(reading => reading.fields_id === field.id)
                      .map(reading => (
                        <Column marginTop='15px'>
                            <Row marginBottom='5px'>
                              <Text
                                variant='paragraph'
                                color={theme.colors.gray.n700}
                                marginRight='5px'
                              >
                                {reading.percentage >= 1
                                  ? 'Registro elevado de'
                                  : 'Registro moderado de'}
                              </Text>
                              <Link to={`/painel/talhao/${field.id}?insectId=${reading.insects_id}`}>
                                {reading.insects_name}
                              </Link>
                            </Row>
                            
                            <Text
                              variant='paragraph'
                              fontWeight='bold'
                              color={theme.colors.gray.n900}
                            >
                              {moment(reading.reading_date).format('DD/MM')}
                            </Text>
                        </Column>
                      ))}
                  </Column>
                </Column>
              )
          )
        ) : (
          <Text marginTop='24px'>Nenhuma leitura preocupante recentemente!</Text>
        )}
      </Column>
    </Column>
  )
}

export default Summary
