import React from 'react'
import styled from 'styled-components'

import { Row, Text } from '../../components'

import { theme } from '../../theme'

const InputCoordinate = ({
  value,
  coordinate,
  placeholder,
  suffix,
  name,
  setValue,
  register,
  range,
  ...props
}) => {
  return (
    <Row
      width={150}
      height={40}
      backgroundColor='gray.n200'
      borderRadius={4}
      alignItems='center'
      {...props}
    >
      <Text fontWeight={600} marginLeft={23} color='gray.n700'>
        {suffix}
      </Text>
      <StyledInput
        ref={register}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={e => {
          if (e.target.value !== '-' && isNaN(parseInt(e.target.value.replace(',', '.')))) {
            setValue(name, '')
          } else {
            setValue(name, e.target.value.replace(',', '.'))
            if (parseInt(e.target.value.replace(',', '.')) > range) {
              setValue(name, range)
            }
            if (parseInt(e.target.value.replace(',', '.')) < -range) {
              setValue(name, -range)
            }
          }
        }}
      />
    </Row>
  )
}

const StyledInput = styled.input`
  color: ${theme.colors.gray.n600};
  width: 100%;
  padding: 0 12px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  border-color: ${theme.colors.gray.n200};
  background-color: ${theme.colors.gray.n200};
  border-style: solid;
`

export default InputCoordinate
