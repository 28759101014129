import * as React from 'react'
import { DescriptionOutlined } from '@material-ui/icons'

function SvgDescription (props) {
  return (
    <DescriptionOutlined style={{ ...props }} />
  )
}

export default SvgDescription
