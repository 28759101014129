import styled from 'styled-components'
import { space, layout, typography, color, variant, position } from 'styled-system'

const H1 = 'h1'
const H2 = 'h2'
const H3 = 'h3'
const SUBTITLES = 'subtitles'
const PARAGRAPH = 'paragraph'
const CAPTION = 'caption'
const BODY = 'body'

const TextComponent = styled.p(
  {
    margin: '0px'
  },
  variant({
    variants: {
      [H1]: {
        fontSize: 72,
        lineHeight: '94px'
      },
      [H2]: {
        fontSize: 64,
        lineHeight: '83px'
      },
      [H3]: {
        fontSize: 48,
        lineHeight: '62px'
      },
      [SUBTITLES]: {
        fontSize: 24,
        lineHeight: '31px'
      },
      [PARAGRAPH]: {
        fontSize: 16,
        lineHeight: '21px'
      },
      [BODY]: {
        fontSize: 14,
        lineHeight: '18px'
      },
      [CAPTION]: {
        fontSize: 11,
        lineHeight: '14px'
      }
    }
  }),
  space,
  layout,
  typography,
  color,
  position
)

export default TextComponent
