import React from 'react'

const SVGEdit = ({ width = 25, height = 24, color = '#FFF' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.56 9.02L15.48 9.94L6.42 19H5.5V18.08L14.56 9.02ZM18.16 3C17.91 3 17.65 3.1 17.46 3.29L15.63 5.12L19.38 8.87L21.21 7.04C21.6 6.65 21.6 6.02 21.21 5.63L18.87 3.29C18.67 3.09 18.42 3 18.16 3ZM14.56 6.19L3.5 17.25V21H7.25L18.31 9.94L14.56 6.19Z'
      fill={color}
    />
  </svg>
)

export default SVGEdit
