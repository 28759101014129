import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'
import Login from './containers/Login'
import AddProperty from './containers/AddProperty'
import AddHarvest from './containers/AddHarvest'
import Demarcation from './containers/Demarcation'
import Dashboard from './containers/Dashboard'
import DashboardHarvest from './containers/DashboardHarvest'
import Micro from './containers/Micro'
import ActivateTraps from './containers/ActivateTraps'
import ForgotPassword from './containers/ForgotPassword'
import ResetPassword from './containers/ResetPassword'
import { AuthContext } from './context/AuthContext'
import NotFound from './containers/NotFound'
import { Loader, Row } from './components'
import { useProperty } from './context/PropertiesContext'

const Routes = () => {
  const { verifyLogin, authLoading } = useContext(AuthContext)
  const { propertiesLoading, property } = useProperty()

  useEffect(() => {
    verifyLogin()
  }, [verifyLogin])


  const Return = () => { 
    return(
      <Redirect to='/propriedade/cadastro'/>
      )
  }


  return (
    <BrowserRouter>
      {authLoading || propertiesLoading ? (
        <Row height='100vh' width='100vw' justifyContent='center' alignItems='center'>
          <Loader />
        </Row>
      ) : (
        <Switch>
          <Route exact path='/'>
            <Redirect to='/login' />
          </Route>
          <Route component={ForgotPassword} path='/esqueci-a-senha' exact />
          <Route component={ResetPassword} path='/redefinir-senha/:token' exact />
          <Route component={Login} path='/login' exact />
          <PrivateRoute component={AddProperty} path='/propriedade/cadastro' exact />
          <PrivateRoute component={property ? Demarcation : Return} path='/propriedade/cadastro/talhao' exact />
          <PrivateRoute component={property ? AddHarvest : Return} path='/propriedade/cadastro/safra' exact />
          <PrivateRoute component={property ? ActivateTraps : Return} path='/propriedade/cadastro/armadilha' exact />
          <PrivateRoute component={property ? Dashboard : Return} path='/painel' exact />
          <PrivateRoute component={property ? DashboardHarvest : Return} path='/painel/:propertyId/safra' exact />
          <PrivateRoute component={property ? Micro : Return} path='/painel/talhao/:id' exact />
          <Route component={NotFound} />
        </Switch>
      )}
    </BrowserRouter>
  )
}

export default Routes
