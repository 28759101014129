import * as Yup from 'yup'

Yup.setLocale({
  mixed: {
    required: 'Campo obrigatório'
  }
})

export const loginSchema = Yup.object().shape({
  email: Yup.string().email('Insira um e-mail válido').required(),
  password: Yup.string().required()
})

export const PropertyIdentifierSchema = Yup.object().shape({
  company_name: Yup.string(),
  property_name: Yup.string().required()
})

export const demarcationSchema = Yup.object().shape({
  field_name: Yup.string().required()
})

export const harvestSchema = Yup.object().shape({
  fields: Yup.array().min(1),
  planting: Yup.object().required(),
  plantingType: Yup.string().required(),
  startDate: Yup.date().required(),
  endDate: Yup.date().min(Yup.ref('startDate')).required()
})

export const insectControlSchema = Yup.object().shape({
  controlLevels: Yup.array()
    .of(
      Yup.object().shape({
        insectLevel: Yup.number().typeError('O valor deve ser um número').required()
      })
    )
    .required()
})

export const insectLimiterSchema = Yup.object().shape({
  limiter: Yup.number()
})

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Insira um e-mail válido').required()
})

export const resetPasswordSchema = Yup.object({
  password: Yup.string().required('Insira uma senha'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'As senhas devem ser iguais.'
  )
})
