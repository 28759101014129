import React from 'react'
import styled from 'styled-components'

const InputFile = ({ onChange, accept, fileRef }) => {
  return <StyledInput ref={fileRef} type='file' accept={accept} onChange={onChange} />
}

const StyledInput = styled.input`
  display: none;
`

export default InputFile
