import moment from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'

import { Column, Row, Text, Icon, IconButton, DateTimePicker, Loader } from '..'
import { Edit } from '../../icons'

import { theme } from '../../theme'

const TrapCard = ({
  code,
  battery,
  locationLat,
  locationLng,
  index,
  status,
  isMicro = false,
  microIcon,
  lastTrapEvent,
  submitFloor,
  trapId,
  floorData,
  isFloorDateLoading = false
}) => {
  const lat = locationLat < 0 ? String(locationLat).slice(0, 8) : String(locationLat).slice(0, 7)
  const long = locationLng < 0 ? String(locationLng).slice(0, 8) : String(locationLng).slice(0, 7)

  const { control, handleSubmit, setValue } = useForm()

  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const [isEditingFloor, setIsEditingFLoor] = useState(false)

  const onFloorSubmit = data => {
    submitFloor(trapId, data.floorDate)
    setIsEditingFLoor(false)
  }

  return (
    <CardContainer>
      <Row width='100%' alignItems='center' maxHeight='106px'>
        {microIcon && (
          <Icon icon={microIcon} width={52} height={52} marginLeft={24} marginRight={20} />
        )}

        {status && !microIcon && (
          <Icon
            icon={status !== 'active' ? 'disabledPin' : 'normalPin'}
            width={52}
            height={52}
            marginLeft={24}
            marginRight={20}
          />
        )}

        <Column flex={1}>
          <Row justifyContent='space-between'>
            <Column marginTop='8px'>
              <Text variant='caption' color='gray.n700'>
                Código
              </Text>
              <Text
                fontSize={16}
                fontWeight={600}
                lineHeight='20px'
                color='gray.n900'
                marginRight='15px'
              >
                {code}
              </Text>
            </Column>

            {!isMicro && (
              <Column marginTop='8px' marginRight={isMicro ? '20%' : '30%'}>
                <Text variant='caption' color='gray.n700'>
                  Bateria
                </Text>
                <Text variant='paragraph' fontWeight={600} color='gray.n900'>
                  {`${battery}%`}
                </Text>
              </Column>
            )}
            {isMicro && (
              <Column margin='8px 10px 0px 0px'>
                <Text variant='caption' color='gray.n700'>
                  Último evento
                </Text>
                <Text variant='paragraph' fontWeight={600} color='gray.n900'>
                  {lastTrapEvent}
                </Text>
              </Column>
            )}
          </Row>
          {isMicro && (
            <Row alignItems='center' margin='10px 0px 10px 0px'>
              {isFloorDateLoading ? (
                <Row>
                  <Loader />
                </Row>
              ) : isEditingFloor ? (
                <Row alignItems='center'>
                  <DateTimePicker
                    name='floorDate'
                    changeValue={setValue}
                    control={control}
                    width='200px'
                    label='Última troca de piso'
                    defaultValue={floorData}
                  />
                  <Row alignItems='center' marginBottom='-10px'>
                    <IconButton
                      width='20px'
                      icon='checkCircle'
                      color={theme.colors.feedback.confirmation}
                      onClick={handleSubmit(onFloorSubmit)}
                    />
                    <IconButton
                      width='20px'
                      icon='cancel'
                      color={theme.colors.feedback.error}
                      onClick={() => setIsEditingFLoor(false)}
                    />
                  </Row>
                </Row>
              ) : (
                <Row alignItems='center'>
                  <Column marginBottom='3px'>
                    <Text variant='caption' color='gray.n700' marginBottom='7px'>
                      Última troca de piso
                    </Text>
                    <Text variant='paragraph' fontWeight={600} color='gray.n800'>
                      {moment(floorData).format('HH:mm DD/MM')}
                    </Text>
                  </Column>
                  <Row
                    alignItems='center'
                    margin='10px 0px 0px 20px'
                    style={{ cursor: 'pointer' }}
                    onClick={() => setIsEditingFLoor(true)}
                  >
                    <Edit color={theme.colors.gray.n700} />
                  </Row>
                </Row>
              )}
            </Row>
          )}

          {!isMicro && (
            <Column margin='8px 0px'>
              <Text variant='caption' color='gray.n700'>
                Localização
              </Text>
              <Text variant='paragraph' fontWeight={600} color='gray.n800'>
                {`Latitude: ${lat}, Longitude: ${long}`}
              </Text>
            </Column>
          )}
        </Column>

        {isMicro && (
          <IconButton
            icon='arrowDown'
            marginRight='20px'
            style={{ transform: isAccordionOpen ? 'rotate(180deg)' : '', transition: '0.5s' }}
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          />
        )}
      </Row>
      {isAccordionOpen && (
        <Row
          width='100%'
          maxHeight='106px'
          padding='5px 20px 5px 30px'
          borderTop={`1px solid ${theme.colors.gray.n300}`}
          justifyContent='space-between'
          backgroundColor={theme.colors.gray.n100}
        >
          <Column margin='8px 0px'>
            <Text variant='caption' color='gray.n700'>
              Localização
            </Text>
            <Text variant='paragraph' fontWeight={600} color='gray.n800'>
              {`Latitude: ${lat}, Longitude: ${long}`}
            </Text>
          </Column>
          <Column marginTop='8px' marginRight={isMicro ? '20%' : '30%'}>
            <Text variant='caption' color='gray.n700'>
              Bateria
            </Text>
            <Text variant='paragraph' fontWeight={600} color='gray.n900'>
              {`${battery}%`}
            </Text>
          </Column>
        </Row>
      )}
    </CardContainer>
  )
}

const CardContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.gray.n200};
  border: 1px solid ${theme.colors.gray.n300};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export default TrapCard
