import React from 'react'

import { Row, Text, Icon } from '../../components'
import { theme } from '../../theme'

const TabComponent = ({
  text,
  iconLeft,
  iconRight,
  activationState,
  setActivationState,
  width,
  height,
  iconWidth = '20px',
  iconHeight = '20px',
  activeColor = theme.colors.secondary.five,
  textVariant = 'paragraph',
  value,
  ...props
}) => {
  const activationControl = () => {
    setActivationState(value)
  }

  return (
    <Row
      width={width}
      height={height}
      alignItens='center'
      justifyContent='center'
      borderBottom={activationState === value && `2px solid ${activeColor}`}
      style={{ cursor: 'pointer' }}
      onClick={activationControl}
      {...props}
    >
      {iconLeft && (
        <Icon
          width={iconWidth}
          height={iconHeight}
          icon={iconLeft}
          color={activationState === value && activeColor}
          marginRight='10px'
        />
      )}
      <Text color={activationState === value && activeColor} variant={textVariant}>
        {text}
      </Text>
      {iconRight && (
        <Icon
          width={iconWidth}
          height={iconHeight}
          icon={iconRight}
          color={activationState === value && activeColor}
          marginLeft='10px'
        />
      )}
    </Row>
  )
}

export default TabComponent
